<div class="container padding-bottom">
  <h1>{{claimService.staticInfo.pageHeaders.findYourClaim}}</h1>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group row">
        <label for="dropDown_lossType" class="col-sm-4 col-form-label claim-form">
          <h3>{{claimformService.lbl.haveYouReceivedClaimNumber}}</h3>
        </label>
        <div class="col-sm-8">
          <button type="submit" class="btn btn-primary float-left" 
          [ngClass]="{ 'active' : viewClaim }" id="claimSubmit" 
          (click)="activateViewClaim()"><i class="pi pi-check" *ngIf="viewClaim === true"></i>{{claimformService.yesNo.yes}}</button>
          <button type="submit" class="btn btn-primary float-left" 
          [ngClass]="{ 'active' : fileClaim }" id="claimSubmit" 
          (click)="activateFileClaim()"><i class="pi pi-check" *ngIf="fileClaim === true"></i>{{claimformService.yesNo.no}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-find-your-claim *ngIf="viewClaim === true"></app-find-your-claim>
<app-find-your-credit-union *ngIf="fileClaim === true"></app-find-your-credit-union>