import { Employment } from './employment'
import { Incident } from './incident'
import { Insured } from './insured'
import { ClaimProperty } from './claim-property';

export class Claim {
    employment: Employment;
    incident: Incident;
    insured: Insured;
    lossType: string;
    otherCU: string;
    loansOtherCU: string;
    incurredDate: Date;
    description: string;
    hasCreditCard: string;
    claimNumber: string;
    areLoansActive: string;
    additionalClaimProperties: Array<ClaimProperty>;
    formType: string;

    constructor() {
        this.employment = new Employment();
        this.incident = new Incident();
        this.insured = new Insured();
        this.lossType = null;
        this.otherCU = null;
        this.loansOtherCU = null;
        this.incurredDate = null;
        this.description = null;
        this.hasCreditCard = null;
        this.claimNumber = null;
        this.areLoansActive = null;
        this.additionalClaimProperties = new Array<ClaimProperty>();
        this.formType = null;
    }
}