import { Injectable } from '@angular/core';
import { ConfigurationService } from '../app/configuration.service';
import { environment } from '../environments/environment';
import { CreditUnion } from './configuration-data/credit-union';

@Injectable({
  providedIn: 'root'
})
export class CreditUnionService {

  env = environment;
  isDemoCreditUnion: boolean = false;
  memberRegistrationCreditUnion: CreditUnion;

  isMemberRegistrationCreditUnion(contractNumber:string):boolean{
    this.isDemoCreditUnion = contractNumber === '12312312' ? true : false;
    if (this.isConfigurationDataLoaded() && this.getCreditUnion(contractNumber)){
      this.memberRegistrationCreditUnion = this.getCreditUnion(contractNumber);
      return true;
    } else {
      this.memberRegistrationCreditUnion = null;
      return false;
    }
  }

  private isConfigurationDataLoaded(): boolean {
    return this.configService.configurationData.creditUnions != null;
  }

  private getCreditUnion(contractNumber): CreditUnion {
    return this.configService.configurationData.creditUnions.find(element => element.contractNumber === contractNumber);
  }

  constructor(
    private configService: ConfigurationService
  ) { }

}
