<div class="container">
    <app-form-steps></app-form-steps>
    <h1>Contact Information</h1>
    <span *ngIf="claimService.claimChannel == claimChannel.PostRegistration; else headerMemberRegistration">
        <h3>Claim Number: {{claimService.claimRoot.claim.claimNumber}}</h3>
    </span>
    <ng-template #headerMemberRegistration>
        <h3>Start a claim for benefits</h3>
    </ng-template>
    <span *ngIf="claimService.lossType">
        <span *ngIf="claimService.claimChannel == claimChannel.PostRegistration">
            <h4>To prevent delays in processing your {{claimService.lossType}} claim, please complete all sections.</h4>
        </span>
        <span *ngIf="claimService.claimChannel == claimChannel.MemberRegistration">
            <h4>
                To prevent delays in processing your claim, please complete all sections.                
            </h4>
        </span>
        <div class="row">
            <div class="col-sm-8">
                <h4 class="h4-header"
                    [hidden]="(claimService.claimChannel != claimChannel.MemberRegistration) || (claimService.claimChannel == claimChannel.MemberRegistration && claimService.lossType == lossType.Life)">
                    Person this {{claimService.lossType}} claim is for:
                </h4>
            </div>
            <div class="col-sm-4">
                <p class="form-error-text">* denotes required fields.</p>
            </div>
        </div>
    </span>
    <div class="container">
        <form (ngSubmit)="contactSubmit(contactForm.valid)" #contactForm="ngForm" [appMustMatch]="['email', 'verifyEmail']" novalidate>
            <div class="form-group row" *ngIf="claimService.claimChannel == claimChannel.MemberRegistration">
                <label for="dropDown_lossType" class="col-sm-5 col-form-label claim-form">
                    {{claimformService.lbl.lossType}} <span class="form-error-text">*</span>
                </label>
                <div class="col-sm-7">
                    <p>If your claim type does not appear within the options listed below, please call us at {{memberRegistrationPhoneNumber}} to file your claim.</p>
                      <ng-select labelForId="lossType" name="lossType" #lossType="ngModel" bindLabel="label" bindValue="value"
                        [(ngModel)]="claimService.claimRoot.claim.lossType" [selectOnTab]="true"  [searchable]="false" [clearable]="false"
                        [items]="claimformService.memberRegistrationLossTypes" placeholder="{{claimformService.lbl.lossType}}" required (ngModelChange)="setLossTypeFromMemberRegistration($event)">
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                          <span [ngOptionHighlight]="search">{{item.label}}</span>
                        </ng-template>
                      </ng-select>
                </div>
            </div>            
            <span *ngIf="claimService.lossType">
                <app-form-contact [claimForm]="contactForm"></app-form-contact>
                <span *ngIf="claimService.lossType == claimService.enumLossType.Hospitalization && allowHospitalizationLossType == true">
                    <app-form-life-hosp [claimForm]="contactForm"></app-form-life-hosp>
                </span>
                <span *ngIf="claimService.lossType != claimService.enumLossType.Life">
                    <span *ngIf="claimformService.showAdditionalBenefits()">
                    <h3>Additional benefits you might qualify for</h3>
                    <app-form-other-benefits [claimForm]="contactForm"></app-form-other-benefits>
                    </span>
                    <h3>How should we communicate with you?</h3>
                    <app-form-communicate [claimForm]="contactForm"></app-form-communicate>
                </span>
                <span *ngIf="claimformService.showDocumentUpload()">
                    <h3>Please attach any documents that will help process the claim.</h3>
                    <app-form-document></app-form-document>                    
                </span>
                <br><br>
                <div class="divider"></div>
                <div *ngIf="contactForm.submitted && !contactForm.valid" class="form-error-text" class="alert alert-danger">
                    {{errMsg.formRequired}}
                </div>
                <div *ngIf="claimformService.claimSubmitError" class="form-error-text" class="alert alert-danger">
                    {{claimformService.claimSubmitError}}
                </div>
                <span *ngIf="claimformService.isSubmittingClaim">
                    {{claimformService.lbl.submittingClaimData}}<i class="pi pi-spin pi-spinner" style="font-size: 3em"></i>
                </span>
                <div class="row">
                    <div class="col-md-12">
                    <button type="submit" class="btn btn-primary float-right" id="contactContinue"
                    [disabled]="contactForm.submitted && !contactForm.valid">
                        <span *ngIf="claimService.isLossTypeDisability || claimService.lossType == claimService.enumLossType.Unemployment; else submitClaimText">
                            {{lbl.continue}}
                        </span>
                        <ng-template #submitClaimText>
                            {{lbl.submitClaim}}
                        </ng-template>
                    </button>
                    </div>
                </div>
                <br><br>
            </span>          
        </form>
        <span *ngIf="claimService.isSinglePageLossType">
            <app-fraud-language></app-fraud-language>
        </span>
    </div>
</div>