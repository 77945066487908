<div class="container">

  <form (ngSubmit)="onSubmit(supplementalForm.valid)" #supplementalForm="ngForm">
    <h1>{{claimService.staticInfo.pageHeaders.claim}}</h1>
    <h3>YOUR CLAIM NUMBER: {{claimService.claimRoot.claim.claimNumber}}</h3>
    <div class="container padding">
      <div class="row">
        <div class="col-sm-9">
          <h4>
            Please provide the following information so that we can continue our review for benefits.<br><br>
          </h4>
        </div>
        <div class="col-sm-3">
          <p class="form-error-text">* denotes required fields.</p>
        </div>
      </div>
      <span *ngIf="claimService.isLossTypeDisability; else suppUnemployment">
        <app-form-supp-disability [claimForm]="supplementalForm"></app-form-supp-disability>
      </span>
      <ng-template #suppUnemployment>
        <app-form-supp-simple-iu [claimForm]="supplementalForm" *ngIf="showSimpleIU === true; else oldIU"></app-form-supp-simple-iu>
        <ng-template #oldIU>
          <app-form-supp-unemployment [claimForm]="supplementalForm"></app-form-supp-unemployment>
        </ng-template>
      </ng-template>
    </div>
    <span *ngIf="claimformService.showDocumentUpload()">
      <h3>PLEASE ATTACH ANY DOCUMENTS THAT WILL HELP PROCESS THE CLAIM.</h3>
      <app-form-document></app-form-document>
    </span>
    <div class="divider"></div>
    <div *ngIf="supplementalForm.submitted && !supplementalForm.valid" class="form-error-text" class="alert alert-danger">
      {{claimformService.errMsg.claimFormRequired}}
    </div>

    <div *ngIf="claimformService.claimSubmitError" class="form-error-text" class="alert alert-danger">
      {{claimformService.claimSubmitError}}
    </div>
    <span *ngIf="claimformService.isSubmittingClaim">
      {{claimformService.lbl.submittingClaimData}}<i class="pi pi-spin pi-spinner" style="font-size: 3em"></i>
    </span>

    <div class="row">
      <div class="col-sm-1">

      </div>
      <div class="col-sm-5">
      </div>
      <div class="col-sm-6">
        <button type="submit" class="btn btn-primary float-right" id="supplementalForm"
        [disabled]="supplementalForm.submitted && !supplementalForm.valid">
          {{claimformService.lbl.submitClaim}}
        </button>
      </div>
    </div>

    <br><br>
  </form>
  <app-fraud-language></app-fraud-language>
</div>