<div style="padding: 30px">
  <h4 class=sidebar-title>Contact Us</h4>
  <div *ngIf="showPortalUpdatesMessage" class="in-sidebar" style="color: red;"><b>UPDATE:</b> In observance of the Labor Day holiday, our office will have limited staff from September 3rd-9th.  Please be advised that we may experience high call volumes.</div>    
  <address class="in-sidebar">
    <h5>Phone:</h5>
    {{phoneService.phoneNumber}}<br>
    8:00 AM - 5:00 PM CT<br>
    Monday - Friday<br><br>    
    <h5>Mail:</h5>
    Claims <br>
    PO Box 5010 <br>
    Madison, WI 53705-0010 <br>
  </address>
  <h4 class=sidebar-title>Resources For You</h4>
  <div class="in-sidebar">
    <span id="communityResources" class="btn-link faq-text" (click)="openModal(communityResourcesModal)">
      Community Resources
    </span>
    <br>
    <span id="faq" class="btn-link faq-text" (click)="openModal(faqModal)">
      FAQ
    </span>
  </div>
  <h4 class=sidebar-title>Fax</h4>
  <div class="in-sidebar">
    If you have documents to support your claim, you may fax them to {{brandname}} using the fax cover sheet, which you can download by clicking the following link.<br><br>
    <a class="btn-link faq-text" href={{faxcover}} target="_blank">Fax cover sheet</a><br><br>
    Our fax number is 1-855-726-2513. Please address the fax to {{brandname}}, Attn: Claims.<br>
    </div>
</div>

<ng-template #faqModal>
  <app-faq></app-faq>  
</ng-template>

<ng-template #communityResourcesModal>
  <app-community-resources></app-community-resources>
</ng-template>
