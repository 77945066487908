<div class="container">
    <app-form-steps></app-form-steps>
    <h1>{{claimService.staticInfo.pageHeaders.claimWork}}</h1>
    <div class="row">
        <div class="col-sm-8">
        </div>
        <div class="col-sm-4">
            <p class="form-error-text">* denotes required fields.</p>
        </div>
    </div>
    <div class="container claim-form">
        <form (ngSubmit)="workSubmit(workForm.valid)" #workForm="ngForm">
            <span *ngIf="allowUnemploymentPostRegistration === true; else workOldWay">
                <app-form-work *ngIf="claimService.lossType === lossType.Disability" [claimForm]="workForm"></app-form-work>
                <app-form-work-unemployment *ngIf="claimService.lossType === lossType.Unemployment" [claimForm]="workForm"></app-form-work-unemployment>
            </span>
            <ng-template #workOldWay>
                <app-form-work [claimForm]="workForm"></app-form-work>
            </ng-template>
            <div class="divider"></div>
            <div *ngIf="workForm.submitted && !workForm.valid" class="form-error-text" class="alert alert-danger">
                {{errMsg.formRequired}}
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <button type="button" class="btn btn-secondary float-right" id="back" (click)="claimRouterService.routeToContact()">
                        {{lbl.back}}
                    </button>
                </div>
                <div class="col-sm-4">
                    
                </div>
                <div class="col-sm-5">
                    <button type="submit" class="btn btn-primary float-right" id="next"
                        [disabled]="workForm.submitted && !workForm.valid">
                        <span *ngIf="claimService.isLossTypeDisability; else summaryButton">
                            {{lbl.enterMedInfo}}
                        </span>
                        <ng-template #summaryButton>
                            {{lbl.reviewClaim}}
                        </ng-template>
                    </button>
                </div>
            </div>
            <br><br>
        </form>
    </div>
</div>