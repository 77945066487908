import { Injectable } from '@angular/core';
import { ClaimRoot } from './claim-data/claim-root';
import { StaticInfo } from './_helpers/static-info';

@Injectable({
  providedIn: 'root'
})
export class ContactInfoMapperService {
  staticInfo: StaticInfo;

  mapClaimRoot(claimRoot: ClaimRoot){
    let returnValue: ClaimRoot

    // Map values from CLX types to interim objects that match the portal types

    // If claim is international: Map line2 to city, clear state property, clear zipCode property
    if (claimRoot.contact.address.countryCode != this.staticInfo.countryCode.usa){
      claimRoot.contact.address.city = claimRoot.contact.address.line2;
      claimRoot.contact.address.state = null;
      claimRoot.contact.address.zip = null;
    }

    // Now convert to actual portal objects
    returnValue = { ...<ClaimRoot>claimRoot };

    return returnValue;
  }

  constructor() {
    this.staticInfo = new StaticInfo();
  }
}
