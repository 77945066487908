import { Claim } from './claim'
import { Contact } from './contact'

export class ClaimRoot{
    cuContractNumber: string
    claim: Claim
    contact: Contact
    
    constructor() {
        this.cuContractNumber = null;
        this.claim = new Claim();
        this.contact = new Contact();
    }
}