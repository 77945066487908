import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { ClaimRoot } from '../claim-data/claim-root';
import { ClaimRouterService } from '../claim-router.service';
import { ClaimService } from '../claim.service';
import { ClaimformService } from '../claimform.service';
import { ConfigurationService } from '../configuration.service';
import { CreditUnionService } from '../credit-union.service';
import { ClaimChannel } from '../enum-claim-channel';
import { PhoneService } from '../phone.service';
import { SessionService } from '../session.service';
import { StaticInfo } from '../_helpers/static-info';
import { SharedModule } from 'primeng/api';
import { AccordionModule } from 'primeng/accordion';
import { NgFor, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';


@Component({
    selector: 'app-find-your-credit-union',
    templateUrl: './find-your-credit-union.component.html',
    styleUrls: ['./find-your-credit-union.component.css'],
    standalone: true,
    imports: [FormsModule, NgFor, NgIf, AccordionModule, SharedModule, NgSelectModule, NgOptionHighlightModule]
})
export class FindYourCreditUnionComponent implements OnInit {

  staticInfo = new StaticInfo();
  lbl = this.staticInfo.label;
  errMsg = this.staticInfo.errorMessage;
  api = this.staticInfo.api;
  internalServerError: boolean = false
  isLoadingAuth: boolean = false;

  /** This calls createMemberToken and returns the token if KBA is successful.
   * Upon completion, this performs actions based on the URL entered. */
  authorizeCreditUnion(form){
    if (form.valid){

      //clear any claim data in case user has authenticated with find-your-claim component
      this.claimService.claimRoot = new ClaimRoot;
      this.claimService.lossType = null;
      this.claimService.setPropertiesForCurrentLossType();

      //set up user for Member Registration
      this.claimService.setEnumClaimChannel(ClaimChannel.MemberRegistration);
      this.creditUnionService.isMemberRegistrationCreditUnion(this.claimService.contractNumber)
      this.claimformService.memberRegistrationLossTypes = this.staticInfo.memberRegistrationLossTypes(this.creditUnionService.memberRegistrationCreditUnion.lossTypes)

      this.isLoadingAuth = true;

      this.authService.createMemberToken(this.claimService.contractNumber).subscribe(
        (data:string) => {
          this.authService.token = data 
          this.claimService.claimRoot.cuContractNumber = this.claimService.contractNumber;
          this.claimService.trackingNumber = null //set to null in case user has authenticated with find-your-claim component
          this.isLoadingAuth = false;
        },
        (err) => {
          this.isLoadingAuth = false;
          this.claimService.showErrorMsg(err);
        },
        () => {
          this.sessionService.startCounter();
          this.claimRouterService.routeToContact();
        }
      )
    }
  }

  constructor(
    public authService: AuthenticationService,
    public claimformService: ClaimformService,
    public phoneService: PhoneService,
    public claimService: ClaimService,
    private sessionService: SessionService,
    public configurationService: ConfigurationService,
    private claimRouterService: ClaimRouterService,
    private creditUnionService: CreditUnionService
  ) { }

  ngOnInit() {
  }

}
