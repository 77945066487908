export enum UnemploymentReason {
    LackOfWork = 'LackOfWork',
    NotMeetingExpectations = 'NotMeetingExpectations',
    MissedWork = 'MissedWork',
    ResignedOrRelocated = 'ResignedOrRelocated',
    RuleOrPolicyViolation = 'RuleOrPolicyViolation',
    Misconduct = 'Misconduct',
    BusinessClosureOrPositionEliminated = 'BusinessClosureOrPositionEliminated', 
    ContractEnded =  'ContractEnded',
    SeasonalLayoff = 'SeasonalLayoff',
    Other = 'Other'
}