<p><b>
  FRAUD WARNING: Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit, or
  knowingly presents false information in an application for insurance may be guilty of a crime and subject to fines
  and confinement in prison, and denial of insurance benefits, depending on state law. 
</b></p>

<p>
  <b>Alabama and Maryland Residents:</b>
  Any person who knowingly or willfully presents a false or fraudulent claim for payment of a loss or benefit or who
  knowingly or willfully presents false information in an application for insurance is guilty of a crime and may be
  subject to fines and confinement in prison. 
</p>

<p>
  <b>Arkansas:</b> 
  Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or
  knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines
  and confinement in prison.
</p>

<p>
  <b>California Residents:</b>
  For your protection California law requires the following to appear on this form: 
  Any person who knowingly presents false or fraudulent information to obtain or amend insurance coverage or to make a 
  claim for the payment of a loss is guilty of a crime and may be subject to fines and confinement in state prison.
</p>

<p><b>
  Colorado Residents: It is unlawful to knowingly provide false, incomplete, or misleading facts or information to
  an insurance company for the purpose of defrauding or attempting to defraud the company. Penalties may include
  imprisonment, fines, denial of insurance, and civil damages. Any insurance company or agent of an insurance company
  who knowingly provides false, incomplete, or misleading facts or information to a policyholder or claimant for the
  purpose of defrauding or attempting to defraud the policyholder or claimant with regard to a settlement or award
  payable from insurance proceeds shall be reported to the Colorado division of insurance within the department of
  regulatory agencies.
</b></p>

<p> 
  <b>District of Columbia Residents: WARNING:</b> 
  It is a crime to provide false or misleading information to an
  insurer for the purpose of defrauding the insurer or any other person. Penalties include imprisonment and/or fines. In
  addition, an insurer may deny insurance benefits, if false information materially related to a claim was provided by
  the applicant.
</p>

<p> 
  <b>Florida Residents:</b> 
  Any person who knowingly and with intent to injure, defraud or deceive any insurer files a
  statement of claim or an application containing any false, incomplete or misleading information is guilty of a felony
  of the third degree. 
</p>

<p> 
  <b>New Jersey Residents:</b> 
  Any person who knowingly files a statement of claim containing any false or misleading
  information is subject to criminal and civil penalties.
</p>

<p>
  <b>New York Residents:</b> 
  Any person who knowingly and with intent to defraud any insurance company or other person
  files an application for insurance or statement of claim containing any materially false information, or conceals for
  the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance act, which
  is a crime, and shall also be subject to civil penalty not to exceed $5,000 and the stated value of the claim for each
  such violation.
</p>

<p>
  <b>Ohio Residents:</b> 
  Any person who, with intent to defraud or knowing that he is facilitating a fraud against an
  insurer, submits an application or files a claim containing a false or deceptive statement is guilty of insurance
  fraud.
</p>

<p>
  <b>Pennsylvania Residents:</b> 
  Any person who knowingly and with intent to defraud any insurance company or other
  person files an application for insurance or a statement of claim containing any materially false information or
  conceals for the purpose of misleading, information concerning any fact material thereto commits a fraudulent
  insurance act, which is a crime and subjects such person to criminal and civil penalties.
</p>

<p>
  <b>Puerto Rico Residents:</b> 
  Any person who knowingly includes false information in an application for insurance, or
  presents a fraudulent claim for payment of a loss or benefit, or files more than one claim for the same loss commits a
  felony. If found guilty such person is subject to fines ($5,000 to $10,000) or confinement in prison (2-5 years) or
  both for each violation. 
</p>