<div class="container">
    <app-form-steps></app-form-steps>
    <h1>{{claimService.staticInfo.pageHeaders.summary}}</h1>
    <h3>PLEASE REVIEW YOUR CLAIM <span *ngIf="claimService.claimChannel !== claimService.enumClaimChannel.MemberRegistration">({{claimService.claimRoot.claim.claimNumber}})</span></h3>
    <div class="row">
        <div class="col-md-8">
        </div>
        <div class="col-md-4">
            <p class="form-error-text">* denotes required fields.</p>
        </div>
    </div>
</div>

<div class="container">
    <form (ngSubmit)="onSubmit(claimForm)" #claimForm="ngForm" [appMustMatch]="['email', 'verifyEmail']" novalidate>
        <h4>The following information is for <span *ngIf="claimService.lossType === claimService.enumLossType.Unemployment; else a">an</span> <ng-template #a>a</ng-template> {{claimService.claimRoot.claim.lossType | titlecase}} claim:</h4>
        <br>
        <app-form-collapsible>
            <span content-header>
                {{claimformService.header.claim}}
            </span>
            <span content-body class="claim-form">
                <app-form-contact [claimForm]="claimForm"></app-form-contact>
                <app-form-communicate [claimForm]="claimForm"></app-form-communicate>
            </span>
        </app-form-collapsible>
        <app-form-collapsible *ngIf="claimformService.showAdditionalBenefits()">
            <span content-header>
                {{claimformService.header.otherBenefits}}
            </span>
            <span content-body class="claim-form">
                <app-form-other-benefits [claimForm]="claimForm"></app-form-other-benefits>
            </span>
        </app-form-collapsible>
        <app-form-collapsible>
            <span content-header>
                {{claimformService.header.work}}
            </span>
            <span content-body class="claim-form">
                <span *ngIf="allowUnemploymentPostRegistration === true; else workOldWay">
                    <app-form-work *ngIf="claimService.lossType === lossType.Disability" [claimForm]="claimForm"></app-form-work>
                    <app-form-work-unemployment *ngIf="claimService.lossType === lossType.Unemployment" [claimForm]="claimForm"></app-form-work-unemployment>
                </span>
                <ng-template #workOldWay>
                    <app-form-work [claimForm]="claimForm"></app-form-work>
                </ng-template>
            </span>
        </app-form-collapsible>
        <app-form-collapsible *ngIf="claimService.lossType !== lossType.Unemployment">
            <span content-header>
                {{claimformService.header.medical}}
            </span>
            <span content-body class="claim-form">
                <app-form-medical [claimForm]="claimForm"></app-form-medical>
            </span>
        </app-form-collapsible>
        <app-form-collapsible *ngIf="claimformService.showDocumentUpload()">
            <span content-header>
                {{claimformService.header.documents}}
            </span>
            <span content-body class="claim-form">
                <app-form-document></app-form-document>
            </span>
        </app-form-collapsible>
        <div *ngIf="claimForm.submitted && !isFormValid(claimForm)" class="form-error-text" class="alert alert-danger">
            {{claimformService.errMsg.claimFormRequired}}
        </div>
        <div *ngIf="claimformService.claimSubmitError" class="form-error-text" class="alert alert-danger">
            {{claimformService.claimSubmitError}}
        </div>
        <span *ngIf="claimformService.isSubmittingClaim">
            {{claimformService.lbl.submittingClaimData}}<i class="pi pi-spin pi-spinner" style="font-size: 3em"></i>
        </span>
        <div class="row">
            <div class="col-md-6">
                <span *ngIf="claimService.claimChannel != claimService.enumClaimChannel.Intake">
                    <span *ngIf="claimService.lossType == lossType.Disability">
                        <button type="button" class="btn btn-secondary float-left" id="claimSubmit" (click)="claimRouterService.routeToMedical()">Back</button>
                    </span>
                    <span *ngIf="claimService.lossType == lossType.Unemployment">
                        <button type="button" class="btn btn-secondary float-left" id="claimSubmit" (click)="claimRouterService.routeToWork()">Back</button>
                    </span>
                </span>
            </div>
            <div class="col-md-6">
                <button type="submit" class="btn btn-primary float-right" id="claimSubmit"
                [disabled]="(claimForm.submitted && !isFormValid(claimForm)) || (claimformService.isSubmittingClaim)">{{claimformService.lbl.submitClaim}}</button>
            </div>
        </div>
        <br><br><br>
    </form>
    <app-fraud-language></app-fraud-language>
</div>