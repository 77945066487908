import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationRequestBody } from '../app/api-data/auth-request-body';
import { StaticInfo } from '../app/_helpers/static-info';
import { CreditUnionService } from './credit-union.service';
import { of } from 'rxjs';
import { delay } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

  staticInfo = new StaticInfo();
  api = this.staticInfo.api
  token: string;
  auth = new AuthenticationRequestBody();
  
  constructor(
    private http: HttpClient,
    private creditUnionService: CreditUnionService
    ) { }

  /** Returns a JWT. Used for PRWC. */
  createToken(authRequestBody: AuthenticationRequestBody){
    return this.http.post(this.api.authNoTrackingIdURL, authRequestBody, { headers: this.api.baseApiHeaders})
  }

  /** Returns a JWT. Used for Member Registration. */
  createMemberToken(contractNumber:string){
    if(this.creditUnionService.isDemoCreditUnion === true){
      return of('demoAuthToken').pipe(delay(2000));
    }else{
      return this.http.get(decodeURIComponent(encodeURIComponent(this.api.memberRegistrationAuthURL + contractNumber)), { headers: this.api.baseApiHeaders, responseType: 'text' })
    }
  }

}
