<div class="doc claim-form">
  <span *ngIf="claimService.lossType == claimService.enumLossType.Unemployment && claimService.claimChannel == claimService.enumClaimChannel.Supplemental">
    Please note, you are not required to submit any additional documentation.
    <br><br>
    If you have documents to support your claim, you may attach them below or fax them to {{brandname}} at
    1-855-726-2513. Please address the fax to {{brandname}}, Attn: Claims.
    <br><br>
  </span>
  Click "Choose File" to attach your document(s) to this claim. You can attach more than one document by clicking "Choose File" again.
  <br>
  Valid formats are PDF, JPG, JPEG, TIF and TIFF.
  <br><br>
  <span id="docExample" class="btn-link" (click)="openModal(docExampleModal)">
    Click here to see some examples of what to attach.
  </span>
  <br><br>
  <input type="file" class="form-control-file" (change)="uploadDocument($event)">
  <br><br>
  <span *ngIf="claimService.documents.length > 0">
    <p-table [value]="claimService.documents">
      <ng-template pTemplate="header">
        <tr>
          <th>Document(s) currently attached:</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-document>
        <tr>
          <td>{{document.name}} <button pButton type="button" icon="pi pi-times" (click)="claimService.removeDocument(document.name, document.data)" class="p-button-danger"></button></td>
        </tr>
      </ng-template>
    </p-table>
  </span>
  <p-messages [(value)]="documentsErrorMsg"></p-messages>
</div>

<ng-template #docExampleModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Examples of what to include with your {{claimService.lossType}} claim</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal-padding">
    <span *ngIf="claimService.isLossTypeDisability">
      - Workers’ compensation work status reports or letters<br><br>
      - Social Security disability award notice<br><br>
      - Doctor's note for work<br><br>
      - Family and Medical Leave Act (FMLA) paperwork<br><br>
      - Medical records<br><br>
      - Claim form from another insurance carrier<br><br>
      - Power of Attorney<br><br>
      - Military discharge papers (DD214)<br><br>
    </span>
    <span *ngIf="claimService.lossType == claimService.enumLossType.Life">
      - Death Certificate<br><br>
      - Obituary<br><br>
      - Power of Attorney<br><br>
    </span>
    <span *ngIf="claimService.lossType == claimService.enumLossType.Unemployment">
      - A copy of your separation letter from your last employer.<br><br>
      - A copy of your two most recent paystubs.<br><br>
      - If your unemployment is due to a military discharge, please submit a copy of your discharge letter.<br><br>
      - A copy of your state unemployment approval or denial letter.<br><br>
      - Proof of payments received from the state unemployment office.<br><br>
    </span>
    <span *ngIf="claimService.lossType == claimService.enumLossType.Terminal_Illness">
      - Dr.'s note<br><br>
      - Family and Medical Leave Act paperwork<br><br>
      - Medical records<br><br>
      - Claim form from another insurance carrier<br><br>
      - Power of Attorney<br><br>
    </span>
    <span *ngIf="claimService.lossType == claimService.enumLossType.Accidental_Dismemberment">
      - Dr.'s note<br><br>
      - Family and Medical Leave Act paperwork<br><br>
      - Medical records<br><br>
      - Claim form from another insurance carrier<br><br>
      - Power of Attorney<br><br>
    </span>
    <span *ngIf="claimService.lossType == claimService.enumLossType.Hospitalization">
      - Hospital Admission & Discharge Papers<br><br>
    </span>
  </div>
  <div class="modal-footer">
    <ng-content select="[content-footer]"></ng-content>
  </div>
</ng-template>
