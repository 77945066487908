import { Component, OnInit } from '@angular/core';
import { ClaimStepsService } from '../claim-steps.service';
import { ClaimService } from '../claim.service';
import { LossType } from '../claim-data/enum-losstype';
import { StepsModule } from 'primeng/steps';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-form-steps',
    templateUrl: './form-steps.component.html',
    styleUrls: ['./form-steps.component.css'],
    standalone: true,
    imports: [NgIf, StepsModule]
})
export class FormStepsComponent implements OnInit {

  lossType = LossType;

  constructor(
    public claimStepsService: ClaimStepsService,
    public claimService: ClaimService
  ) { }

  ngOnInit() {
  }

}
