<div class="modal-header">
  <h4 class="modal-title pull-left">Frequently Asked Questions</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="contactUs.modalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p-fieldset [toggleable]="true">
    <p-header>
      <b>
        CLAIM PORTAL
      </b>
    </p-header>
    <br>
    <div class="fieldset-padding">
      <p-accordion [multiple]="true" styleClass="question-header" (onOpen)="gtmClassChange('open')" (onClose)="gtmClassChange('close')">
        <p-accordionTab>
          <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
            What happens next?
          </p-header>
          Your 
          <span *ngIf="claimService.claimChannel == claimChannel.Supplemental; else whatHappensNext_NotSupp">information</span>
          <ng-template #whatHappensNext_NotSupp>claim request</ng-template>
           will be reviewed by one of our claims specialists. If further information is needed, you will
          be contacted as soon as possible.
          <br><br>
          If you choose to receive email and/or text updates for your claim, you will receive updates this way.
        </p-accordionTab>
        <p-accordionTab>
          <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
            Why do I need to provide this information?
          </p-header>
          Our goal is to provide you a response to your request for benefits as quickly as we can. The information you
          provide will help us expedite your claim.
        </p-accordionTab>
        <p-accordionTab>
          <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
            Can I enter a claim before it happens?
          </p-header>
          Yes. You can enter a claim when you are planning for a period of disability or involuntary unemployment.
          <br><br>
          For example, you could enter a claim when you are having a planned surgery in the near future.
          <br><br>
          Or, if you know that you will be laid off in the near future, you could enter a claim.
          <br><br>
          Please note that your claim cannot be processed until the actual loss event occurs.
        </p-accordionTab>
        <p-accordionTab>
          <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
            The claim is not for me?
          </p-header>
          ALL CLAIMS EXCEPT LIFE: If you are filing this claim for a member other than yourself, please give us as much of the
          information as possible. If more information is needed, we will contact the member. However, the member can authorize
          us
          to talk to you about the claim.
          <br><br>
          LIFE CLAIMS: Please give us as much of the requested information as possible. If further information is needed, we may
          contact you directly.
        </p-accordionTab>
        <p-accordionTab>
          <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
            What supporting documents do I need?
          </p-header>
          Supporting documents can vary based on the claim type. When you complete your online claim request, we will provide
          you
          with a list of documents based on your claim type.
        </p-accordionTab>
        <p-accordionTab>
          <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
            Can I start a claim without supporting documents?
          </p-header>
          <span *ngIf="claimService.claimChannel == claimChannel.Supplemental; else supportingDocuments_NotSupp">
            Yes. Please fill out as much of the information as you can today. You are able to fax or mail documents to us when you
            have them.
          </span>
          <ng-template #supportingDocuments_NotSupp>
            Yes. Please fill out as much of the information as you can to start your claim today. You are able to fax or mail
            documents to us when you have them.
          </ng-template>
        </p-accordionTab>
        <p-accordionTab>
          <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
            What if my claim type is not listed within the drop-down options?
          </p-header>
          If your claim type is not found within the options listed, please call us at {{memberRegistrationPhoneNumber}} to file your claim.
        </p-accordionTab>
      </p-accordion>
    </div>
  </p-fieldset>

  <p-fieldset [toggleable]="true">
    <p-header>
      <b>
        PAYMENT PROTECTION BASICS
      </b>
    </p-header>
    <br>
    <div class="fieldset-padding">
      <p-accordion [multiple]="true" styleClass="question-header" (onOpen)="gtmClassChange('open')" (onClose)="gtmClassChange('close')">
        <p-accordionTab>
          <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
            What is a "Waiting Period", and why is in the contract or certificate of insurance?
          </p-header>
          The waiting period is a specified period of time that must pass after the date your disability begins in order to be
          eligible for benefits.
          <br><br>
          Your contract or certificate of insurance will specify the length of the waiting period which will be the first 14 or 30
          days of disability. The disability must continue for the specified waiting period to be eligible for benefits,
          <br><br>
          Your contract or certificate of insurance will also specify when benefits begin once the waiting period has been met.
          Some contracts or certificates of insurance specify that once the 14 or 30 day waiting period has been met, benefits
          will begin from the date the disability began to include the first 14 or 30 days. Others include an elimination period
          where the first 14 or 30 days are excluded, and benefits do not begin until the 15th or 31st day after the date the
          disability began. Please refer to your contract or insurance schedule/certificate of insurance for specific protection
          terms and conditions.
          <br><br>
          Your payment protection is designed to provide benefits when a serious life event occurs. Creating a program with a
          waiting period helps lower the cost of payment protection for all credit union members while providing the protection
          needed when a serious life event continues past 14 or 30 days.
        </p-accordionTab>
        <p-accordionTab>
          <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
            What does "Actively at Work" mean?
          </p-header>
          “Actively at Work” is the number of hours outlined in your contract or certificate of insurance that you must be working
          in order to be eligible for protection. Your contract may also have actively at work requirements that apply at time of
          loss.
        </p-accordionTab>
        <p-accordionTab>
          <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
            What is "Pre-Exisiting", and why is it in the contract or the certificate of insurance?
          </p-header>
          Pre-existing conditions are health problems or conditions treated before the effective date of your protection or before
          disbursement of money on a loan. Typically if the disability occurred after the effective date of your protection, but
          was caused by the condition that you were already diagnosed with or being treated for, you may not be eligible for
          benefits. Your contract or certificate of insurance will specify the pre-existing exclusions.
          <br><br>
          Unlike other types of payment protection products, your credit union’s payment protection product limits the amount of
          health-related eligibility questions you are asked at the time you purchase protection. However, the contract or
          certificate of insurance does include the pre-existing exclusion. Payment protection is designed to protect against the
          unexpected, this exclusion is designed to prevent expected claims caused by previous health problems or conditions that
          would impact the cost of the protection for all members under the program.
        </p-accordionTab>
        <p-accordionTab>
          <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
            What are the benefit maximums?
          </p-header>
          Benefit maximums vary depending on the protection terms listed in your contract or in the insurance schedule/certificate
          of insurance. Your specific benefit maximums will be outlined for you. Benefit maximums may include total dollars per
          claim -- maximums for monthly payments or age. Please refer to your contract or insurance schedule/certificate of
          insurance for specific protection terms and conditions.
        </p-accordionTab>
      </p-accordion>
    </div>
  </p-fieldset>

<p-fieldset [toggleable]="true">
  <p-header>
    <b>
      CLAIM PROCESS TIMELINE
    </b>
  </p-header>
  <br>
  <div class="fieldset-padding">
    <p-accordion [multiple]="true" styleClass="question-header" (onOpen)="gtmClassChange('open')" (onClose)="gtmClassChange('close')">
      <p-accordionTab>
        <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
          What occurs once my credit union submits my claim to {{brandname}}?
        </p-header>
        We will reach out to you via email, text or phone to request the details of your loss. You will have the options to
        respond using the method most convenient for you. You may choose to respond online, by phone or in writing.
        <br><br>
        Once {{brandname}} receives the needed details of your loss, your claim will be reviewed and we will contact you
        using your preferred method of communication.
      </p-accordionTab>
      <p-accordionTab>
        <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
          How long does it take for my claim to be processed?
        </p-header>
        Depending on the complexity of your claim and the information that needs to be reviewed, the time it takes to process
        your claim could vary. For example:
        <br><br>
        • If we’ve received all necessary information our determination can sometimes be made as quickly as the same day!
        <br>• When additional information is needed we would need more time.
      </p-accordionTab>
      <p-accordionTab>
        <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
          You have approved my benefits, why do I need to continue to provide updates?
        </p-header>
        Your claim was approved based on the period of time you qualified for benefits to date. If we are asking for updated
        information it is to determine if you continue to qualify for benefits beyond that date.
      </p-accordionTab>
      <p-accordionTab>
        <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
          What happens next?
        </p-header>
        Your claim request will be reviewed by one of our claims specialists. If further information is needed, you will be
        contacted as soon as possible.
        <br><br>
        If you choose to receive email and/or text updates for your claim, you will receive updates this way.
      </p-accordionTab>
      <p-accordionTab>
        <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
          Where can I get the status of my claim?
        </p-header>
        <div class="row contact-padding">
          <div class="col-3 icon-align">
            <i class="pi pi-wifi icon-size"></i>
          </div>
          <div class="col-9">
            Check the status online at:<br>
            <span id="faq" class="btn-link .check-status-text" (click)="directToClaimPage()">
              <!-- This is not yet fully functioning. A PBI is out there to make this function as it should. -->
              Click here to view your claim information
            </span>
          </div>
        </div>
        <div class="row contact-padding">
          <div class="col-3 icon-align">
            <i class="pi pi-mobile icon-size"></i>
          </div>
          <div class="col-9">
            {{faqContact.callLine1}}
            <br>{{faqContact.callLine2}}
            <br>{{faqContact.callLine3}}
          </div>
        </div>
        <div class="row contact-padding">
          <div class="col-3 icon-align">
            <i class="pi pi-inbox icon-size"></i>
          </div>
          <div class="col-9">
            {{faqContact.emailLine1}}
            <br>{{emailLine2}}
          </div>
        </div>
        <div class="row contact-padding">
          <div class="col-3 icon-align">
            <i class="pi pi-envelope icon-size"></i>
          </div>
          <div class="col-9">
            {{faqContact.mailLine1}}
            <br>{{faqContact.mailLine2}}
            <br>{{faqContact.mailLine3}}
            <br>{{faqContact.mailLine4}}
          </div>
        </div>
        <div class="row contact-padding">
          <div class="col-3 icon-align">
            <i class="pi pi-print icon-size"></i>
          </div>
          <div class="col-9">
            {{faqContact.faxLine1}}
            <br>{{faqContact.faxLine2}}
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>
</p-fieldset>

<p-fieldset [toggleable]="true">
  <p-header>
    <b>
      PAYMENT PROTECTION BENEFITS
    </b>
  </p-header>
  <br>
  <div class="fieldset-padding">
    <p-accordion [multiple]="true" styleClass="question-header" (onOpen)="gtmClassChange('open')" (onClose)="gtmClassChange('close')">
      <p-accordionTab>
        <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
          Where are the benefits sent?
        </p-header>
        When benefits are due, they are sent directly to your credit union.
      </p-accordionTab>
      <p-accordionTab>
        <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
          How will the benefit be applied to my loan?
        </p-header>
        Your credit union will determine how benefits will be applied. If you have questions about how benefits are applied,
        please contact your credit union.
      </p-accordionTab>
      <p-accordionTab>
        <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
          How long does it take the payment to get to my credit union?
        </p-header>
        If your credit union receives the benefit electronically it may take 1-2 business days to receive it.
        <br><br>
        If your credit union receives a check via mail, it could take longer. There could also be a delay between the date the
        benefit was received and the date your credit union processes it.
      </p-accordionTab>
      <p-accordionTab>
        <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
          How is the benefit amount determined?
        </p-header>
        Benefits are issued for the period of time you currently qualify for benefits, as described in your certificate or
        contract.
        <br><br>
        If your disability is expected to continue to a future date, benefits will continue and will be paid monthly, subject to
        the terms and conditions of coverage as set forth in your Certificate of Insurance or Contract.
      </p-accordionTab>
      <p-accordionTab>
        <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
          Why doesn't the benefit equal my loan payment?
        </p-header>
        Your approved benefit may not match your loan payment. This may occur when benefits are approved for a period which is
        greater than or less than the period currently owed on your loan or you have reached a benefit maximum stated in the
        certificate or contract.
      </p-accordionTab>
      <p-accordionTab>
        <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
          Who is responsible for loan payments?
        </p-header>
        Whether or not you have made a claim for benefits, you remain obligated to the credit union to comply with the terms of
        your loan agreement. Making your loan payments as scheduled remains your obligation.
      </p-accordionTab>
      <p-accordionTab>
        <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
          What if I have already made loan payments for the time period when benefits are being extended?
        </p-header>
        Contact your credit union to determine how benefits will impact your loan.
      </p-accordionTab>
    </p-accordion>
  </div>
</p-fieldset>

<p-fieldset [toggleable]="true">
  <p-header>
    <b>
      GENERAL QUESTIONS
    </b>
  </p-header>
  <br>
  <div class="fieldset-padding">
    <p-accordion [multiple]="true" styleClass="question-header" (onOpen)="gtmClassChange('open')" (onClose)="gtmClassChange('close')">
      <p-accordionTab>
        <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
          Who do I contact if I have a question?
        </p-header>
        Should you have any questions about your claim, {{brandname}} offers these convenient ways for you to reach us:
        <br><br>
        <div class="row contact-padding">
          <div class="col-3 icon-align">
            <i class="pi pi-mobile icon-size"></i>
          </div>
          <div class="col-9">
            {{faqContact.callLine1}}
            <br>{{faqContact.callLine2}}
            <br>{{faqContact.callLine3}}
          </div>
        </div>
        <div class="row contact-padding">
          <div class="col-3 icon-align">
            <i class="pi pi-inbox icon-size"></i>
          </div>
          <div class="col-9">
            {{faqContact.emailLine1}}
            <br>{{emailLine2}}
          </div>
        </div>
        <div class="row contact-padding">
          <div class="col-3 icon-align">
            <i class="pi pi-envelope icon-size"></i>
          </div>
          <div class="col-9">
            {{faqContact.mailLine1}}
            <br>{{faqContact.mailLine2}}
            <br>{{faqContact.mailLine3}}
            <br>{{faqContact.mailLine4}}
          </div>
        </div>
        <div class="row contact-padding">
          <div class="col-3 icon-align">
            <i class="pi pi-print icon-size"></i>
          </div>
          <div class="col-9">
            {{faqContact.faxLine1}}
            <br>{{faqContact.faxLine2}}
          </div>
        </div>
      </p-accordionTab>
      <p-accordionTab>
        <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
          How often should I expect a status update on my claim?
        </p-header>
        Each time benefits are extended on your claim, you will receive an Explanation of Benefits detailing the following
        information:
        <br><br>
        <br>• Benefit amount extended on your loan at the credit union
        <br>• Date range of benefits extended
        <br>• Information regarding an Elimination/Waiting Period (if applicable)
        <br>• Request for additional information (if applicable)
      </p-accordionTab>
      <p-accordionTab>
        <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
          Is my data secured?
        </p-header>
        {{brandname}} takes information security very seriously.{{brandname}} supports Transport Layer Security (TLS) data
        encryption for online business services that require data transmission and provides powerful data security. 
        <br><br>
        For more information, please see:
        <br>
        <a id="securityInformation" href={{securityurl}} target="_blank">
          Security Information
        </a>
        <br>
        <a id="privacy" href={{privacyurl}} target="_blank">
          Privacy
        </a>
      </p-accordionTab>
      <p-accordionTab>
        <p-header class="cmg-ga-tag-faq-{{gtmFaq}}">
          How can I get my documents to you faster? Do you accept information via email?
        </p-header>
        <div class="row contact-padding">
          <div class="col-3 icon-align">
            <i class="pi pi-inbox icon-size"></i>
          </div>
          <div class="col-9">
            {{faqContact.emailLine1}}
            <br>{{emailLine2}}
          </div>
        </div>
        <div class="row contact-padding">
          <div class="col-3 icon-align">
            <i class="pi pi-envelope icon-size"></i>
          </div>
          <div class="col-9">
            {{faqContact.mailLine1}}
            <br>{{faqContact.mailLine2}}
            <br>{{faqContact.mailLine3}}
            <br>{{faqContact.mailLine4}}
          </div>
        </div>
        <div class="row contact-padding">
          <div class="col-3 icon-align">
            <i class="pi pi-print icon-size"></i>
          </div>
          <div class="col-9">
            {{faqContact.faxLine1}}
            <br>{{faqContact.faxLine2}}
          </div>
        </div>
      </p-accordionTab>
      
    </p-accordion>
  </div>
</p-fieldset>

</div>
<div class="modal-footer">
  <ng-content select="[content-footer]"></ng-content>
</div>