import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClaimformSummaryComponent } from './claimform-summary/claimform-summary.component';
import { FindYourClaimComponent } from './find-your-claim/find-your-claim.component'
import { ErrorDefaultComponent } from './error-default/error-default.component';
import { RoutesStaticInfo, Title } from './_helpers/routes-static-info';
import { ClaimSubmitSuccessComponent } from './claim-submit-success/claim-submit-success.component';
import { ClaimPageComponent } from './claim-page/claim-page.component';
import { AuthGuardService } from './_helpers/can-activate/auth-guard-service.service';
import { ClaimSubmitGuardService } from './_helpers/can-activate/claim-submit-guard.service';
import { ClaimformContactComponent } from './claimform-contact/claimform-contact.component';
import { ClaimformWorkComponent } from './claimform-work/claimform-work.component';
import { ClaimformMedicalComponent } from './claimform-medical/claimform-medical.component';
import { ClaimformSupplementalComponent } from './claimform-supplemental/claimform-supplemental.component';
import { GdprConsentComponent } from './gdpr-consent/gdpr-consent.component';
import { LandingGuard } from './_helpers/can-activate/landing.guard';
import { LandingFileOrViewComponent } from './landing-file-or-view/landing-file-or-view.component';

const routesUrlPath = RoutesStaticInfo;

export const routes: Routes = [
  { path: routesUrlPath.linkContract,
    component: LandingFileOrViewComponent,
    canActivate: [LandingGuard],
    data: {title: Title.claimContact}
  },
  { path: routesUrlPath.linkIntake,
    component: FindYourClaimComponent,
    canActivate: [LandingGuard],
    data: {title: Title.findYourClaim}
  },
  { path: routesUrlPath.linkClaim,
    component: LandingFileOrViewComponent,
    canActivate: [LandingGuard],
    data: {title: Title.findYourClaim}
  },
  { path: routesUrlPath.linkSupp,
    component: LandingFileOrViewComponent,
    canActivate: [LandingGuard],
    data: {title: Title.findYourClaim}
  },
  { path: routesUrlPath.linkSuppIntake,
    component: FindYourClaimComponent,
    canActivate: [LandingGuard],
    data: {title: Title.findYourClaim}
  },
  { path: routesUrlPath.linkLanding,
    component: LandingFileOrViewComponent,
    canActivate: [LandingGuard],
    data: {title: Title.findYourClaim}
  },
  { path: routesUrlPath.claimSupp,
    component: ClaimformSupplementalComponent,
    canActivate: [AuthGuardService],
    data: {title: Title.claim}
  },
  { path: routesUrlPath.summary,
    component: ClaimformSummaryComponent,
    canActivate: [AuthGuardService],
    data: {title: Title.summary}
  },
  { path: routesUrlPath.claimSubmitSuccess,
    component: ClaimSubmitSuccessComponent,
    canActivate: [AuthGuardService, ClaimSubmitGuardService],
    data: {title: Title.claimSubmitSuccess}
  },
  { path: routesUrlPath.claim,
    component: ClaimPageComponent,
    canActivate: [AuthGuardService],
    data: {title: Title.claim}
  },
  { path: routesUrlPath.claimContact,
    component: ClaimformContactComponent,
    canActivate: [AuthGuardService],
    data: {title: Title.claimContact}
  },
  { path: routesUrlPath.claimWork,
    component: ClaimformWorkComponent,
    canActivate: [AuthGuardService],
    data: {title: Title.claimWork}
  },
  { path: routesUrlPath.claimMedical,
    component: ClaimformMedicalComponent,
    canActivate: [AuthGuardService],
    data: {title: Title.claimMedical}
  },
  { path: routesUrlPath.GDPRConsent,
    component: GdprConsentComponent,
    canActivate: [AuthGuardService],
    data: {title: Title.GDPRConsent}
  },
  { path: routesUrlPath.home,
    component: LandingFileOrViewComponent,
    canActivate: [LandingGuard],
    data: {title: Title.findYourClaim},
    pathMatch: 'full'
  },
  { path: routesUrlPath.error,
    component: ErrorDefaultComponent,
    data: {title: Title.error}
  }
];