import { Physician } from './physician'

export class Incident{
    disabilityType: string
    drOrderedNoWorkDate: Date
    hasBeenHospitalized: boolean
    hasWorkmansComp: boolean
    hospitalizationEndDate: Date
    hospitalizationStartDate: Date
    medicalCodeCategory: string
    physician: Physician
    physician2: Physician
    physician3: Physician
    physician4: Physician
    physician5: Physician
    underProviderCare: string
    incidentDate: string
    initialAppointmentDate: string

    constructor(){
        this.disabilityType = null;
        this.drOrderedNoWorkDate = null;
        this.hasBeenHospitalized = null;
        this.hasWorkmansComp = null;
        this.hospitalizationEndDate = null;
        this.hospitalizationStartDate = null;
        this.medicalCodeCategory = null;
        this.physician = new Physician();
        this.physician2 = new Physician();
        this.physician3 = new Physician();
        this.physician4 = new Physician();
        this.physician5 = new Physician();
        this.underProviderCare = null;
        this.incidentDate = null;
        this.initialAppointmentDate = null;
    }
}