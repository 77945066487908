import { Injectable } from '@angular/core';
import { StaticInfo } from './_helpers/static-info';

@Injectable({
  providedIn: 'root'
})
export class ClaimStepsService {

  index: number;
  staticInfo = new StaticInfo();
  disabilitySteps = this.staticInfo.disabilitySteps;
  unemploymentSteps = this.staticInfo.unemploymentSteps;

  constructor(
  ) { }
}
