<div class="form-group row">
  <label for="employerName" class="col-sm-5 col-form-label">
    <span *ngIf="claimService.isLossTypeDisability; else lastEmployer">
      {{claimformService.lbl.employerName}}
    </span>
    <ng-template #lastEmployer>
      {{claimformService.lbl.lastEmployer}}
    </ng-template>
  </label>
  <div class="col-sm-7">
    <input type="text" pInputText class="form-control input-styles" id="employerName"
      [(ngModel)]="claimService.claimRoot.claim.employment.employer.name" name="employerName" #employerName="ngModel"
      maxlength="50">
  </div>
</div>
<div class="form-group row">
  <label for="employerPhone" class="col-sm-5 col-form-label">
    <span *ngIf="claimService.isLossTypeDisability; else lastEmployerPhone">
      {{claimformService.lbl.employerPhone}}
    </span>
    <ng-template #lastEmployerPhone>
      {{claimformService.lbl.lastEmployerPhone}}
    </ng-template>
  </label>
  <div class="col-sm-7">
    <p-inputMask styleClass="input-styles" type="tel" id="employerPhone"
      [(ngModel)]="claimService.claimRoot.claim.employment.employer.phoneNumber" name="employerPhone"
      #employerPhone="ngModel" mask="999-999-9999" styleClass="input-styles"></p-inputMask>
  </div>
</div>
<div class="form-group row" *ngIf="claimService.lossType == claimService.enumLossType.Unemployment">
  <label for="employmentCategory" class="col-sm-5 col-form-label">
    {{claimformService.lbl.employmentCategory}} <span class="form-error-text">*</span>
  </label>
  <div class="col-sm-7">
    <div>
      <ng-select class="fw-normal fs-6" labelForId="employmentCategory" name="employmentCategory" #employmentCategory="ngModel" bindLabel="label" bindValue="value"
                [(ngModel)]="claimService.claimRoot.claim.employment.category" [selectOnTab]="true" [searchable]="false" [clearable]="false"
                [items]="claimformService.employmentCategory" placeholder="{{claimformService.lbl.employmentCategory}}" required>
      </ng-select>
    </div>
    <div
      *ngIf="(employmentCategory.invalid && !employmentCategory.pristine) || (claimForm.submitted && employmentCategory.invalid)"
      class="alert alert-danger">
      {{claimformService.errMsg.genericSelect}}
    </div>
  </div>
</div>
<div class="form-group row" *ngIf="claimService.isLossTypeDisability">
  <label for="selfEmployment" class="col-sm-5 col-form-label">
    {{claimformService.lbl.selfEmployment}} <span class="form-error-text">*</span>
  </label>
  <div class="col-sm-7">
    <div>
      <ng-select class="fw-normal fs-6" labelForId="selfEmployment" name="selfEmployment" #selfEmployment="ngModel" bindLabel="value" bindValue="value"
        [(ngModel)]="claimService.claimRoot.claim.employment.selfEmployed" [selectOnTab]="true" [searchable]="false" [clearable]="false"
        [items]="claimformService.yesNoUnsure" placeholder="{{claimformService.lbl.selfEmployment}}" required>
      </ng-select>
    </div>
    <div *ngIf="(selfEmployment.invalid && !selfEmployment.pristine) || (claimForm.submitted && selfEmployment.invalid)"
      class="alert alert-danger">
      {{claimformService.errMsg.genericSelect}}
    </div>
  </div>
</div>
<div class="form-group row" *ngIf="claimService.isLossTypeDisability">
  <label for="occupation" class="col-sm-5 col-form-label">
    {{claimformService.lbl.occupation}} <span class="form-error-text">*</span>
  </label>
  <div class="col-sm-7">
    <div>
    <ng-select class="fw-normal fs-6" labelForId="occupation" name="occupation" #occupation="ngModel" bindLabel="label" bindValue="value"
      [(ngModel)]="claimService.claimRoot.claim.employment.description" [selectOnTab]="true" [searchable]="false" [clearable]="false"
      [items]="claimformService.empOccupation" placeholder="{{claimformService.lbl.occupation}}" required>
    </ng-select>
  </div>
    <div *ngIf="(occupation.invalid && !occupation.pristine) || (claimForm.submitted && occupation.invalid)"
      class="alert alert-danger">
      {{claimformService.errMsg.genericSelect}}
    </div>
  </div>
</div>
<div class="form-group row">
  <label for="hoursPerWeek" class="col-sm-5 col-form-label">
    {{claimformService.lbl.hoursPerWeek}} <span class="form-error-text">*</span>
  </label>
  <div class="col-sm-7">
    <input type="tel" pInputText pKeyFilter="num" class="form-control input-styles" id="hoursPerWeek" required
      [(ngModel)]="claimService.claimRoot.claim.employment.hoursPerWeek" name="hoursPerWeek" #hoursPerWeek="ngModel"
      maxlength="3">
    <div *ngIf="(hoursPerWeek.invalid && !hoursPerWeek.pristine) || (claimForm.submitted && hoursPerWeek.invalid)"
      class="alert alert-danger">
      {{claimformService.errMsg.hoursPerWeek}}
    </div>
  </div>
</div>
<div class="form-group row">
  <label for="lastDayWorked" class="col-sm-5 col-form-label">
    {{claimformService.lbl.lastDayWorked}} <span class="form-error-text">*</span>
  </label>
  <div class="col-sm-7">
    <p-calendar id="lastDayWorked" required [monthNavigator]="true" [yearNavigator]="true" [showOnFocus]="false"
      [touchUI]="claimformService.touchUI" yearRange="{{claimformService.lbl.todayPlusMinus90Yrs}}" appAfterIncurredDate
      dataType="string" rPCalendarMask [(ngModel)]="claimService.claimRoot.claim.employment.lastDateWorked"
      placeholder="MM/DD/YYYY" class="ui-fluid" name="lastDayWorked" #lastDayWorked="ngModel" [showIcon]="true" styleClass="input-styles" dateMask>
    </p-calendar>
    <div *ngIf="(lastDayWorked.invalid && !lastDayWorked.pristine) || (claimForm.submitted && lastDayWorked.invalid)"
      class="alert alert-danger">
      <span *ngIf="lastDayWorked.errors.required">
        {{claimformService.errMsg.dateRequired}}
      </span>
      <span *ngIf="lastDayWorked.errors.afterIncurredDate">
        {{claimformService.errMsg.afterIncurredDate(claimService.claimRoot.claim.incurredDate, claimService.claimRoot.claim.employment.lastDateWorked)}}
      </span>
    </div>
  </div>
</div>
<fieldset class="form-group" *ngIf="claimService.lossType == claimService.enumLossType.Unemployment">
  <div class="row">
    <div class="col-sm-5">
      <legend>
        {{claimformService.lbl.receivingCompensation}}<span class="form-error-text">*</span>
      </legend>
    </div>
    <div class="col-sm-7">
      <div class="row" id="receivingCompensation">
        <div class="col-12 radio-button">
          <p-radioButton name="receivingCompensation" [value]="claimformService.yesNo.yes" required
            label="{{claimformService.yesNo.yes}}" [(ngModel)]="claimService.claimRoot.claim.employment.receivingAid"
            inputId="receivingCompensation1" id="receivingCompensationYes" #receivingCompensation="ngModel">
          </p-radioButton>
        </div>
        <div class="col-12 radio-button">
          <p-radioButton name="receivingCompensation" [value]="claimformService.yesNo.no" required
            label="{{claimformService.yesNo.no}}" [(ngModel)]="claimService.claimRoot.claim.employment.receivingAid"
            inputId="receivingCompensation2" id="receivingCompensationNo" #receivingCompensation="ngModel"
            (click)="claimformService.clearField(claimformService.clearFormField.receivingAidYes)"></p-radioButton>
        </div>
      </div>
    </div>
    <div
      *ngIf="(receivingCompensation.invalid && !receivingCompensation.pristine) || (claimForm.submitted && receivingCompensation.invalid)"
      class="alert alert-danger">
      {{claimformService.errMsg.genericSelect}}
    </div>
  </div>
</fieldset>
<div class="form-group row"
  *ngIf="claimService.lossType == claimService.enumLossType.Unemployment && claimService.claimRoot.claim.employment.receivingAid === claimformService.yesNo.yes">
  <label for="lastDateEmploymentCompensation" class="col-sm-5 col-form-label">
    {{claimformService.lbl.lastDateEmploymentCompensation}}
  </label>
  <div class="col-sm-7">
    <p-calendar id="lastDateEmploymentCompensation" [monthNavigator]="true" [yearNavigator]="true" [showOnFocus]="false"
      [touchUI]="claimformService.touchUI" yearRange="{{claimformService.lbl.todayPlusMinus90Yrs}}" rPCalendarMask
      dataType="string" [(ngModel)]="claimService.claimRoot.claim.employment.lastDateEmploymentCompPaidThru"
      placeholder="MM/DD/YYYY" class="ui-fluid" name="lastDateEmploymentCompensation"
      #lastDateEmploymentCompensation="ngModel" [showIcon]="true" styleClass="input-styles" dateMask></p-calendar>
  </div>
</div>
<div class="form-group row" *ngIf="claimService.isLossTypeDisability">
  <label for="jobClass" class="col-sm-5 col-form-label">
    {{claimformService.lbl.jobClass}} <span class="form-error-text">*</span>
  </label>
  <div class="col-sm-7">
    <div>
    <ng-select class="fw-normal fs-6" labelForId="jobClass" name="jobClass" #jobClass="ngModel" bindLabel="label" bindValue="value"
      [(ngModel)]="claimService.claimRoot.claim.employment.jobClass" [selectOnTab]="true" [searchable]="false" [clearable]="false"
      [items]="claimformService.jobClassOption" placeholder="{{claimformService.lbl.jobClass}}" required>
    </ng-select>
    </div>
    <div *ngIf="(jobClass.invalid && !jobClass.pristine) || (claimForm.submitted && jobClass.invalid)"
      class="alert alert-danger">
      {{claimformService.errMsg.genericSelect}}
    </div>
  </div>
</div>
<fieldset class="form-group">
  <div class="row">
    <div class="col-sm-5">
      <legend>
        {{claimformService.lbl.returnedToWork}}
      </legend>
    </div>
    <div class="col-sm-7">
      <div class="row" id="returnedToWork">
        <div class="col-12 radio-button">
          <p-radioButton name="returnedToWork" [value]="true" label="{{claimformService.yesNo.yes}}"
            [(ngModel)]="claimService.claimRoot.claim.employment.hasReturnedToWork" inputId="returnedToWork1"
            id="returnedToWorkYes"></p-radioButton>
        </div>
        <div class="col-12 radio-button">
          <p-radioButton name="returnedToWork" [value]="false" label="{{claimformService.yesNo.no}}"
            [(ngModel)]="claimService.claimRoot.claim.employment.hasReturnedToWork" inputId="returnedToWork2"
            id="returnedToWorkNo"
            (click)="claimformService.clearField(claimformService.clearFormField.hasReturnedToWork)"></p-radioButton>
        </div>
      </div>
    </div>
  </div>
</fieldset>
<div *ngIf="claimService.claimRoot.claim.employment.hasReturnedToWork" class="form-group row">
  <label for="returnedToWorkDate" class="col-sm-5 col-form-label">
    {{claimformService.lbl.returnedToWorkDate}} <span class="form-error-text">*</span>
  </label>
  <div class="col-sm-7">
    <p-calendar id="returnedToWorkDate" required [monthNavigator]="true" [yearNavigator]="true" [showOnFocus]="false"
      [touchUI]="claimformService.touchUI" yearRange="{{claimformService.lbl.todayPlusMinus90Yrs}}" dataType="string"
      rPCalendarMask [(ngModel)]="claimService.claimRoot.claim.employment.returnDate" class="ui-fluid"
      name="returnedToWorkDate" placeholder="MM/DD/YYYY" #returnedToWorkDate="ngModel" [showIcon]="true" styleClass="input-styles" dateMask></p-calendar>
    <div
      *ngIf="(returnedToWorkDate.invalid && !returnedToWorkDate.pristine) || (claimForm.submitted && returnedToWorkDate.invalid)"
      class="alert alert-danger">
      {{claimformService.errMsg.dateRequired}}
    </div>
  </div>
</div>
<div *ngIf="claimService.claimRoot.claim.employment.hasReturnedToWork" class="form-group row">
  <label for="hasSameHours" class="col-sm-5 col-form-label">
    {{claimformService.lbl.hasSameHours}}
  </label>
  <div class="col-sm-7">
    <div>
    <ng-select class="fw-normal fs-6" labelForId="hasSameHours" name="hasSameHours" #hasSameHours="ngModel" bindLabel="value" bindValue="value"
      [(ngModel)]="claimService.claimRoot.claim.employment.hasSameHours" [selectOnTab]="true" [searchable]="false" [clearable]="false"
      (ngModelChange)="claimformService.setSameHours(claimService.claimRoot.claim.employment.hasSameHours)"
      [items]="claimformService.yesNoUnsure" placeholder="{{claimformService.lbl.hasSameHours}}" required>
    </ng-select>
    </div>
    <div *ngIf="(hasSameHours.invalid && !hasSameHours.pristine) || (claimForm.submitted && hasSameHours.invalid) || hasSameHours == claimformService.yesNoUnsure.null"
      class="alert alert-danger">
      {{claimformService.errMsg.genericSelect}}
    </div>
  </div>
</div>
<fieldset class="form-group">
  <div class="row">
    <div class="col-sm-5">
      <legend>
        {{claimformService.lbl.hasSameDuty}}
      </legend>
    </div>
    <div class="col-sm-7">
      <div class="row" id="hasSameDuty">
        <div class="col-12 radio-button">
          <p-radioButton name="hasSameDuty" [value]="true" label="{{claimformService.yesNo.yes}}"
            [(ngModel)]="claimService.claimRoot.claim.employment.hasSameDuty" inputId="hasSameDuty1" id="hasSameDutyYes"
            #hasSameDuty="ngModel"></p-radioButton>
        </div>
        <div class="col-12 radio-button">
          <p-radioButton name="hasSameDuty" [value]="false" label="{{claimformService.yesNo.no}}"
            [(ngModel)]="claimService.claimRoot.claim.employment.hasSameDuty" inputId="hasSameDuty2" id="hasSameDutyNo"
            #hasSameDuty="ngModel"></p-radioButton>
        </div>
      </div>
    </div>
  </div>
</fieldset>
<div *ngIf="claimService.isLossTypeDisability" class="form-group row">
  <label for="drOrderedNoWorkDate" class="col-sm-5 col-form-label">
    {{claimformService.lbl.drOrderedNoWorkDate}} <span class="form-error-text">*</span>
  </label>
  <div class="col-sm-7">
    <p-calendar id="drOrderedNoWorkDate" required [monthNavigator]="true" [yearNavigator]="true" [showOnFocus]="false"
      [touchUI]="claimformService.touchUI" yearRange="{{claimformService.lbl.todayPlusMinus90Yrs}}" dataType="string"
      placeholder="MM/DD/YYYY" [(ngModel)]="claimService.claimRoot.claim.incident.drOrderedNoWorkDate" class="ui-fluid"
      rPCalendarMask name="drOrderedNoWorkDate" #drOrderedNoWorkDate="ngModel" [showIcon]="true" styleClass="input-styles" dateMask></p-calendar>
    <div
      *ngIf="(drOrderedNoWorkDate.invalid && !drOrderedNoWorkDate.pristine) || (claimForm.submitted && drOrderedNoWorkDate.invalid)"
      class="alert alert-danger">
      {{claimformService.errMsg.dateRequired}}
    </div>
  </div>
</div>
<div *ngIf="claimService.isLossTypeDisability" class="form-group row">
  <label for="underProviderCare" class="col-sm-5 col-form-label">
    {{claimformService.lbl.underProviderCare}} <span class="form-error-text">*</span>
  </label>
  <div class="col-sm-7">
    <div>
    <ng-select class="fw-normal fs-6" labelForId="underProviderCare" name="underProviderCare" #underProviderCare="ngModel" bindLabel="value" bindValue="value"
      [(ngModel)]="claimService.claimRoot.claim.incident.underProviderCare" [selectOnTab]="true" [searchable]="false" [clearable]="false"
      [items]="claimformService.yesNoUnsure" placeholder="{{claimformService.lbl.underProviderCare}}" required>
    </ng-select>
    </div>
    <div
      *ngIf="(underProviderCare.invalid && !underProviderCare.pristine) || (claimForm.submitted && underProviderCare.invalid)"
      class="alert alert-danger">
      {{claimformService.errMsg.genericSelect}}
    </div>
  </div>
</div>
<fieldset class="form-group">
  <div class="row">
    <div class="col-sm-5">
      <legend>
        {{claimformService.lbl.lastEmployerMilitary}}
      </legend>
    </div>
    <div class="col-sm-7">
      <div class="row" id="lastEmployerMilitary">
        <div class="col-12 radio-button">
          <p-radioButton name="lastEmployerMilitary" [value]="true" label="{{claimformService.yesNo.yes}}"
            [(ngModel)]="claimformService.lastEmployerMilitary" inputId="lastEmployerMilitary1"
            id="lastEmployerMilitaryYes">
          </p-radioButton>
        </div>
        <div class="col-12 radio-button">
          <p-radioButton name="lastEmployerMilitary" [value]="false" label="{{claimformService.yesNo.no}}"
            [(ngModel)]="claimformService.lastEmployerMilitary" inputId="lastEmployerMilitary2"
            id="lastEmployerMilitaryNo"
            (click)="claimformService.clearField(claimformService.clearFormField.lastEmployerMilitary)"></p-radioButton>
        </div>
      </div>
    </div>
  </div>
</fieldset>
<div *ngIf="claimformService.lastEmployerMilitary" class="form-group row">
  <label for="militaryStatus" class="col-sm-5 col-form-label">
    {{claimformService.lbl.militaryStatus}}
  </label>
  <div class="col-sm-7">
    <input type="text" pInputText class="form-control input-styles" id="militaryStatus"
      [(ngModel)]="claimService.claimRoot.claim.employment.militaryStatus" name="militaryStatus"
      #militaryStatus="ngModel">
  </div>
</div>
<div *ngIf="claimformService.lastEmployerMilitary && claimService.lossType == claimService.enumLossType.Unemployment"
  class="form-group row">
  <label for="separationDate" class="col-sm-5 col-form-label">
    {{claimformService.lbl.separationDate}}
  </label>
  <div class="col-sm-7">
    <p-calendar id="separationDate" [monthNavigator]="true" [yearNavigator]="true" [showOnFocus]="false" rPCalendarMask
      [touchUI]="claimformService.touchUI" yearRange="{{claimformService.lbl.todayPlusMinus90Yrs}}" dataType="string"
      placeholder="MM/DD/YYYY" [(ngModel)]="claimService.claimRoot.claim.employment.separationDate" class="ui-fluid"
      name="separationDate" #separationDate="ngModel" [showIcon]="true" styleClass="input-styles" dateMask></p-calendar>
  </div>
</div>
<div *ngIf="claimformService.lastEmployerMilitary && claimService.lossType == claimService.enumLossType.Unemployment"
  class="form-group row">
  <label for="separationReason" class="col-sm-5 col-form-label">
    {{claimformService.lbl.separationReason}}
  </label>
  <div class="col-sm-7">
    <textarea class="input-styles" rows="5" cols="30" id="separationReason" [(ngModel)]="claimService.claimRoot.claim.separationReason"
      name="separationReason" maxlength="1500" pInputTextarea #separationReason="ngModel"></textarea>
  </div>
</div>
<div *ngIf="claimformService.lastEmployerMilitary && claimService.lossType == claimService.enumLossType.Unemployment"
  class="form-group row">
  <label for="characterOfService" class="col-sm-5 col-form-label">
    {{claimformService.lbl.characterOfService}}
  </label>
  <div class="col-sm-7">
    <input type="text" pInputText class="form-control input-styles" id="characterOfService"
      [(ngModel)]="claimService.claimRoot.claim.employment.characterOfService" name="characterOfService"
      #characterOfService="ngModel">
  </div>
</div>
<fieldset class="form-group" *ngIf="claimService.isLossTypeDisability">
  <div class="row">
    <div class="col-sm-5">
      <legend>
        {{claimformService.lbl.workersComp}}
      </legend>
    </div>
    <div class="col-sm-7">
      <div class="row" id="workersComp">
        <div class="col-12 radio-button">
          <p-radioButton name="workersComp" [value]="true" label="{{claimformService.yesNo.yes}}"
            [(ngModel)]="claimService.claimRoot.claim.incident.hasWorkmansComp" inputId="workersComp1"
            id="workersCompYes"></p-radioButton>
        </div>
        <div class="col-12 radio-button">
          <p-radioButton name="workersComp" [value]="false" label="{{claimformService.yesNo.no}}"
            [(ngModel)]="claimService.claimRoot.claim.incident.hasWorkmansComp" inputId="workersComp2"
            id="workersCompNo">
          </p-radioButton>
        </div>
      </div>
    </div>
  </div>
</fieldset>