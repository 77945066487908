import { Component, OnInit } from '@angular/core';
import { ClaimService } from '../claim.service';
import cssVars from 'css-vars-ponyfill';
import { ClaimStepsService } from '../claim-steps.service';
import { LossType } from '../claim-data/enum-losstype';
import { ClaimRouterService } from '../claim-router.service';
import { ClaimformService } from '../claimform.service';
import { ClaimChannel } from '../enum-claim-channel';
import { ConfigurationService } from '../configuration.service';
import { FraudLanguageComponent } from '../fraud-language/fraud-language.component';
import { FormDocumentComponent } from '../form-document/form-document.component';
import { FormMedicalComponent } from '../form-medical/form-medical.component';
import { FormWorkUnemploymentComponent } from '../form-work-unemployment/form-work-unemployment.component';
import { FormWorkComponent } from '../form-work/form-work.component';
import { FormOtherBenefitsComponent } from '../form-other-benefits/form-other-benefits.component';
import { FormCommunicateComponent } from '../form-communicate/form-communicate.component';
import { FormContactComponent } from '../form-contact/form-contact.component';
import { FormCollapsibleComponent } from '../form-collapsible/form-collapsible.component';
import { MustMatchDirective } from '../_helpers/custom-validators/must-match.directive';
import { FormsModule } from '@angular/forms';
import { NgIf, TitleCasePipe } from '@angular/common';
import { FormStepsComponent } from '../form-steps/form-steps.component';

@Component({
    selector: 'app-claimform-summary',
    templateUrl: './claimform-summary.component.html',
    styleUrls: ['./claimform-summary.component.css'],
    standalone: true,
    imports: [FormStepsComponent, NgIf, FormsModule, MustMatchDirective, FormCollapsibleComponent, FormContactComponent, FormCommunicateComponent, FormOtherBenefitsComponent, FormWorkComponent, FormWorkUnemploymentComponent, FormMedicalComponent, FormDocumentComponent, FraudLanguageComponent, TitleCasePipe]
})

export class ClaimformSummaryComponent implements OnInit {

  lossType = LossType;
  summaryPage = this.setSummaryStepIndex();
  allowUnemploymentPostRegistration:boolean = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.allowUnemploymentPostRegistration);

  /** When user submits a valid form, this calls submitClaim from ClaimService. */
  onSubmit(form){
    if (this.isFormValid(form)){
      this.claimformService.submitClaimSubscribe();
    }
  }

  /** Checks if the form is valid. */
  isFormValid(form){
    if (this.claimService.claimChannel === ClaimChannel.Intake && form.valid && this.claimService.isPeClicked){
      return true;
    } else if (form.valid){
      return true;
    }
    else {
      return false;
    }
  }

  setSummaryStepIndex(){
    switch(this.claimService.lossType){
      case this.lossType.Disability: {
        return 3;
      }
      case this.lossType.Unemployment: {
        return 2;
      }
    }
    return null;
  }

  constructor(
    public claimService: ClaimService,
    public claimRouterService: ClaimRouterService,
    private claimsStepService: ClaimStepsService,
    public claimformService: ClaimformService,
    public configService: ConfigurationService
  ) { }

  ngOnInit() {
    cssVars();
    this.claimsStepService.index = this.summaryPage;
  }

}
