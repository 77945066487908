exports.footerjs=function(acc){
    window.brand.sitefooter({
        //Mount point
        mount: ".js-sitefooter-mount",
         links: [
            {
              text: acc[0],
              href: acc[1]
            },
            {
                text: acc[2],
                href: '#',
            },  
         ]
         });
}

exports.additionalfootertext=function(additionaltext){
  var app = document.createElement('div')
  app.className='AdditionalText'
  app.innerHTML=additionaltext
  app.style.paddingTop ="5pt"
  if(typeof document.getElementsByClassName('brand-site-footer__list js-brand-site-footer__list')[0] != "undefined"){
    if(!document.getElementsByClassName('brand-site-footer__list js-brand-site-footer__list')[0].querySelector('.AdditionalText')){
        document.getElementsByClassName('brand-site-footer__list js-brand-site-footer__list')[0].appendChild(app)
        
    }  
  }
}


