import { Component, OnInit, Input } from '@angular/core';
import { ClaimService } from '../claim.service';
import { NgForm, ControlContainer, FormsModule } from '@angular/forms';
import { ClaimformService } from '../claimform.service';
import cssVars from 'css-vars-ponyfill';
import { LossType } from '../claim-data/enum-losstype';
import { InternationalAddressService } from '../international-address.service';
import { FormDescriptionComponent } from '../form-description/form-description.component';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { DateMaskDirective } from '../date-mask.directive';
import { CalendarModule } from 'primeng/calendar';
import { NgIf, NgFor } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
    selector: 'app-form-contact',
    templateUrl: './form-contact.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    styleUrls: ['./form-contact.component.css'],
    standalone: true,
    imports: [NgIf, CalendarModule, DateMaskDirective, FormsModule, InputTextModule, NgFor, InputMaskModule, FormDescriptionComponent, NgSelectModule]
})
export class FormContactComponent implements OnInit {

  @Input() claimForm: NgForm
  
  constructor(
    public claimService: ClaimService,
    public claimformService: ClaimformService,
    public internationalAddressService: InternationalAddressService
  ) { }

  setContactFirstName(firstName: string){
    // Life is set by its own box; all other types need to be set to the insured's first name
    if (this.claimService.lossType !== LossType.Life) {
      this.claimService.claimRoot.contact.firstName = firstName;
    }
  }

  setContactLastName(lastName: string){
    // Life is set by its own box; all other types need to be set to the insured's last name
    if (this.claimService.lossType !== LossType.Life) {
      this.claimService.claimRoot.contact.lastName = lastName;
    }
  }

  isForeignCountry(countryCode: string){
    this.internationalAddressService.isClaimInternational(countryCode);
  }

  showDescriptionOnContactForm():boolean{
    if((this.claimService.lossType=== LossType.Disability) || (this.claimService.lossType=== LossType.Unemployment)||(this.claimService.lossType=== LossType.Hospitalization)){
      return false;
    }
    return true;
  }

  ngOnInit() {
    cssVars();
  }
}
