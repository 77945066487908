import { Injectable } from '@angular/core';
import { ClaimRoot } from '../app/claim-data/claim-root';
import { Document } from '../app/claim-data/document';
import { LossType } from '../app/claim-data/enum-losstype';
import { HttpClient } from '@angular/common/http';
import { StaticInfo } from '../app/_helpers/static-info';
import { ClaimChannel } from './enum-claim-channel';
import { SubmitParams } from './api-data/submit-params';
import { ProcedureCodes } from './enum-procedure-codes';
import { SubmitRequestBody } from './api-data/submit-request-body';
import { AuthenticationService } from './authentication.service';
import { BenefitStatus, ClaimBenefitInfo } from './api-data/claim-benefit-info';
import { ClaimPaymentInformation, PaymentHistory, BenefitPaymentsInformation } from './api-data/claim-payment-info';
import { ClaimMapperService } from './claim-mapper.service';
import { CreditUnionService } from './credit-union.service';
import { of } from 'rxjs';
import { delay } from "rxjs/operators";
import { ContactInfoMapperService } from './contact-info-mapper.service';
import { PhoneService } from './phone.service';
import { ClaimRouterService } from './claim-router.service';
import { isGDPRApplicable } from './api-data/is-gdpr-applicable';
import { ClaimProperty } from './claim-data/claim-property';
import { InternationalAddressService } from './international-address.service';
import { additionalClaimPropertyValues } from './claim-data/additionalClaimPropertyValue';
import { ConfigurationService } from './configuration.service';
import { EncodedRequestBody } from './api-data/encoded-request-body';

@Injectable({
  providedIn: 'root'
})

export class ClaimService {

  claimRoot: ClaimRoot;
  documents: Document[] = [];
  staticInfo = new StaticInfo();
  api = this.staticInfo.api;
  ps = this.staticInfo.paymentStatus;
  additionalClaimPropertyKey = this.staticInfo.additionalClaimProperty.key;
  additionalClaimPropertyName = this.staticInfo.additionalClaimProperty.name;
  additionalClaimPropertyValue = this.staticInfo.additionalClaimProperty.value;
  lossType: LossType;
  isLossTypeDisability: boolean = false;
  isSinglePageLossType: boolean = false;
  claimChannel: ClaimChannel;
  trackingNumber: string = '';
  contractNumber: string = '';
  claimSubmitSuccess: boolean = false;
  claimNumber: string;
  benefitStatuses: BenefitStatus[] = []
  claimBenefit: ClaimBenefitInfo;
  enumClaimChannel = ClaimChannel;
  enumLossType = LossType;
  isPeClicked:boolean = false;
  claimPaymentInformation: ClaimPaymentInformation;
  mostRecentPayment: PaymentHistory;
  PastPayments: PaymentHistory[];
  FuturePayments: PaymentHistory[];
  currentBenefitStatus: BenefitPaymentsInformation;
  verifyEmail: string;
  claimHasBeenSubmitted: boolean = false;
  isLoadingClaimData: boolean = false;
  showIncurredDate: boolean = false;
  incurredDateText: string;
  descriptionPlaceHolder: string;
  apiError: boolean;
  apiErrorType: number;
  emailDomainCheck: boolean = false;
  acpValues: additionalClaimPropertyValues = new additionalClaimPropertyValues() //this will hold all additionalClaimProperties values. use this to map to corresponding property and key.

  private submitRequestBody: SubmitRequestBody;
  private submitAuthId;
  private procedureCode: string;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private mapper: ClaimMapperService,
    private creditUnionService: CreditUnionService,
    private contactMapper: ContactInfoMapperService,
    private phoneService: PhoneService,
    private claimRouterService: ClaimRouterService,
    private internationalAddressService: InternationalAddressService,
    private configService: ConfigurationService
  ) {
    this.claimRoot = new ClaimRoot();
  }

  /** Sets lossType property. */
  setEnumLossType(lossType: string){
    switch(lossType.toUpperCase()){
      case LossType.Disability.toUpperCase(): {
        this.lossType = LossType.Disability;
        this.isLossTypeDisability = true;
        this.isSinglePageLossType = false;
        break;
      }
      case LossType.Life.toUpperCase(): {
        this.lossType = LossType.Life;
        this.isLossTypeDisability = false;
        this.isSinglePageLossType = true;
        break;
      }
      case LossType.Accidental_Dismemberment.toUpperCase(): {
        this.lossType = LossType.Accidental_Dismemberment;
        this.isLossTypeDisability = false;
        this.isSinglePageLossType = true;
        break;
      }
      case LossType.Terminal_Illness.toUpperCase(): {
        this.lossType = LossType.Terminal_Illness;
        this.isLossTypeDisability = false;
        this.isSinglePageLossType = true;
        break;
      }
      case LossType.Unemployment.toUpperCase(): {
        this.lossType = LossType.Unemployment;
        this.isLossTypeDisability = false;
        this.isSinglePageLossType = false;
        break;
      }
      case LossType.Hospitalization.toUpperCase(): {    
        this.lossType = LossType.Hospitalization;
        this.isLossTypeDisability = false;
        this.isSinglePageLossType = true;
        break;
      }
      default: {
        this.lossType = null;
        break;
      }
    }
    if(this.claimChannel == this.enumClaimChannel.MemberRegistration){
      this.claimRoot.claim.lossType = this.lossType;
    }
  }

  /** Sets claimChannel property. */
  setEnumClaimChannel(claimChannel:ClaimChannel){
    return this.claimChannel = claimChannel;
  }

  /** Adds the user's document to the document property. */
  addDocument(fileName, base64):boolean{
    let newDocument: Document = {
      name: fileName,
      data: base64
    }
    for (let document of this.documents){
      if (newDocument.name == document.name && newDocument.data == document.data){
        return true;
      }
    }
    this.documents.push(newDocument);
    return false;
  }

  /** When user removes their document from the file upload que, this removes that same document from the documents property. */
  removeDocument(fileName, base64){
    this.documents = this.documents.filter(function(obj){
      return !(obj.name == fileName && obj.data == base64)
    })
  }

  /** Adds payment information to BenefitStatuses property. */
  addPaymentInfoToBenefitStatus(){
    for(let benefitStatus of this.claimBenefit.BenefitStatuses){
      for (let payment of this.claimPaymentInformation.BenefitPaymentsInformation){
        if (payment.BenefitNumber == benefitStatus.BenefitNumber){
          benefitStatus.paymentsExplanation = payment.PaymentExplanation;
          benefitStatus.paymentsHistory = payment.PaymentsHistory.sort((a: PaymentHistory, b: PaymentHistory) => {
            return new Date(b.PaymentInitiationDate).getTime() - new Date(a.PaymentInitiationDate).getTime();
          });
        }
      }
    }
  }

  /** Sets currentBenefitStatus to the current benefitStatus in the loop so other components can use the same benefitStatus. */
  setCurrentBenefitStatus(benefitStatus: BenefitPaymentsInformation){
    this.currentBenefitStatus = benefitStatus;
  }

  /** Custom search logic to allow states label and state value to be searchable. */
  customSearchState(term: string, item) {
    term = term.toLowerCase();
    return item.label.toLowerCase().indexOf(term) > -1 || item.value.toLowerCase() === term;
  }

  onStateSearch(event, model) { 
    if (event.term.length === 2 && event.items.some(x => x.value === event.term.toUpperCase())){
      if (model.name === "state")
        this.claimRoot.contact.address.state = event.term.toUpperCase(); 
      if (model.name === "hospitalState")
        this.acpValues.hospitalState = event.term.toUpperCase(); 
    }
  }

  /** Populate claimRoot, lossType, PhoneService.phoneNumber properties. */
  populateClaimProperties(claimData){
    // Need to merge the return with what we already have, replacing values with the return values
    this.internationalAddressService.isClaimInternational(claimData.contact.address.countryCode)
    this.claimRoot = {
      ...this.claimRoot,
      ...this.contactMapper.mapClaimRoot(claimData)
    };
    this.setEnumLossType(this.claimRoot.claim.lossType);
    this.phoneService.getPhoneNumber(this.claimChannel, this.lossType)
  }

  routeBasedOnClaimChannel(){
    switch (this.claimChannel) {
      case this.enumClaimChannel.Intake: {
        this.isGDPRApplicable()
        .subscribe(
          (data: isGDPRApplicable) => {
            if(data.IsGDPRApplicable === true){
              this.claimRouterService.routeToGDPRConsent();
            } else {
              this.routeIntakeBasedOnLossType();
            }
          },
          (err) => {
            this.showErrorMsg(err);
          },
          () => {
            this.resetErrorMsg();
          }
        )
        break;
      }
      case this.enumClaimChannel.SupplementalIntake: {
        if(this.lossType === this.enumLossType.Hospitalization){
          this.claimRouterService.routeToError();
        } else {
          this.claimRouterService.routeToSupplemental();
        }
        break;
      }
      default: {
        this.loadClaimPage();
        break;
      }
    }
  }

  /** Losstypes with one pager's do not need a summary page. Therefore, if the loss type only includes a single page, route the intake directly to the single page.*/
  routeIntakeBasedOnLossType(){
    switch(this.lossType){
      case this.enumLossType.Life: {
        this.claimRouterService.routeToContact();
        break;
      }
      case this.enumLossType.Hospitalization: {
        this.claimRouterService.routeToContact();
        break;
      }
      default: {
        this.claimRouterService.routeToSummary();
      }
    }
  }

  loadClaimPage(){
    this.isLoadingClaimData = true;
    this.loadClaimPageStatusData().subscribe(
      (data: ClaimBenefitInfo) => {
        this.claimBenefit = data
      },
      (err) => {
        this.isLoadingClaimData = false;
        this.showErrorMsg(err);
      },
      () => {
        this.resetErrorMsg();
        this.isLoadingClaimData = false;
        this.getBenefitInformation();
      }
    );
  }

  /** Get member's benefit Information. */
  getBenefitInformation() {
    this.isLoadingClaimData = true;
    this.loadClaimPagePaymentData().subscribe(
      (data: ClaimPaymentInformation) => {
        this.claimPaymentInformation = data;
        this.addPaymentInfoToBenefitStatus();
      },
      (err) => {
        this.isLoadingClaimData = false;
        this.showErrorMsg(err);
      },
      () => {
        this.resetErrorMsg();
        this.isLoadingClaimData = false;
        this.isGDPRApplicable().subscribe(
          (data: isGDPRApplicable) => {
            for (let benefitStatus of this.claimBenefit.BenefitStatuses){
              if (
                data.IsGDPRApplicable === true &&
                !this.claimHasBeenSubmitted &&
                benefitStatus.MemberFriendlyStatus == this.staticInfo.benefitStatus.informationNeeded &&
                benefitStatus.ClaimsExpressStatus == this.staticInfo.benefitStatus.registered){
                  this.claimRouterService.routeToGDPRConsent();
                  break;
                } else {
                  this.claimRouterService.routeToClaimPage();
                }
            }
          },
          (err) => {
            this.showErrorMsg(err);
          },
          () => {
            this.resetErrorMsg();
          }
        )
      }
    );
  }

  /** Set compononent properties based on loss type. */
  setPropertiesForCurrentLossType() {
    switch (this.lossType) {
      case this.enumLossType.Accidental_Dismemberment: {
        this.showIncurredDate = true;
        this.incurredDateText = this.staticInfo.label.incurredDateAD;
        this.descriptionPlaceHolder = this.staticInfo.label.descriptionPlaceHolderAD;
        break;
      }
      case this.enumLossType.Disability: {
        this.showIncurredDate = true;
        this.incurredDateText = this.staticInfo.label.incurredDate;
        this.descriptionPlaceHolder = this.staticInfo.label.descriptionPlaceHolderDisability;
        break;
      }
      case this.enumLossType.Terminal_Illness: {
        this.showIncurredDate = true;
        this.incurredDateText = this.staticInfo.label.incurredDateTI;
        this.descriptionPlaceHolder = this.staticInfo.label.descriptionPlaceHolderTI;
        break;
      }
      case this.enumLossType.Unemployment: {
        this.showIncurredDate = true;
        this.incurredDateText = this.staticInfo.label_IU.incurredDate;
        this.descriptionPlaceHolder = this.staticInfo.label.descriptionPlaceHolderIU;
        break;
      }
      case this.enumLossType.Life: {
        this.showIncurredDate = false;
        this.incurredDateText = '';
        this.descriptionPlaceHolder = this.staticInfo.label.descriptionPlaceHolderLife;
        break;
      }
      case this.enumLossType.Hospitalization: {
        this.showIncurredDate = true;
        this.incurredDateText = this.staticInfo.label.incurredDateHosp;
        this.descriptionPlaceHolder = this.staticInfo.label.descriptionPlaceHolderHospitalization;
        break;
      }
      default: {
        this.showIncurredDate = false;
        this.incurredDateText = '';
        this.descriptionPlaceHolder = '';
      }
    }
  }

  /** Loads member's contact information, then redirects to correct page depending on claimChannel. */
  callLoadContactAPI(){
    this.isLoadingClaimData = true;
    this.loadContactInformation()
    .subscribe(
      (data) => {
        this.populateClaimProperties(data);
        this.setPropertiesForCurrentLossType();
      },
      (err) => {
        this.isLoadingClaimData = false;
        this.showErrorMsg(err);
      },
      () => {
        this.resetErrorMsg();
        this.isLoadingClaimData = false;
        this.routeBasedOnClaimChannel();
      }
    );
  }

  /** Shows an error message if createToken or loadContactInformation API calls return an error.  */
  showErrorMsg(err){
    this.apiError = true;
    this.apiErrorType = err.status;
  }

  /** Resets the error message properties so error messages will not be shown. */
  resetErrorMsg(){
    this.apiError = false;
    this.apiErrorType = null;
  }

  /** Returns claim data from CLX API. */
  loadContactInformation(){
    return this.http.get(decodeURIComponent(encodeURIComponent(this.api.loadContactInfoURL + this.trackingNumber)), { headers: this.api.headers(this.authService.token)});
  }

  setAdditionalClaimProperties(){
    //This method will continue to grow. We may need to move this into its own service soon.

    //set Claim Entry
    let claimEntryIntake: ClaimProperty = {
      key: this.additionalClaimPropertyKey.claimEntry,
      name: this.additionalClaimPropertyName.claimEntry,
      value: this.additionalClaimPropertyValue.intake,
    }
    let claimEntryMember: ClaimProperty = {
      key: this.additionalClaimPropertyKey.claimEntry,
      name: this.additionalClaimPropertyName.claimEntry,
      value: this.additionalClaimPropertyValue.member,
    }
    switch(this.claimChannel){
      case ClaimChannel.MemberRegistration: {
        this.procedureCode = ProcedureCodes.Initial.toString();
        this.claimRoot.claim.additionalClaimProperties.push(claimEntryMember);
        break;
      }
      case ClaimChannel.Intake: {
        this.procedureCode = ProcedureCodes.Initial.toString();
        this.claimRoot.claim.additionalClaimProperties.push(claimEntryIntake);
        break;
      }
      case ClaimChannel.PostRegistration: {
        this.procedureCode = ProcedureCodes.Initial.toString();
        this.claimRoot.claim.additionalClaimProperties.push(claimEntryMember);
        break;
      }
      case ClaimChannel.Supplemental: {
        this.procedureCode = ProcedureCodes.Supplemental.toString();
        this.claimRoot.claim.additionalClaimProperties.push(claimEntryMember);
        break;
      }
      case ClaimChannel.SupplementalIntake: {
        this.procedureCode = ProcedureCodes.Supplemental.toString();
        this.claimRoot.claim.additionalClaimProperties.push(claimEntryIntake);
        break;
      }
      case ClaimChannel.NotSet: {
        this.procedureCode = ProcedureCodes.Initial.toString();
        this.claimRoot.claim.additionalClaimProperties.push(claimEntryMember);
        break;
      }
      default: {
        this.procedureCode = ProcedureCodes.Initial.toString();
        this.claimRoot.claim.additionalClaimProperties.push(claimEntryMember);
        break;
      }
    }

    //set countryCode
    let countryName:string;
    for(let i of this.staticInfo.country){
      if(i.value === this.claimRoot.contact.address.countryCode){
        countryName = i.label
      }
    }
    let countryCode: ClaimProperty = {
      key: this.additionalClaimPropertyKey.countryCode,
      name: countryName,
      value: this.claimRoot.contact.address.countryCode
    }
    this.claimRoot.claim.additionalClaimProperties.push(countryCode);

    //set Unemployment Q&A's
    let employedLongerThanOneYear: ClaimProperty;
    let isUnionMember: ClaimProperty;
    let unionName: ClaimProperty;
    let isTemporaryEmployee:  ClaimProperty;
    let isContractBasis:  ClaimProperty;
    let knewLayoffWasPossible:  ClaimProperty;
    let hasSeasonalEmployment:  ClaimProperty;
    let isUnemploymentFromCOVID19:  ClaimProperty;
    let isReceivingWagesDuringCOVID19Unemployment:  ClaimProperty;
    let reasonForUnemployment:  ClaimProperty;
    let differentReasonForUnemployment:  ClaimProperty;
    let currentHoursPerWeek:  ClaimProperty;
    let isSeekingEmployment:  ClaimProperty;
    let plansToWorkWithSameEmployer: ClaimProperty;    

    employedLongerThanOneYear = {
      key: this.additionalClaimPropertyKey.employedLongerThanOneYear,
      name: this.staticInfo.label_IU.employedOneYearOrMore,
      value: this.acpValues.employedLongerThanOneYear
    }
    this.claimRoot.claim.additionalClaimProperties.push(employedLongerThanOneYear);

    isUnionMember = {
      key: this.additionalClaimPropertyKey.isUnionMember,
      name: this.staticInfo.label_IU.isUnionMember,
      value: this.acpValues.isUnionMember
    }
    this.claimRoot.claim.additionalClaimProperties.push(isUnionMember);

    unionName = {
      key: this.additionalClaimPropertyKey.unionName,
      name: this.staticInfo.label_IU.unionName,
      value: this.acpValues.unionName
    }
    this.claimRoot.claim.additionalClaimProperties.push(unionName);

    isTemporaryEmployee = {
      key: this.additionalClaimPropertyKey.isTemporaryEmployee,
      name: this.staticInfo.label_IU.isTemporaryEmployee,
      value: this.acpValues.isTemporaryEmployee
    }
    this.claimRoot.claim.additionalClaimProperties.push(isTemporaryEmployee);

    isContractBasis = {
      key: this.additionalClaimPropertyKey.isContractBasis,
      name: this.staticInfo.label_IU.isContractBasis,
      value: this.acpValues.isContractBasis
    }
    this.claimRoot.claim.additionalClaimProperties.push(isContractBasis);

    knewLayoffWasPossible = {
      key: this.additionalClaimPropertyKey.knewLayoffWasPossible,
      name: this.staticInfo.label_IU.knewLayoffWasPossible,
      value: this.acpValues.knewLayoffWasPossible
    }
    this.claimRoot.claim.additionalClaimProperties.push(knewLayoffWasPossible);

    hasSeasonalEmployment = {
      key: this.additionalClaimPropertyKey.hasSeasonalEmployment,
      name: this.staticInfo.label_IU.hasSeasonalEmployment,
      value: this.acpValues.hasSeasonalEmployment
    }
    this.claimRoot.claim.additionalClaimProperties.push(hasSeasonalEmployment);

    isUnemploymentFromCOVID19 = {
      key: this.additionalClaimPropertyKey.isUnemploymentFromCOVID19,
      name: this.staticInfo.label_IU.isUnemploymentFromCOVID19,
      value: this.acpValues.isUnemploymentFromCOVID19
    }
    this.claimRoot.claim.additionalClaimProperties.push(isUnemploymentFromCOVID19);

    isReceivingWagesDuringCOVID19Unemployment = {
      key: this.additionalClaimPropertyKey.isReceivingWagesDuringCOVID19Unemployment,
      name: this.staticInfo.label_IU.isReceivingWagesDuringCOVID19Unemployment.replace(/<[^>]+>/g, ''),
      value: this.acpValues.isReceivingWagesDuringCOVID19Unemployment
    }
    this.claimRoot.claim.additionalClaimProperties.push(isReceivingWagesDuringCOVID19Unemployment);

    reasonForUnemployment = {
      key: this.additionalClaimPropertyKey.reasonForUnemployment,
      name: this.staticInfo.label_IU.reasonForUnemployment,
      value: this.acpValues.reasonForUnemployment
    }
    this.claimRoot.claim.additionalClaimProperties.push(reasonForUnemployment);

    differentReasonForUnemployment = {
      key: this.additionalClaimPropertyKey.differentReasonForUnemployment,
      name: this.staticInfo.label_IU.differentReasonForUnemployment,
      value: this.acpValues.differentReasonForUnemployment
    }
    this.claimRoot.claim.additionalClaimProperties.push(differentReasonForUnemployment);

    currentHoursPerWeek = {
      key: this.additionalClaimPropertyKey.currentHoursPerWeek,
      name: this.staticInfo.label_IU.currentHoursPerWeek,
      value: this.acpValues.currentHoursPerWeek
    }
    this.claimRoot.claim.additionalClaimProperties.push(currentHoursPerWeek);
    
    isSeekingEmployment = {
      key: this.additionalClaimPropertyKey.isSeekingEmployment,
      name: this.staticInfo.label_IU.isSeekingEmployment,
      value: this.acpValues.isSeekingEmployment
    }
    this.claimRoot.claim.additionalClaimProperties.push(isSeekingEmployment);

    plansToWorkWithSameEmployer = {
      key: this.additionalClaimPropertyKey.plansToWorkWithSameEmployer,
      name: this.staticInfo.label_IU_Supp.plansToWorkWithSameEmployer,
      value: this.acpValues.plansToWorkWithSameEmployer
    }
    this.claimRoot.claim.additionalClaimProperties.push(plansToWorkWithSameEmployer)

    //For LifeHospitalization
    let hospitalStartDate: ClaimProperty;
    let hospitalEndDate: ClaimProperty;
    let hospitalName: ClaimProperty;
    let hospitalCity: ClaimProperty;
    let hospitalState: ClaimProperty;

    if(this.claimRoot.claim.incurredDate!=null){
      hospitalStartDate = {
        key: this.additionalClaimPropertyKey.hospitalStartDate,
        name: this.staticInfo.label.hospitalizationStartDate,
        value: this.claimRoot.claim.incurredDate.toString()
      }
      this.claimRoot.claim.additionalClaimProperties.push(hospitalStartDate)
    }
    
    if(this.claimRoot.claim.incident.hospitalizationEndDate != null){
      hospitalEndDate = {
        key: this.additionalClaimPropertyKey.hospitalEndDate,
        name: this.staticInfo.label.hospitalizationEndDate,
        value: this.claimRoot.claim.incident.hospitalizationEndDate.toString()
      }
      this.claimRoot.claim.additionalClaimProperties.push(hospitalEndDate)
    }
    
    hospitalName = {
      key: this.additionalClaimPropertyKey.hospitalName,
      name: this.staticInfo.label.hospitalName,
      value: this.acpValues.hospitalName
    }
    this.claimRoot.claim.additionalClaimProperties.push(hospitalName)

    hospitalCity = {
      key: this.additionalClaimPropertyKey.hospitalCity,
      name: this.staticInfo.label.hospitalCity,
      value: this.acpValues.hospitalCity
    }
    this.claimRoot.claim.additionalClaimProperties.push(hospitalCity)

    hospitalState = {
      key: this.additionalClaimPropertyKey.hospitalState,
      name: this.staticInfo.label.hospitalState,
      value: this.acpValues.hospitalState
    }
    this.claimRoot.claim.additionalClaimProperties.push(hospitalState)

  }

  setClaimProperties(){
    this.setAdditionalClaimProperties();

    let submitParams: SubmitParams = {
      claimsExpress: {
        trackingNumber: this.trackingNumber,
        procedureCode: this.procedureCode
      }
    }

    this.submitRequestBody = {
      documents: this.documents,
      claim: this.mapper.mapClaimRoot(this.claimRoot),
      params: submitParams
    }

    this.submitAuthId = this.claimChannel === ClaimChannel.MemberRegistration ? this.contractNumber : this.trackingNumber;
  }

  /** Claim submission to GuaranteedDelivery API. */
  submitClaim(){
    if(this.creditUnionService.isDemoCreditUnion === true){
      return of('return a fake response').pipe(delay(2000));
    } else {
      this.setClaimProperties();

      var body: any = this.submitRequestBody;
      var url = this.api.guaranteedDeliveryV1URL + this.submitAuthId;

      if (this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.useNewGuaranteedDeliveryAPI) === true)
      {
        body = new EncodedRequestBody(this.submitRequestBody)
        url = this.api.guaranteedDeliveryV2URL + this.submitAuthId;
      }
      
      return this.http.post(url, body, { headers: this.api.headers(this.authService.token), responseType: 'text' });
    }
  }

  /** sets isPeClicked to true and returns PE status of the claim. */
  checkPreExisting() {
    this.isPeClicked = true;

    return this.http.get(this.api.checkPreExistingURL(this.trackingNumber,
                                             this.claimRoot.claim.incurredDate,
                                             this.claimRoot.claim.employment.lastDateWorked,
                                             this.claimRoot.claim.incident.drOrderedNoWorkDate), { headers: this.api.headers(this.authService.token)});
  }

  /** Loads the claim and benefit status data, and returns an Observable */
  loadClaimPageStatusData(){
    return this.http.get(decodeURIComponent(encodeURIComponent(this.api.statusURL(this.trackingNumber))), { headers: this.api.headers(this.authService.token) });
  }

  /** Loads the claim and benefit payment data, and returns an Observable */
  loadClaimPagePaymentData(){
    return this.http.get(decodeURIComponent(encodeURIComponent(this.api.getPaymentInfoURL(this.trackingNumber))), { headers: this.api.headers(this.authService.token) });
  }

  /** Checks if GDPR is applicable. */
  isGDPRApplicable(){
    return this.http.get(decodeURIComponent(encodeURIComponent(this.api.isGDPRApplicable(this.trackingNumber))), { headers: this.api.headers(this.authService.token) });
  }
}
