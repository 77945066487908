import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { ClaimService } from 'src/app/claim.service';

@Directive({
    selector: '[appAfterIncurredDate]',
    providers: [{ provide: NG_VALIDATORS, useExisting: AfterIncurredDateDirective, multi: true }],
    standalone: true
})
export class AfterIncurredDateDirective implements Validator{

  constructor(private claimService: ClaimService) { }

  validate(control: AbstractControl) {
    const controlDate = new Date(control.value);
    const incurredDate = new Date(this.claimService.claimRoot.claim.incurredDate)

    if (controlDate > incurredDate) {
      return ({ afterIncurredDate: true })
    };

    return null;
  }

}
