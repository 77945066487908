import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { ClaimService } from '../claim.service';
import { NgForm, ControlContainer, FormsModule } from '@angular/forms';
import { ClaimformService } from '../claimform.service';
import cssVars from 'css-vars-ponyfill';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalEdcComponent } from '../modal-edc/modal-edc.component';
import { StaticInfo } from '../_helpers/static-info';
import { ClaimChannel } from '../enum-claim-channel';
import { InternationalAddressService } from '../international-address.service';
import { EmailDomainCheckDirective } from '../_helpers/custom-validators/email-domain-check.directive';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { NgIf, NgFor } from '@angular/common';
import { InputMaskModule } from 'primeng/inputmask';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
    selector: 'app-form-communicate',
    templateUrl: './form-communicate.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    styleUrls: ['./form-communicate.component.css'],
    standalone: true,
    imports: [InputMaskModule, FormsModule, NgIf, RadioButtonModule, NgFor, InputTextModule, EmailDomainCheckDirective, NgSelectModule]
})
export class FormCommunicateComponent implements OnInit {

  @Input() claimForm: NgForm
  modalRef: BsModalRef
  staticInfo: StaticInfo = new StaticInfo();

  constructor(
    public claimService: ClaimService,
    public claimformService: ClaimformService,
    private modalService: BsModalService,
    public internationalAddressService: InternationalAddressService
  ) { }

  /** Displays a modal on the page. */
  setPreferredMethod() {
    this.claimformService.emailPreferred();

    // Set email updates to Yes and hide control
    this.claimformService.emailUpdatesSet();

    // Intake Specialists do not need to consent for email
    if (this.claimformService.blnEmailPreferred && this.claimService.claimChannel !== ClaimChannel.Intake) {
      this.claimformService.showEmailConsentButtons = true;
      this.modalRef = this.modalService.show(ModalEdcComponent, this.staticInfo.consentModalOptions);
    }
  }

  ngOnInit() {
    /*
    Need to call these as soon as we load this form,
    as the data has been prefilled by calling the CLX
    contact API. Without this, some of the validation
    gets really wonky.
    */
    this.claimformService.txtUpdate();
    this.claimformService.emailUpdate();
    this.claimformService.emailPreferred();
    cssVars();
  }

}
