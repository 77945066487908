import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalEdcComponent } from '../modal-edc/modal-edc.component';
import { StaticInfo } from '../_helpers/static-info';
import { footerjs,additionalfootertext } from '../main-footer/footer.js';
import { ConfigurationService } from 'src/app/configuration.service';


@Component({
    selector: 'app-main-footer',
    templateUrl: './main-footer.component.html',
    styleUrls: ['./main-footer.component.css'],
    standalone: true
})
export class MainFooterComponent implements OnInit {
  display: boolean = false;

  modalRef: BsModalRef;
  staticInfo: StaticInfo = new StaticInfo(); 
  footerText = this.staticInfo.footer.mainText;
  tnc = this.staticInfo.footer.terms;
  secInfo = this.staticInfo.footer.security;
  edc = this.staticInfo.footer.consent;
  acc = this.staticInfo.footer.accessibility;
  priv = this.staticInfo.footer.privacy;
  forAbuseVictims = this.staticInfo.footer.forAbuseVictims;
  accessibility= this.staticInfo.footer.accessibilityurlTurstage;
  footerarray:any[];
  AllowTrustage:boolean;


  constructor(private modalService: BsModalService, private configService: ConfigurationService) {
    this.footerarray=[this.acc,
      this.accessibility,
      this.edc,
    ];
   }

  ngOnInit() {
    if (this.configService.configurationData.featureToggles) {
      this.AllowTrustage = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.allowTrustageBranding);
      (this.AllowTrustage && typeof this.AllowTrustage != "undefined")?
        this.accessibility = this.staticInfo.footer.accessibilityurlTurstage:
        this.accessibility = this.staticInfo.footer.accessibilityurlcmg;
        this.footerarray[1] = this.accessibility;
        footerjs(this.footerarray);
    } else {
    this.configService.loadFeatureToggleData().toPromise()
    .then(p_configService => {
      this.AllowTrustage = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.allowTrustageBranding);
      (this.AllowTrustage && typeof this.AllowTrustage != "undefined")?
        this.accessibility = this.staticInfo.footer.accessibilityurlTurstage:
        this.accessibility = this.staticInfo.footer.accessibilityurlcmg;
        this.footerarray[1] = this.accessibility;
        footerjs(this.footerarray);
    })
    .catch(err => console.error(err));
  };
  }

  ngAfterViewChecked(){
    if (this.AllowTrustage && typeof this.AllowTrustage != "undefined"){
       additionalfootertext(this.staticInfo.footer.mainTextTrustage);
    }
    if(!this.AllowTrustage && typeof this.AllowTrustage != "undefined")
    {
       additionalfootertext(this.staticInfo.footer.mainText);
    }
  }

  // /** Displays a modal on the page. */
  openModal() {
    this.modalRef = this.modalService.show(ModalEdcComponent, this.staticInfo.consentModalOptions);
  }


  @HostListener('click',['$event'])Click(event:MouseEvent){
    if((event.target as HTMLElement).textContent == "E-Delivery Consent"){
    this.openModal();
    }
  }
}
