import { SelectItem } from 'primeng/api';
import { MenuItem } from 'primeng/api';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LossType } from '../claim-data/enum-losstype';

export class StaticInfo{
    public readonly consentModalOptions = {
        class: 'modal-lg',
        backdrop: true,
        ignoreBackdropClick: true
    };

    public readonly lossType: SelectItem[] = [
        { label: LossType.Accidental_Dismemberment, value: LossType.Accidental_Dismemberment },
        { label: LossType.Disability, value: LossType.Disability },
        { label: LossType.Life, value: LossType.Life },
        { label: LossType.Terminal_Illness, value: LossType.Terminal_Illness },
        { label: LossType.Unemployment, value: LossType.Unemployment },
        { label: LossType.Hospitalization, value: LossType.Hospitalization}
    ];

    memberRegistrationLossTypes(lossType:LossType[]){
        let dropDown_LossType: SelectItem[] = [];
        dropDown_LossType.push.apply(dropDown_LossType, lossType.map(e => <SelectItem>{ label: e, value: e }))
        return dropDown_LossType;
    }

    public readonly email = 'Email';
    public readonly yes = 'Yes';
    public readonly no = 'No';
    public readonly unsure = 'Unsure';

    public readonly preferredContactMethod: SelectItem[] = [
        { label: this.email, value: this.email },
        { label: 'Mail', value: 'Mail' }
    ];

    public readonly gender: SelectItem[] = [
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' },
        { label: 'No Response', value: 'Prefer_not_to_answer' }
    ];

    public readonly yesNoUnsure: SelectItem[] = [
        { label: this.yes, value: this.yes },
        { label: this.no, value: this.no },
        { label: this.unsure, value: this.unsure }
    ];

    public readonly yesNo = {
        yes: this.yes,
        no: this.no
    }

    public readonly header = {
        claim: "CLAIM",
        otherBenefits: "OTHER BENEFITS YOU MIGHT QUALIFY FOR",
        work: "WORK",
        medical: "MEDICAL",
        documents: "DOCUMENTS",
        pastBenf: 'PAST BENEFITS',
        futureBenf: 'FUTURE SCHEDULED BENEFITS',
        mostRecentBenf: 'MOST RECENT BENEFIT'
    }

    public readonly footer = {
        mainText: 'CUNA Mutual Group is the marketing name for CUNA Mutual Holding Company, a mutual insurance holding company, its subsidiaries and affiliates. Payment protection products include debt protection products available through your credit union that are administered by CUNA Mutual Insurance Agency, Inc. and credit life and credit disability insurance products underwritten by CMFG Life Insurance Company. ',
        mainTextTrustage: 'TruStage™ is the marketing name for TruStage Financial Group, Inc. its subsidiaries and affiliates. Insurance products are issued by CMFG Life Insurance Company, MEMBERS Life Insurance Company, CUMIS Insurance Society Inc., CUMIS Specialty Insurance Society Inc., American Memorial Life Insurance Company, and Union Security Insurance Company. Payment protection products include debt protection products available through your credit union that are administered by CUNA Mutual Insurance Agency, Inc. and credit life and credit disability insurance products underwritten by CMFG Life Insurance Company. </br></br>Corporate Headquarters 5910 Mineral Point Road, Madison WI 53705',
        terms: 'Terms and Conditions',
        security: 'Security Information',
        consent: 'E-Delivery Consent',
        accessibility: 'Accessibility',
        privacy: 'Privacy',
        forAbuseVictims: 'For Abused Victims',
        accessibilityurlcmg: 'https://www.cunamutual.com/about-us/general-information/accessibility',
        accessibilityurlTurstage: 'https://www.trustage.com/legal/accessibility'
    }

    public readonly feedback = {
        feedbackurlcmg: 'https://cunamutualgroup.co1.qualtrics.com/jfe/form/SV_bPgkjeZR1vFVpt4',
        feedbackurlTrustage:'https://feedback.trustage.com/jfe/form/SV_bPgkjeZR1vFVpt4' 
    }

    public readonly url = {
        securityurlcmg:'https://www.cunamutual.com/about-us/general-information/security-information',
        securityurlTrustage:'https://www.trustage.com/legal/security',
        privacyurlcmg:'https://www.cunamutual.com/about-us/general-information/your-privacy',
        privacyurlTrustage:'https://www.trustage.com/legal/privacy'
    }


    public readonly accOrIll = {
        accident: 'Accident',
        illness: 'Illness'
    }

    public readonly benefitStatus = {
        registered: 'Registered',
        denied: 'Denied',
        informationNeeded: 'Information Needed',
        SupplementalInformationNeeded: 'Supplemental Information Needed'
    }

    public readonly queryParam = {
        trackingNumber: 'TrackingNumber',
        contractNumber: 'ContractNumber'
    }

    public readonly paymentStatus = {
        payable:{
            code: 'PY',
            description: 'Payable'
        },
        paid:{
            code: 'PD',
            description: 'Paid'
        }
    }
    public readonly brandname = {
        trustage: 'TruStage',
        cmg:'CUNA Mutual Group'
    }
    public readonly faxcoverpath ={
        faxcovercmg:'../../assets/cmfg/FaxCoverSheet.pdf',
        faxcoverTrustage:'../../assets/trustage/FaxCoverSheet.pdf'
    }

    public readonly gdprconsent ={
        gdprconsentformcmg:'../../assets/cmfg/GDPRConsentForm.pdf',
        gdprconsentformTrustage:'../../assets/trustage/GDPRConsentForm.pdf',
        emailMemberclaimcmg:'Members.Claims@cunamutual.com',
        emailMemberclaimTrustage:'Members.Claims@trustage.com'

    }

    public readonly faqContact = {
        callLine1: 'Call us:',
        callLine2: '800-621-6323 (Toll-Free)',
        callLine3: '*You may also be transferred to your claim professional',
        emailLine1: 'Email us:',
        emailLine2cmg: 'exceptionalclaimservice@cunamutual.com',
        emailLine2Trustage: 'exceptionalclaimservice@trustage.com',
        emailBody: 'To help us respond efficiently to your inquiry, please include your first and last name and date of birth.  Please also include your claim number (if you know this).',
        mailLine1: 'Mail:',
        mailLine2: 'Claims',
        mailLine3: 'PO Box 5010',
        mailLine4: 'Madison, WI 53705-0010',
        faxLine1: 'Fax:',
        faxLine2: '855.726.2513 (Toll-Free)'
    }

    public readonly employmentCategory: SelectItem[] = [
        { label: 'Employed by Joint Borrower', value: 'Employed_by_Joint_Borrower' },
        { label: 'Independent Contractor', value: 'Independent_Contractor' },
        { label: 'Self Employed', value: 'Self_Employed' },
        { label: 'Temporary Employee', value: 'Temporary_Employee' },
        { label: 'Not Listed', value: 'Not_Listed' }
    ]

    public readonly empOccupation: SelectItem[] = [
        { label: 'Administrative & Managerial', value: 'Administrative___Managerial' },
        { label: 'Agricultural & Forestry', value: 'Agricultural___Forestry' },
        { label: 'Armed Forces Personnel', value: 'Armed_Forces_Personnel' },
        { label: 'Construction', value: 'Construction' },
        { label: 'Engineers & Surveyors', value: 'Engineers___Surveyors' },
        { label: 'Health Care', value: 'Health_Care' },
        { label: 'Homemaker', value: 'Homemaker' },
        { label: 'Marketing & Sales', value: 'Marketing___Sales' },
        { label: 'Mechanics & Repairers', value: 'Mechanics___Repairers' },
        { label: 'Mining & Petroleum Drilling', value: 'Mining___Petroleum_Drilling' },
        { label: 'Minors (Pre-School)', value: 'Minors__Pre_School_' },
        { label: 'Non-Military (Government)', value: 'non_Military__Government_' },
        { label: 'Production Occupations', value: 'Production_Occupations' },
        { label: 'Protective Service', value: 'Protective_Service' },
        { label: 'Retired Personnel', value: 'Retired_Personnel' },
        { label: 'Service Occupations', value: 'Service_Occupations' },
        { label: 'Social Scientists & Lawyers', value: 'Social_Scientists___Lawyers' },
        { label: 'Students', value: 'Students' },
        { label: 'Teachers & Librarians', value: 'Teachers___Librarians' },
        { label: 'Technologists and Technicians', value: 'Technologists_and_Technicians' },
        { label: 'Transportation & Material', value: 'Transportation___Material' },
        { label: 'Unemployed', value: 'Unemployed' },
        { label: 'Writers, Artists, & Entertainment', value: 'Writers__Artists____Entertainment' },
        { label: 'Miscellaneous', value: 'Miscellaneous' }
    ]

    public readonly jobClass: SelectItem[] = [
        { label: 'Sedentary - involves lifting no more than 10 pounds', value:'Sedentary' },
        { label: 'Light work - involves lifting no more than 20 pounds', value: 'Light' },
        { label: 'Medium - involves lifting no more than 50 pounds at a time', value: 'Medium' },
        { label: 'Heavy - involves lifting no more than 100 pounds', value: 'Heavy' },
        { label: 'Very Heavy - involves lifting more than 100 pounds', value: 'VeryHeavy'}
    ]

    public readonly medCodeCategory: SelectItem[] = [
        { label: 'Blood disorder', value: 'Blood_disorder' },
        { label: 'Cancer', value: 'Cancer' },
        { label: 'Diabetes, thyroid, other endocrine diseases', value: 'Diabetes__thyroid__other_endocrine_diseases' },
        { label: 'Digestive system condition, Hernia', value: 'Digestive_system_condition__Hernia' },
        { label: 'Ear condition', value: 'Ear_condition' },
        { label: 'Eye condition', value: 'Eye_condition' },
        { label: 'Heart condition', value: 'Heart_condition' },
        { label: 'Infection', value: 'Infection' },
        { label: 'Kidney, bladder, reproductive organ condition', value: 'Kidney__bladder__reproductive_organ_condition' },
        { label: 'Lung condition', value: 'Lung_condition' },
        { label: 'Mental health', value: 'Mental_health' },
        { label: 'Muscle, tendon, joint', value: 'Muscle__tendon__joint' },
        { label: 'Nervous system disorder', value: 'Nervous_system_disorder' },
        { label: 'Pregnancy', value: 'Pregnancy' },
        { label: 'Skin condition', value: 'Skin_condition' },
        { label: 'Sprains, strains, or fractures', value: 'Sprains__strains__or_fractures' },
        { label: 'Other', value: 'Other' }
    ]

    public readonly clearField = {
        hasBeenHospitalized: 'hasBeenHospitalized',
        hasReturnedToWork: 'hasReturnedToWork',
        hasReturnedToWorkYes: 'hasReturnedToWorkYes',
        lastEmployerMilitary: 'lastEmployerMilitary',
        receivingAidYes: 'receivingAidYes',
        hasSameHoursYes: 'hasSameHoursYes',
        hasSameWagesYes: 'hasSameWagesYes'
    }

    public readonly touchUI = window.innerWidth > 500 ? false : true;

    public readonly label = {
        incurredDate: 'First date unable to work',
        incurredDateIU: 'Date unemployment started',
        incurredDateAD: 'Date of accident',
        incurredDateTI: 'Date of diagnosis',
        incurredDateHosp: 'Date you were admitted',
        firstName: 'First Name',
        lastName: 'Last Name',
        contactFirstName: 'Contact First Name',
        contactLastName: 'Contact Last Name',
        address: 'Address',
        city: 'City',
        state: 'State',
        zipCode: 'Zip Code',
        gender: 'Gender',
        birthDate: 'Birth Date',
        deathDate: 'Date of Death',
        last4SSNFullText: 'Last 4 numbers of the social security number',
        last4SSN: 'Last 4 SSN',
        description: 'Additional detail:',
        describeDisability: 'Please describe the condition causing your disability:',
        homePhone: 'Home Phone',
        cellPhone: 'Cell Phone',
        txtUpdate: 'I would like to receive text updates on my claim. Message and data rates may apply.',
        email: 'Email',
        verifyEmail: 'Verify Email',
        emailUpdate: 'I would like to receive email updates on my claim.',
        preferredContactMethod: 'I would like to receive claim information.',
        emailDisclaimer: 'By selecting email I consent to receiving documents electronically.',
        loansOtherCU: 'Do you have any protected loans with other credit unions?',
        otherCUNames: 'Please enter the credit union name(s):',
        creditCard: 'Do you have protected credit cards with your credit union?',
        employerName: 'What is your employer\'s name?',
        employerPhone: 'What is your employer\'s phone number?',
        selfEmployment: 'Are you self-employed?',
        occupation: 'What is your occupation?',
        hoursPerWeek: 'How many hours do you typically work per week?',
        lastDayWorked: 'What was the last full day you worked?',
        jobClass: 'What job class best describes your work?',
        returnedToWork: 'Have you returned to work?',
        returnedToWorkDate: 'What date did you return to work?',
        hasSameHours: 'Are you back to working your normal hours per week?',
        hasSameDuty: 'At any time before or after your disability began did you work with limitations or restrictions your health care provider placed on you, such as limited hours, reduced lifting, standing, sitting…etc.?',
        drOrderedNoWorkDate: 'What was the first date you were unable to work as a result of your disabling condition?',
        underProviderCare: 'Are you under the care of a physician for your disabling condition?',
        lastEmployerMilitary: 'Was your last employer the military?',
        militaryStatus: 'Military Status',
        workersComp: 'Are you receiving worker\'s compensation?',
        accOrIll: 'Was this an accident or illness?',
        providerName: 'What is the name of the individual health care provider treating you? (e.g. Dr. John Smith)',
        providerPhone: 'What is your health care provider\'s phone number?',
        addProvider: 'Please provide any additional health care providers that have treated you for the condition causing your disability',
        addProviderName: 'What is the name of the additional health provider?',
        addProviderPhone: 'What is the phone number of your additional health care provider?',
        medicalCode: 'Of the listed medical categories, which best fits your claim?',
        nextApptDate: 'When is your next health care provider\'s appointment?',
        expectedReturnDate: 'What date are you expected to recover?',
        hasBeenHospitalized: 'Have you been hospitalized for at least 48 consecutive hours for this condition?',
        hospitalizationStartDate: 'Hospital Admission Date', //this label is for Disability loss type
        hospitalizationEndDate: 'Hospital Discharge Date', //this label is for Disability loss type
        hospitalEndDate: 'Date you were discharged', //this label is for Hospitalization loss type
        hospitalName: 'Hospital Name',
        hospitalCity: 'Hospital City',
        hospitalState: 'Hospital State',
        submitClaim: 'Submit Claim',
        trackingNumber: 'Tracking Number',
        todayPlusMinus90Yrs: (new Date().getFullYear() - 90) + ':' + (new Date().getFullYear() + 90),
        infoBenefit: 'Information for Benefit #',
        status: 'Status',
        cuName: 'Credit Union Name',
        loanNumber: 'Loan Number',
        loanPurpose: 'Loan Purpose',
        explanationBenfStatus: 'Explanation of the Benefit\'s Status',
        benfRegistered(loanPurpose) {
            return 'Your ' + loanPurpose + ' loan is currently being reviewed and we will keep you informed on the progress.';
        },
        benfDenied: 'Unfortunately, benefits have been denied.  A detailed letter of explanation has been sent.',
        preExistingIllDate: 'What date did your illness or injury first appear?',
        preExistingProviderDate: 'What date were you first seen by a provider for this condition?',
        noPreExisting: 'There are no additional pre-existing questions to display for this claim.',
        checkForPreExisting: 'Checking for pre-existing condition(s)...',
        expOfMostRecentBenf: 'Explanation for the Most Recent Benefit',
        benfIssued: 'Benefit Issued on',
        benfAmtIssued: 'Benefit Amount Issued',
        benfStatus: 'Benefit Status',
        benfPeriod: 'Benefit Period',
        expOfFutureBenf: 'Benefits have been scheduled as listed below and are subject to the terms and conditions of coverage as set forth in your certificate or contract. We will contact you when an update is needed. To avoid an overpayment, please contact us immediately if there is a change in your status.',
        futureBenfDesc: 'Future Scheduled Benefits (up to six months into the future)',
        pastBenfDesc: '(Benefits are sorted from newest to oldest.)',
        continue: 'Continue',
        back: 'Back',
        enterMedInfo: 'Enter medical information',
        reviewClaim: 'Review and submit claim',
        additionalInfo: 'Please click here to provide us with additional information for your claim',
        additionalInfoIfNeeded: 'If you have an update on your claim, please click here to provide us with additional information.',
        lossType: 'Claim type',
        hasSameHoursSupplemental: 'Have you returned to your normal job duties and hours?',
        normalJobDutiesAndHours: 'List your normal job duties and hours prior to disability.',
        currentJobDutiesAndHours: 'List your current job duties and hours.',
        specificRestrictions: 'List the specific restrictions placed on you by your physician.',
        returnToWorkSameWage: 'Upon returning to work, are you earning the same wages as prior to disability?',
        pleaseExplain: 'Please explain:',
        currentTreatingPhysician: 'Who is your current treating physician?',
        currentTreatingPhysicianPhone: 'What is your current treating physician\'s phone number?',
        anticipatedRecoveryTime: 'If your physician has stated when you may expect to return to your normal job duties and hours, please provide the anticipated date.',
        nextDateWithPhysician: 'When is your next appointment date with your physician?',
        loansActive: 'Is your loan(s) still active at the Credit Union?',
        submittingClaimData: 'Submitting claim data...',
        unemploymentHoursPerWeek: 'How many hours are you working per week?',
        unemploymentBenefits: 'Are you receiving consistent state unemployment benefits?',
        unemploymentExplainBenefits: 'Please explain why you are not receiving consistent unemployment benefits:',
        unemploymentJobContact: 'How many job contacts per week have you been seeking since your date of separation?',
        descriptionPlaceHolderAD: 'Please be as specific as possible. Example: I was learning to downhill ski and ran into a tree.  I broke my left wrist and twisted my knee.  I have a cast on my left arm and will be having surgery on my knee next week.  I haven’t been able to work since the accident.',
        descriptionPlaceHolderDisability: 'Example: I was learning to downhill ski and ran into a tree.  I broke my left wrist and twisted my knee.  I have a cast on my left arm and will be having surgery on my knee next week.  I haven’t been able to work since the accident.',
        descriptionPlaceHolderTI: 'Example: My breast cancer came back and recently my doctor talked to me about hospice.  He told me that he can’t offer me a cure, but I can get treatment to help with the symptoms.',
        descriptionPlaceHolderIU: 'Example: The plant that I worked at is closing down.  In three weeks, I will be unemployed.',
        descriptionPlaceHolderLife: 'Example: My husband had heart problems for a number of years.  He passed away last Saturday from heart failure.',
        descriptionPlaceHolderHospitalization: "",
        lastEmployer: 'Who was your last employer?',
        lastEmployerPhone: 'Last employer\'s phone number?',
        employmentCategory: 'What category fits your employment?',
        receivingCompensation: 'Are you receiving federal, state, or railroad unemployment compensation?',
        lastDateEmploymentCompensation: 'What was the last date employment compensation was paid through?',
        separationDate: 'What was your separation date (Box 12b-DD Form 214, Member 4)?',
        separationReason: 'What was the reason for separation (Box 28-DD Form 214, Member)?',
        characterOfService: 'What was the character of service (Box 24-DD Form 214, Member)?',
        contactInformation: 'Please enter your contact information.',
        deceasedInformation: 'Please provide some information about the deceased.',
        country: 'Country',
        claimNumber: 'Claim Number',
        creditUnion: 'Choose your credit union to get started with your claim process.',
        haveYouReceivedClaimNumber: 'Have you received a claim number?',
        viewOrUpdateClaim: 'I want to view or update my claim',
        fileNewClaim: 'I want to file a new claim',
        privacypolicyurlcmg: 'https://www.cunamutual.com/about-us/general-information/your-privacy',
        privacypolicyurltrustage: 'https://www.trustage.com/legal/privacy'
 }

    public readonly label_IU = {
        selfEmployed: this.label.selfEmployment,
        employerName: this.label.employerName,
        employerNumber: this.label.employerPhone,
        incurredDate: 'What was the first day that you were unemployed?',
        hrsPerWeekPriorToUnemployment: 'How many hours per week did you work prior to becoming unemployed?',
        employedOneYearOrMore: 'Have you been employed with this employer for one year or more?',
        isUnionMember: 'Is your type of employment tied to a trade that you report to a local union for work?',
        unionName: 'What is the name of the Local Union?',
        isTemporaryEmployee: 'Were you hired as a temporary employee?',
        isContractBasis: 'Were you hired on a contract basis?',
        knewLayoffWasPossible: 'At time of hire, did you know that you may be laid off at this time?',
        hasSeasonalEmployment: 'Have you been laid off in the past around this same time of year?',
        isUnemploymentFromCOVID19: 'Is your unemployment due to the Coronavirus (COVID-19)?',
        isReceivingWagesDuringCOVID19Unemployment: 'At any time, have you received wages or income <i>from your employer</i> during this layoff?',
        reasonForUnemployment: 'What is the reason your job ended?',
        differentReasonForUnemployment: 'Please explain:',
        receivingAid: 'Have you received a payment from State Unemployment yet?',
        hasReturnedToWork: 'Have you returned to work?',
        returnDate: 'What date did you return to work?',
        currentHoursPerWeek: 'Hours currently working per week',
        isSeekingEmployment: 'Are you seeking employment?',
        lastDateEmploymentCompensation: this.label.lastDateEmploymentCompensation,
        plansToWorkWithSameEmployer: 'Do you plan to return to work with the same employer?'
    }

    public readonly label_IU_Supp = {
        workedHoursSinceUnemployed: 'Have you worked any hours since the date you became unemployed?',
        firstDateWorkedAfterUnemployed: 'What is the first date you worked after becoming unemployed?', 
        hoursWorkingPerWeek: 'Hours currently working per week',
        plansToWorkWithSameEmployer: 'Do you plan to return to work with the same employer?',
        isSeekingEmployment: 'Are you seeking employment?'
    }

    public readonly additionalClaimProperty = {
        key:{
            claimEntry: 'claimEntry',
            countryCode: 'countryCode',
            employedLongerThanOneYear: 'employedLongerThanOneYear',
            isUnionMember: 'isUnionMember',
            unionName: 'unionName',
            isTemporaryEmployee: 'isTemporaryEmployee',
            isContractBasis: 'isContractBasis',
            knewLayoffWasPossible: 'knewLayoffWasPossible',
            hasSeasonalEmployment: 'hasSeasonalEmployment',
            isUnemploymentFromCOVID19: 'isUnemploymentFromCOVID19',
            isReceivingWagesDuringCOVID19Unemployment: 'isReceivingWagesDuringCOVID19Unemployment',
            reasonForUnemployment: 'reasonForUnemployment',
            differentReasonForUnemployment: 'differentReasonForUnemployment',
            currentHoursPerWeek: 'currentHoursPerWeek',
            isSeekingEmployment: 'isSeekingEmployment',
            plansToWorkWithSameEmployer: 'plansToWorkWithSameEmployer',
            hospitalStartDate: 'hospitalStartDate',
            hospitalEndDate: 'hospitalEndDate',
            hospitalName: 'hospitalName',
            hospitalCity: 'hospitalCity',
            hospitalState: 'hospitalState'
        },
        name:{
            claimEntry: 'Claim Entry',
            countryCode: 'countryCode'
        },
        value:{
            member: 'Member',
            intake: 'Intake'
        }
    }

    public readonly contactUsPhoneNumber = {
        postRegistrationClaimChannel: '1-844-379-3949',
        memberRegistrationClaimChannel: '1-800-621-6323',
        supplementalClaimChannelAndDisability: '1-855-400-8505',
        supplementalClaimChannelAndUnemployment: '1-800-621-6323 ext 665-8094',
        default: '1-800-621-6323'
    }

    public readonly api = {
        configurationURL: environment.apiURL + 'lending-claims-portal-configuration/configuration',
        authURL: environment.apiURL + 'LendingClaims/V1.2.0/Authentication/createtokenbynumber/',
        authNoTrackingIdURL: environment.apiURL + 'LendingClaims/V1.2.0/Authentication/createtokenbyclaim',
        memberRegistrationAuthURL: environment.apiURL + 'LendingClaims/V1.2.0/Authentication/createmembertokenfromculist/',
        loadContactInfoURL: environment.apiURL + 'LendingClaims/V1.3.0/Claims/',
        featureTogglesURL: environment.apiURL + 'lending-claims-portal-configuration/featuretoggles',
        creditUnionsURL: environment.apiURL + 'lending-claims-portal-configuration/creditunions',
        guaranteedDeliveryV1URL: environment.apiURL + 'LendingClaims/V1.1.0/GuaranteedDelivery/',
        guaranteedDeliveryV2URL: environment.apiURL + 'LendingClaims/V2.0.0/GuaranteedDelivery/',
        checkPreExistingURL(trackingNumber, incurredDate, lastDateWorked, drOrderedNoWorkDate){
            return environment.apiURL + 'LendingClaims/V1.3.0/Claims/' + trackingNumber + '/CheckPreExisting?incurredDate=' + incurredDate + '&lastDateWorked=' + lastDateWorked + '&providerSupportedDate=' + drOrderedNoWorkDate;
        },
        getPaymentInfoURL(trackingNumber){
            return environment.apiURL + 'LendingClaims/V1.3.0/Claims/' + trackingNumber + '/PaymentInformation?status=PD,PY&type=CLMPAY';
        },
        statusURL(trackingNumber){
            return environment.apiURL + 'LendingClaims/V1.3.0/Claims/' + trackingNumber + '/Status';
        },
        isGDPRApplicable(trackingNumber){
            return environment.apiURL + 'LendingClaims/V1.3.0/Claims/' + trackingNumber + '/IsGDPRApplicable'
        },
        baseApiHeaders: new HttpHeaders({
            'Content-Type': 'application/json',
            'X-IBM-Client-Id': environment.clientID,
        }),
        headers(token){
            return new HttpHeaders({
            'X-IBM-Client-Id': environment.clientID,
            'Authorization': 'Bearer ' + token
            });
        }
    }

    private readonly required = ' is required.';

    public readonly errorMessage = {
        generic: 'This field' + this.required,
        genericSelect: 'This field' + this.required + ' Select a value.',
        firstNameRequired: this.label.firstName + this.required,
        lastNameRequired: this.label.lastName + this.required,
        claimNumberRequired: this.label.claimNumber + this.required,
        contactFirstNameRequired: this.label.contactFirstName + this.required,
        contactLastNameRequired: this.label.contactLastName + this.required,
        dateRequired: 'A date' + this.required + '\n Date format must be "MM/DD/YYYY".',
        addressRequired: this.label.address + this.required,
        cityRequired: this.label.city + this.required,
        stateRequired: this.label.state + this.required,
        countryRequired: this.label.country + this.required,
        zipCodeRequired: this.label.zipCode + this.required,
        genderRequired: this.label.gender + this.required,
        last4SSNRequired: this.label.last4SSN + this.required,
        descriptionRequired: 'A brief description' + this.required,
        cellPhoneRequired: this.label.cellPhone + this.required,
        emailRequired: this.label.email + this.required + ' Example of a valid email: "email@example.com".',
        emailInvalid: this.label.email + ' is invalid. Example of a valid email: "email@example.com".',
        emailVerify: 'Emails do not match.',
        emailDomainCheck: 'Please verify email address.',
        hoursPerWeek: 'Hours you typically work per week' + this.required,
        hrsPerWeekPriorToUnemployment: 'Hours per week you work prior to becoming unemployed' + this.required,
        formRequired: 'One or more fields are invalid. Please correct any invalid field(s) and try again.',
        claimFormRequired: 'Claim submission failed. One or more fields are invalid. Please correct any invalid field(s) and try again.',
        futureDate: 'This date cannot be set to a future date.',
        checkPreExisting: 'Please fill in all required fields on this claim before checking for additional Pre-Existing questions.',
        checkPreExistingClick: 'Please check for additional Pre-Existing questions.',
        creditUnionRequired: 'Please choose your credit union from the list.',
        afterIncurredDate(incurredDate, lastDayWorked){
            return 'Last date worked (' + lastDayWorked + ') cannot be after the date the event occured (' + incurredDate + ')';
        },
        badRequest(phoneNumber){
            return 'We could not locate your claim. Please verify the information entered is correct and try again.\
            \n If you receive this error again, please call ' + phoneNumber + ' and a claim specialist will help you.';
        },
        internalServer(phoneNumber) {
            return 'Our apologies. It seems that our system is not available at this time. Please try again at a later time.\
            \n If you receive this error again, please call ' + phoneNumber + ' and a claim specialist will help you. ';
        },
        claimSubmitError(phoneNumber) {
            return 'An error occured while trying to submit your claim. Please try again at a later time.\
            \n If you receive this error again, please call ' + phoneNumber + ' and a claim specialist will help you. ';
        }
    }

    public readonly disabilitySteps: MenuItem[] =  [
        { label: 'Claim' },
        { label: 'Work' },
        { label: 'Medical' },
        { label: 'Summary' }
    ];

    public readonly unemploymentSteps: MenuItem[] = [
        { label: 'Claim' },
        { label: 'Work' },
        { label: 'Summary' }
    ];

    public readonly countryCode = {
        usa: 'USA'
    };

    public readonly country: SelectItem[] = [
        { label: 'USA' , value: 'USA', },
        { label: 'Other' , value: 'OT', },
        { label: 'Austria' , value: 'AUS', },
        { label: 'Belgium' , value: 'BEL', },
        { label: 'Bulgaria' , value: 'BGR', },
        { label: 'Republic of Cyprus' , value: 'CYP', },
        { label: 'Czech Republic' , value: 'CZE', },
        { label: 'Germany' , value: 'DEU', },
        { label: 'Denmark' , value: 'DNK', },
        { label: 'Spain' , value: 'ESP', },
        { label: 'Estonia' , value: 'EST', },
        { label: 'Finland' , value: 'FIN', },
        { label: 'France' , value: 'FRA', },
        { label: 'The UK' , value: 'GBR', },
        { label: 'Greece' , value: 'GRC', },
        { label: 'Croatia' , value: 'HRV', },
        { label: 'Hungary' , value: 'HUN', },
        { label: 'Ireland' , value: 'IRL', },
        { label: 'Iceland' , value: 'ISL', },
        { label: 'Italy' , value: 'ITA', },
        { label: 'Lichtenstein' , value: 'LIE', },
        { label: 'Lithuania' , value: 'LTU', },
        { label: 'Luxembourg' , value: 'LUX', },
        { label: 'Latvia' , value: 'LVA', },
        { label: 'Malta' , value: 'MLT', },
        { label: 'Netherlands' , value: 'NLD', },
        { label: 'Norway' , value: 'NOR', },
        { label: 'Poland' , value: 'POL', },
        { label: 'Portugal' , value: 'PRT', },
        { label: 'Romania' , value: 'ROU', },
        { label: 'Slovakia' , value: 'SVK', },
        { label: 'Slovenia' , value: 'SVN', },
        { label: 'Sweden' , value: 'SWE', },
    ]

    public readonly states: SelectItem[] = [
        { label: 'Alabama', value: 'AL' },
        { label: 'Alaska', value: 'AK' },
        { label: 'Arizona', value: 'AZ' },
        { label: 'Arkansas', value: 'AR' },
        { label: 'California', value: 'CA' },
        { label: 'Colorado', value: 'CO' },
        { label: 'Connecticut', value: 'CT' },
        { label: 'Delaware', value: 'DE' },
        { label: 'District of Columbia', value: 'DC' },
        { label: 'Florida', value: 'FL' },
        { label: 'Georgia', value: 'GA' },
        { label: 'Hawaii', value: 'HI' },
        { label: 'Idaho', value: 'ID' },
        { label: 'Illinois', value: 'IL' },
        { label: 'Indiana', value: 'IN' },
        { label: 'Iowa', value: 'IA' },
        { label: 'Kansas', value: 'KS' },
        { label: 'Kentucky', value: 'KY' },
        { label: 'Louisiana', value: 'LA' },
        { label: 'Maine', value: 'ME' },
        { label: 'Maryland', value: 'MD' },
        { label: 'Massachusetts', value: 'MA' },
        { label: 'Michigan', value: 'MI' },
        { label: 'Minnesota', value: 'MN' },
        { label: 'Mississippi', value: 'MS' },
        { label: 'Missouri', value: 'MO' },
        { label: 'Montana', value: 'MT' },
        { label: 'Nebraska', value: 'NE' },
        { label: 'Nevada', value: 'NV' },
        { label: 'New Hampshire', value: 'NH' },
        { label: 'New Jersey', value: 'NJ' },
        { label: 'New Mexico', value: 'NM' },
        { label: 'New York', value: 'NY' },
        { label: 'North Carolina', value: 'NC' },
        { label: 'North Dakota', value: 'ND' },
        { label: 'Ohio', value: 'OH' },
        { label: 'Oklahoma', value: 'OK' },
        { label: 'Oregon', value: 'OR' },
        { label: 'Pennsylvania', value: 'PA' },
        { label: 'Rhode Island', value: 'RI' },
        { label: 'South Carolina', value: 'SC' },
        { label: 'South Dakota', value: 'SD' },
        { label: 'Tennessee', value: 'TN' },
        { label: 'Texas', value: 'TX' },
        { label: 'Utah', value: 'UT' },
        { label: 'Vermont', value: 'VT' },
        { label: 'Virginia', value: 'VA' },
        { label: 'Washington', value: 'WA' },
        { label: 'West Virginia', value: 'WV' },
        { label: 'Wisconsin', value: 'WI' },
        { label: 'Wyoming', value: 'WY' }
    ];

    public readonly pageHeaders = {
        error: 'Error',
        GDPRConsent: 'Consent to Process Data',
        claimMedical: 'Medical Information',
        claimWork: 'Work Information',
        claimContact: 'Contact Information',
        claim: 'Claim Information',
        claimSubmitSuccess: 'Claim Submitted',
        summary: 'Claim Review',
        findYourClaim: 'Your Claim'
    }

    public readonly reasonJobEnded: SelectItem[] = [
        { label: 'Business is slow/Lack of work', value: 'LackOfWork' },
        { label: 'I did not meet the goals set for me by my employer', value: 'NotMeetingExpectations' },
        { label: 'I missed too much work', value: 'MissedWork' },
        { label: 'I resigned/I re-located', value: 'ResignedOrRelocated' },
        { label: 'I violated a company policy or rule', value: 'RuleOrPolicyViolation' },
        { label: 'I was let go due to misconduct', value: 'Misconduct' },
        { label: 'My company downsized/My business closed/My position was eliminated', value: 'BusinessClosureOrPositionEliminated' },
        { label: 'My contract ended', value: 'ContractEnded' },
        { label: 'Seasonal, annual or regularly scheduled layoff', value: 'SeasonalLayoff' },
        { label: 'Other', value: 'Other' },
    ]

    public readonly featureToggles = {
        showCovid19Information: 'showCovid19Information',
        allowUnemploymentPostRegistration: 'allowUnemploymentPostRegistration',
        showLoansOtherCU: 'showLoansOtherCU',
        showSimpleIU: 'showSimpleIU',
        showPortalUpdatesMessage: 'showPortalUpdatesMessage',
        allowHospitalizationLossType: 'allowHospitalizationLossType',
        showDocumentUpload: 'showDocumentUpload',
        useNewGuaranteedDeliveryAPI: 'useNewGuaranteedDeliveryAPI',
        allowTrustageBranding: 'allowTrustageBranding',
        showAnnouncement: "showAnnouncement",
        announcementText: "announcementText",
        announcementTextAppend: "announcementTextAppend"
    }
}
