import { Component } from '@angular/core';
import { ConfigurationService } from '../configuration.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-announcements',
    templateUrl: './announcements.component.html',
    styleUrls: ['./announcements.component.css'],
    standalone: true,
    imports: [NgIf]
})
export class AnnouncementsComponent {

  showAnnouncement:boolean;
  announcementText:string;
  announcementTextAppend = []

  constructor(
    private configService: ConfigurationService
    ) { }
  
    ngOnInit() {
      if (this.configService.configurationData.featureToggles) {
        this.showAnnouncement = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.showAnnouncement);
        this.setAnnouncementText();
      } else {
        this.configService.loadFeatureToggleData().toPromise()
        .then(p_configService => {
          this.showAnnouncement = p_configService.getFeatureToggleValueFromKey(this.configService.featureToggles.showAnnouncement);
          this.setAnnouncementText();
        })
        .catch(err => console.error(err));
      }  
    }

  setAnnouncementText(){
    let _this = this;
    this.announcementText = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.announcementText);

    /* 
    4/5/2023
    This is a less than ideal way of how we map this configuration
    But because we're on a time crunch and limited to 255 characters per value, this is an alternative option to add as much or less announcements texts
    We'll fix this when we can migrate to utilizing the Azure AppConfig API, and/or able to free up other system resources

    For appended texts to work, the key MUST be announcementTextAppend{i} where {i} = 1 and iterate i. 
    ex., announcementTextAppend1, announcementTextAppend2, announcementTextAppend3, etc.
    */

    let j:number = 1;
    this.configService.configurationData.featureToggles.forEach(
      function (value){
        if(value.key.startsWith(_this.configService.featureToggles.announcementTextAppend) && value.key.endsWith(j.toString())){
          _this.announcementText += value.value
          j++;
        }
      }
    )

    /*
    lending-claims-portal-configuration API uses ":" to split key and value. This messes things up when we need to send ":" in the announcement text
    Below decodes the base64 encoded version of ":" (which is "Og==")
    If you need to pass ":", encode it as "Og==" and below code will decode it

    So TLDR, "Og==" -> ":"
    */
    if (this.announcementText.includes(btoa(':'))){
      this.announcementText = this.announcementText.split(btoa(':')).join(':');
    }

  }

  closeModal(){
    this.showAnnouncement = false;
  }

}
