import { Address } from './address'

export class Contact{
    firstName: string
    lastName: string
    suffix: string
    homePhone: string
    cellPhone: string
    email: string
    statusTextOptIn: boolean
    statusEmailOptIn: boolean
    prefCorrespondence: string
    address: Address

    constructor(){
        this.firstName = null;
        this.lastName = null;
        this.suffix = null;
        this.homePhone = null;
        this.cellPhone = null;
        this.email = null;
        this.statusTextOptIn = false;
        this.statusEmailOptIn = false;
        this.prefCorrespondence = null;
        this.address = new Address();
    }
}