import { Component, OnInit } from '@angular/core';
import { StaticInfo } from '../_helpers/static-info';
import { RoutesStaticInfo } from '../_helpers/routes-static-info';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { ClaimService } from '../claim.service';
import { ClaimChannel } from '../enum-claim-channel';
import { ConfigurationService } from 'src/app/configuration.service';
import { ClaimRouterService } from '../claim-router.service';
import { NgIf } from '@angular/common';
import { AccordionModule } from 'primeng/accordion';
import { SharedModule } from 'primeng/api';
import { FieldsetModule } from 'primeng/fieldset';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.css'],
    standalone: true,
    imports: [FieldsetModule, SharedModule, AccordionModule, NgIf]
})
export class FaqComponent implements OnInit {

  staticInfo = new StaticInfo();
  faqContact = this.staticInfo.faqContact;
  urlPath = RoutesStaticInfo;
  memberRegistrationPhoneNumber = this.staticInfo.contactUsPhoneNumber.memberRegistrationClaimChannel
  claimChannel = ClaimChannel;
  gtmFaq = "close";
  AllowTrustage:boolean;
  brandname: string;
  privacyurl:string;
  securityurl:string;
  emailLine2:string;

  /** Directs user to claim page. */
  directToClaimPage(){
    this.claimRouterService.routeToClaimPage()
  }

  /** Changes the gtmFaq property so Google Tag Manager can correctly compare against the class. */
  gtmClassChange(text: string){
    this.gtmFaq = text;
  }

  constructor(
    public contactUs: ContactUsComponent,
    public claimService: ClaimService,
    private claimRouterService: ClaimRouterService,
    private configService: ConfigurationService
    ) { }

  ngOnInit() {
    if (this.configService.configurationData.featureToggles) {
      this.AllowTrustage = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.allowTrustageBranding);
        (this.AllowTrustage && typeof this.AllowTrustage != 'undefined')?
        (this.brandname= this.staticInfo.brandname.trustage,
          this.securityurl=this.staticInfo.url.securityurlTrustage,
          this.privacyurl=this.staticInfo.url.privacyurlTrustage,
          this.emailLine2=this.staticInfo.faqContact.emailLine2Trustage):
        (this.brandname=this.staticInfo.brandname.cmg ,
          this.securityurl=this.staticInfo.url.securityurlcmg,
          this.privacyurl=this.staticInfo.url.privacyurlcmg,
          this.emailLine2=this.staticInfo.faqContact.emailLine2cmg)
    } else {
    this.configService.loadFeatureToggleData().toPromise()
    .then(p_configService => {
      this.AllowTrustage = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.allowTrustageBranding);
        (this.AllowTrustage && typeof this.AllowTrustage != 'undefined')?
        (this.brandname= this.staticInfo.brandname.trustage,
          this.securityurl=this.staticInfo.url.securityurlTrustage,
          this.privacyurl=this.staticInfo.url.privacyurlTrustage,
          this.emailLine2=this.staticInfo.faqContact.emailLine2Trustage):
        (this.brandname=this.staticInfo.brandname.cmg,
          this.securityurl=this.staticInfo.url.securityurlcmg,
          this.privacyurl=this.staticInfo.url.privacyurlcmg,
          this.emailLine2=this.staticInfo.faqContact.emailLine2cmg)
    })
    .catch(err => console.error(err));
}

  }

}
