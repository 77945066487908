<div class="form-group row">
  <div class="col-12 col-form-label">
    {{lbl.addProvider}}
  </div>
</div>
<div *ngIf="claimformService.showProvider.two" class="form-group row ">
  <div class="col-11">
    <div class="form-group row">
      <label for="addProviderName2" class="col-sm-5 col-form-label">
        {{lbl.addProviderName}}
      </label>
      <div class="col-sm-7">
        <input type="text" pInputText class="form-control input-styles" id="addProviderName2" [(ngModel)]="claimService.claimRoot.claim.incident.physician2.name"
        name="addProviderName2" #addProviderName2="ngModel">
      </div>
    </div>
    <div class="form-group row">
      <label for="addProviderPhone2" class="col-sm-5 col-form-label">
        {{lbl.addProviderPhone}}
      </label>
      <div class="col-sm-7">
        <p-inputMask styleClass="input-styles" type="tel" id="addProviderPhone2" [(ngModel)]="claimService.claimRoot.claim.incident.physician2.phoneNumber"
        name="addProviderPhone2" #addProviderPhone2="ngModel" mask="999-999-9999" styleClass="input-styles"></p-inputMask>
      </div>
    </div>
  </div>
  <div class="col-1">
    <button icon="pi pi-times" pButton type="button" label="Remove Provider" class="p-button-danger" (click)="claimformService.removeProvider(2)"></button>
  </div>
</div>
<div *ngIf="claimformService.showProvider.three" class="form-group row ">
  <div class="col-11">
    <div class="form-group row">
      <label for="addProviderName3" class="col-sm-5 col-form-label">
        {{lbl.addProviderName}}
      </label>
      <div class="col-sm-7">
        <input type="text" pInputText class="form-control input-styles" id="addProviderName3" [(ngModel)]="claimService.claimRoot.claim.incident.physician3.name"
        name="addProviderName3" #addProviderName2="ngModel">
      </div>
    </div>
    <div class="form-group row">
      <label for="addProviderPhone3" class="col-sm-5 col-form-label">
        {{lbl.addProviderPhone}}
      </label>
      <div class="col-sm-7">
        <p-inputMask styleClass="input-styles" type="tel" id="addProviderPhone3" [(ngModel)]="claimService.claimRoot.claim.incident.physician3.phoneNumber"
        name="addProviderPhone3" #addProviderPhone2="ngModel" mask="999-999-9999" styleClass="input-styles"></p-inputMask>
      </div>
    </div>
  </div>
  <div class="col-1">
      <button pButton type="button" icon="pi pi-times" label="Remove Provider" class="p-button-danger" (click)="claimformService.removeProvider(3)"></button>
  </div>
</div>
<div *ngIf="claimformService.showProvider.four" class="form-group row ">
  <div class="col-11">
    <div class="form-group row">
      <label for="addProviderName4" class="col-sm-5 col-form-label">
        {{lbl.addProviderName}}
      </label>
      <div class="col-sm-7">
        <input type="text" pInputText class="form-control input-styles" id="addProviderName4" [(ngModel)]="claimService.claimRoot.claim.incident.physician4.name"
        name="addProviderName4" #addProviderName2="ngModel">
      </div>
    </div>
    <div class="form-group row">
      <label for="addProviderPhone4" class="col-sm-5 col-form-label">
        {{lbl.addProviderPhone}}
      </label>
      <div class="col-sm-7">
        <p-inputMask styleClass="input-styles" type="tel" id="addProviderPhone4" [(ngModel)]="claimService.claimRoot.claim.incident.physician4.phoneNumber"
        name="addProviderPhone4" #addProviderPhone2="ngModel" mask="999-999-9999" styleClass="input-styles"></p-inputMask>
      </div>
    </div>
  </div>
  <div class="col-1">
    <button pButton type="button" icon="pi pi-times" label="Remove Provider" class="p-button-danger" (click)="claimformService.removeProvider(4)"></button>
  </div>
</div>
<div *ngIf="claimformService.showProvider.five" class="form-group row ">
  <div class="col-11">
    <div class="form-group row">
      <label for="addProviderName5" class="col-sm-5 col-form-label">
        {{lbl.addProviderName}}
      </label>
      <div class="col-sm-7">
        <input type="text" pInputText class="form-control input-styles" id="addProviderName5" [(ngModel)]="claimService.claimRoot.claim.incident.physician5.name"
          name="addProviderName5" #addProviderName2="ngModel">
      </div>
    </div>
    <div class="form-group row">
      <label for="addProviderPhone5" class="col-sm-5 col-form-label">
        {{lbl.addProviderPhone}}
      </label>
      <div class="col-sm-7">
        <p-inputMask styleClass="input-styles" type="tel" id="addProviderPhone5" [(ngModel)]="claimService.claimRoot.claim.incident.physician5.phoneNumber"
        name="addProviderPhone5" #addProviderPhone2="ngModel" mask="999-999-9999" styleClass="input-styles"></p-inputMask>
      </div>
    </div>
  </div>
  <div class="col-1">
    <button pButton type="button" icon="pi pi-times" label="Remove Provider" class="p-button-danger" (click)="claimformService.removeProvider(5)"></button>
  </div>
</div>

<div *ngIf="!claimformService.showProvider.two || !claimformService.showProvider.three || !claimformService.showProvider.four || !claimformService.showProvider.five" class="form-group row">
  <div class="col-sm-5"></div>
  <div for="addProvider" class="col-sm-7 col-form-label">
    <button pButton type="button" id="addProvider" icon="pi pi-plus" label="Additional Health Care Providers" (click)="showAdditionalProviders()"></button>
  </div>
</div>