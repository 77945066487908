import { Component, OnInit } from '@angular/core';
import { ClaimService } from '../claim.service';
import cssVars from 'css-vars-ponyfill';
import { ClaimformService } from '../claimform.service';
import { PhoneService } from '../phone.service';
import { ClaimRouterService } from '../claim-router.service';
import { AuthenticationService } from '../authentication.service';
import { ConfigurationService } from 'src/app/configuration.service';
import { StaticInfo } from '../_helpers/static-info';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-gdpr-consent',
    templateUrl: './gdpr-consent.component.html',
    styleUrls: ['./gdpr-consent.component.css'],
    standalone: true,
    imports: [NgIf]
})
export class GdprConsentComponent implements OnInit {
  gdprconsentform:string;
  memberclaimemail:string;
  staticInfo: StaticInfo = new StaticInfo();
  AllowTrustage:boolean;

  isChannelIntake():boolean{
    return this.claimService.claimChannel === this.claimService.enumClaimChannel.Intake;
  }

  acceptGDPRConsent(){
    if(this.isChannelIntake() === true){
      this.claimService.routeIntakeBasedOnLossType();
    } else {
      this.claimRouterService.routeToClaimPage();
    }
  }

  declineGDPRConsent(){
    this.authService.token = null;
    this.claimRouterService.routeToLinkClaimFindYourClaim();
  }

  constructor(
    public claimService: ClaimService,
    public claimformService: ClaimformService,
    public phoneService: PhoneService,
    private claimRouterService: ClaimRouterService,
    private authService: AuthenticationService,
    private configService: ConfigurationService
    ) { }

  ngOnInit() {
    cssVars();
    if (this.configService.configurationData.featureToggles) {
      this.AllowTrustage = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.allowTrustageBranding);
        (this.AllowTrustage && typeof this.AllowTrustage != 'undefined')?
        (this.gdprconsentform= this.staticInfo.gdprconsent.gdprconsentformTrustage,
          this.memberclaimemail= this.staticInfo.gdprconsent.emailMemberclaimTrustage):
        (this.gdprconsentform= this.staticInfo.gdprconsent.gdprconsentformcmg,
          this.memberclaimemail= this.staticInfo.gdprconsent.emailMemberclaimcmg)
        
    } else {
    this.configService.loadFeatureToggleData().toPromise()
    .then(p_configService => {
      this.AllowTrustage = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.allowTrustageBranding);
        (this.AllowTrustage && typeof this.AllowTrustage != 'undefined')?
        (this.gdprconsentform= this.staticInfo.gdprconsent.gdprconsentformTrustage,
          this.memberclaimemail= this.staticInfo.gdprconsent.emailMemberclaimTrustage):
        (this.gdprconsentform= this.staticInfo.gdprconsent.gdprconsentformcmg,
          this.memberclaimemail= this.staticInfo.gdprconsent.emailMemberclaimcmg)
        })
    .catch(err => console.error(err));
}
  }

}
