export class Address{
    line1: string;
    line2: string;
    city: string;
    state: string;
    zip: string;
    countryCode: string;
    country: string;

    constructor(){
        this.line1 = null;
        this.line2 = null;
        this.city = null;
        this.state = null;
        this.zip = null;
        this.countryCode = null;
        this.country = null;
    }
}