<app-main-header></app-main-header>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-9">
            <div style="padding: 10px">
                <span *ngIf="configService.isLoadingConfigurations">
                    <i class="pi pi-spin pi-spinner" style="font-size: 3em"></i>
                </span>
                <app-announcements></app-announcements>
                <router-outlet (activate)="scrollToTop()"></router-outlet>
            </div>
        </div>
        <div class="col-md-3">
            <app-contact-us></app-contact-us>
        </div>
    </div>
</div>

<app-session></app-session>
<app-main-footer></app-main-footer>