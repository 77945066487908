<span class="claim-form">
  <fieldset class="form-group">
    <div class="row">
      <div class="col-sm-5">
        <legend>
          {{claimformService.lbl.returnedToWork}} <span class="form-error-text">*</span>
        </legend>
      </div>
      <div class="col-sm-7">
        <div class="row" id="returnedToWork">
          <div class="col-12 radio-button">
            <p-radioButton name="returnedToWork" [value]="true" required label="{{claimformService.yesNo.yes}}"
              [(ngModel)]="claimService.claimRoot.claim.employment.hasReturnedToWork" inputId="returnedToWork1"
              id="returnedToWorkYes" #returnedToWork="ngModel"
              (click)="claimformService.clearField(claimformService.clearFormField.hasReturnedToWorkYes)">
            </p-radioButton>
          </div>
          <div class="col-12 radio-button">
            <p-radioButton name="returnedToWork" [value]="false" required label="{{claimformService.yesNo.no}}"
              [(ngModel)]="claimService.claimRoot.claim.employment.hasReturnedToWork" inputId="returnedToWork2"
              id="returnedToWorkNo" #returnedToWork="ngModel"
              (click)="claimformService.clearField(claimformService.clearFormField.hasReturnedToWork)">
            </p-radioButton>
          </div>
        </div>
        <div
          *ngIf="(returnedToWork.invalid && !returnedToWork.pristine) || (claimForm.submitted && returnedToWork.invalid)"
          class="alert alert-danger">
          {{claimformService.errMsg.generic}}
        </div>
      </div>
    </div>
  </fieldset>
  <div *ngIf="claimService.claimRoot.claim.employment.hasReturnedToWork" class="form-group row">
    <label for="returnedToWorkDate" class="col-sm-5 col-form-label">
      {{claimformService.lbl.returnedToWorkDate}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <p-calendar id="returnedToWorkDate" required [monthNavigator]="true" [yearNavigator]="true" [showOnFocus]="false"
        [touchUI]="claimformService.touchUI" yearRange="{{claimformService.lbl.todayPlusMinus90Yrs}}" dataType="string"
        [(ngModel)]="claimService.claimRoot.claim.employment.returnDate" class="ui-fluid" name="returnedToWorkDate"
        placeholder="MM/DD/YYYY" #returnedToWorkDate="ngModel" [showIcon]="true" rPCalendarMask styleClass="input-styles" dateMask></p-calendar>
      <div
        *ngIf="(returnedToWorkDate.invalid && !returnedToWorkDate.pristine) || (claimForm.submitted && returnedToWorkDate.invalid)"
        class="alert alert-danger">
        {{claimformService.errMsg.dateRequired}}
      </div>
    </div>
  </div>
  <div *ngIf="claimService.claimRoot.claim.employment.hasReturnedToWork" class="form-group row">
    <label for="hoursPerWeek" class="col-sm-5 col-form-label">
      {{claimformService.lbl.unemploymentHoursPerWeek}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <input type="tel" pInputText pKeyFilter="num" class="form-control input-styles" id="hoursPerWeek" required
        [(ngModel)]="claimService.claimRoot.claim.employment.hoursPerWeek" name="hoursPerWeek" #hoursPerWeek="ngModel"
        maxlength="3">
      <div *ngIf="(hoursPerWeek.invalid && !hoursPerWeek.pristine) || (claimForm.submitted && hoursPerWeek.invalid)"
        class="alert alert-danger">
        {{claimformService.errMsg.hoursPerWeek}}
      </div>
    </div>
  </div>
  <fieldset class="form-group" *ngIf="claimService.claimRoot.claim.employment.hasReturnedToWork === false">
    <div class="row">
      <div class="col-sm-5">
        <legend>
          {{claimformService.lbl.unemploymentBenefits}}
        </legend>
      </div>
      <div class="col-sm-7">
        <div class="row" id="receivingAid">
          <div class="col-12 radio-button">
            <p-radioButton name="receivingAid" [value]="claimformService.yesNo.yes"
              label="{{claimformService.yesNo.yes}}" [(ngModel)]="claimService.claimRoot.claim.employment.receivingAid"
              inputId="receivingAid1" id="receivingAidYes" #receivingAid="ngModel"
              (click)="claimformService.clearField(claimformService.clearFormField.receivingAidYes)"></p-radioButton>
          </div>
          <div class="col-12 radio-button">
            <p-radioButton name="receivingAid" [value]="claimformService.yesNo.no" label="{{claimformService.yesNo.no}}"
              [(ngModel)]="claimService.claimRoot.claim.employment.receivingAid" inputId="receivingAid2"
              id="receivingAidNo" #receivingAid="ngModel"
              (click)="claimformService.clearField(claimformService.clearFormField.receivingAid)"></p-radioButton>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
  <div class="form-group row"
    *ngIf="claimService.claimRoot.claim.employment.receivingAid === claimformService.yesNo.no">
    <label for="noAidDescription" class="col-sm-5 col-form-label">
      {{claimformService.lbl.unemploymentExplainBenefits}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <textarea class="input-styles" rows="5" cols="30" id="noAidDescription" required
        [(ngModel)]="claimService.claimRoot.claim.employment.noAidDescription" name="noAidDescription" maxlength="1500"
        pInputTextarea #noAidDescription="ngModel"></textarea>
      <div
        *ngIf="(noAidDescription.invalid && !noAidDescription.pristine) || (claimForm.submitted && noAidDescription.invalid)"
        class="alert alert-danger">
        {{claimformService.errMsg.generic}}
      </div>
    </div>
  </div>
  <div *ngIf="claimService.claimRoot.claim.employment.hasReturnedToWork === false" class="form-group row">
    <label for="contactsPerWeek" class="col-sm-5 col-form-label">
      {{claimformService.lbl.unemploymentJobContact}}
    </label>
    <div class="col-sm-7">
      <input type="tel" pInputText pKeyFilter="num" class="form-control input-styles" id="contactsPerWeek"
        [(ngModel)]="claimService.claimRoot.claim.employment.jobContactsPerWeek" name="contactsPerWeek"
        #contactsPerWeek="ngModel" maxlength="3">
    </div>
  </div>
  <div class="form-group row">
    <label for="loansActive" class="col-sm-5 col-form-label">
      {{claimformService.lbl.loansActive}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <div>
        <ng-select class="fw-normal fs-6" labelForId="loansActive" name="loansActive" #loansActive="ngModel" bindLabel="value" bindValue="value"
                [(ngModel)]="claimService.claimRoot.claim.areLoansActive" [selectOnTab]="true" [searchable]="false" [clearable]="false"
                [items]="claimformService.yesNoUnsure" placeholder="{{claimformService.lbl.loansActive}}" required>
        </ng-select>
      </div>
      <div *ngIf="(loansActive.invalid && !loansActive.pristine) || (claimForm.submitted && loansActive.invalid)"
        class="alert alert-danger">
        {{claimformService.errMsg.genericSelect}}
      </div>
    </div>
  </div>
</span>