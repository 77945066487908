import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { ClaimService } from 'src/app/claim.service';


@Directive({
    selector: '[appEmailDomainCheck]',
    providers: [{ provide: NG_VALIDATORS, useExisting: EmailDomainCheckDirective, multi: true }],
    standalone: true
})
export class EmailDomainCheckDirective implements Validator {

  constructor(private claimService: ClaimService) { }

  validate(control: AbstractControl) {
    if (control.value) {
      // check email domains
      if (control.value.includes('comcast.com') || control.value.includes('concast.net') || control.value.includes('gamil.com') || control.value.includes('gmai.com')
        || control.value.includes('hotmal.com') || control.value.includes('iclou.com') || control.value.includes('outloo.com')
        || control.value.includes('sbcglobal.com') || control.value.includes('txt.att.net') || control.value.includes('yaho.com')
        || control.value.includes('yayoo.com') || control.value.includes('yhaoo.com') || control.value.includes('yhoo.com')) {
        this.claimService.emailDomainCheck = true;
      } else {
        this.claimService.emailDomainCheck = false;
      }

    }

    return null;
  }

}
