import { Component, OnInit, Input } from '@angular/core';
import { ClaimService } from '../claim.service';
import { NgForm, ControlContainer, FormsModule } from '@angular/forms';
import { ClaimformService } from '../claimform.service';
import cssVars from 'css-vars-ponyfill';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AfterIncurredDateDirective } from '../_helpers/custom-validators/after-incurred-date.directive';
import { DateMaskDirective } from '../date-mask.directive';
import { CalendarModule } from 'primeng/calendar';
import { KeyFilterModule } from 'primeng/keyfilter';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { NgIf, NgFor } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';


@Component({
    selector: 'app-form-work',
    templateUrl: './form-work.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    styleUrls: ['./form-work.component.css'],
    standalone: true,
    imports: [NgIf, FormsModule, InputTextModule, InputMaskModule, NgFor, KeyFilterModule, CalendarModule, DateMaskDirective, AfterIncurredDateDirective, RadioButtonModule, InputTextareaModule, NgSelectModule]
})
export class FormWorkComponent implements OnInit {

  @Input() claimForm: NgForm

  constructor(
    public claimService: ClaimService,
    public claimformService: ClaimformService
  ) { }

  ngOnInit() {
    cssVars();
  }

}
