<div class="container">
  <div class="container">
    <h1>CLAIM INFORMATION</h1>
    <h2>Hi, {{claimService.claimRoot.claim.insured.firstName}}!</h2>
    <a type="button" class="btn-link" (click)="routeToInitialClaim()"
          *ngIf="showInitialClaimLink(benefitStatuses)">
      {{lbl.additionalInfo}}</a>
    <a type="button" class="btn-link" (click)="routeToSupplementalClaim()"
          *ngIf="showSupplementalClaimLink(benefitStatuses)">
      {{lbl.additionalInfoIfNeeded}}</a>
    <h3>Here, you can find helpful information about your claim.</h3>
    <h4>Claim Number: {{claimService.claimBenefit.ClaimNumber}}</h4>
  </div>
  <p-accordion styleClass="cuna-header">
    <div *ngFor="let benefitStatus of benefitStatuses; index as i">
      <p-accordionTab header="{{lbl.infoBenefit}}{{benefitStatus.BenefitNumber}}" [selected]="i == 0">
        <div class="container">
          <h4 class="status"><b>{{lbl.infoBenefit}}{{benefitStatus.BenefitNumber}}:</b></h4>
          <ul class="list-group status">
            <li class="list-group-item"><b>{{lbl.status}}:</b> {{benefitStatus.ClaimsExpressStatus}}</li>
            <li class="list-group-item"><b>{{lbl.cuName}}:</b> {{benefitStatus.CreditUnionName}}</li>
            <li class="list-group-item"><b>{{lbl.loanNumber}}:</b> {{benefitStatus.LoanNumber}}</li>
            <li class="list-group-item"><b>{{lbl.loanPurpose}}:</b> {{benefitStatus.LoanPurpose}}</li>
            <li class="list-group-item"
            *ngIf="benefitStatus.ClaimsExpressStatus == staticInfo_benefitStatus.registered || benefitStatus.ClaimsExpressStatus == staticInfo_benefitStatus.denied">
              <b>{{lbl.explanationBenfStatus}}:</b>
              <span *ngIf="benefitStatus.ClaimsExpressStatus == staticInfo_benefitStatus.registered">
                {{lbl.benfRegistered(benefitStatus.LoanPurpose)}}
              </span>
              <span *ngIf="benefitStatus.ClaimsExpressStatus == staticInfo_benefitStatus.denied">
                {{lbl.benfDenied}}
              </span>
            </li>
          </ul>
          <br>
          <app-benefit-payment-info *ngIf="hasPaymentsHistory(benefitStatus.paymentsHistory.length)" [benefitStatus]="benefitStatus"></app-benefit-payment-info>
        </div>
      </p-accordionTab>
    </div>
  </p-accordion>
</div>
