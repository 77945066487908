import { Component, OnInit } from '@angular/core';
import { StaticInfo } from '../_helpers/static-info';
import { RoutesStaticInfo } from '../_helpers/routes-static-info';
import { AuthenticationService } from '../authentication.service';
import { ClaimService } from '../claim.service';
import { ClaimChannel } from '../enum-claim-channel';
import cssVars from 'css-vars-ponyfill';
import { SessionService } from '../session.service';
import { ClaimformService } from '../claimform.service';
import { PhoneService } from '../phone.service';
import { AuthenticationRequestBody } from '../api-data/auth-request-body';
import { AuthResult } from '../api-data/auth-result';
import { ConfigurationService } from 'src/app/configuration.service';
import { SharedModule } from 'primeng/api';
import { AccordionModule } from 'primeng/accordion';
import { DateMaskDirective } from '../date-mask.directive';
import { CalendarModule } from 'primeng/calendar';
import { NgIf } from '@angular/common';
import { InputMaskModule } from 'primeng/inputmask';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-find-your-claim',
    templateUrl: './find-your-claim.component.html',
    styleUrls: ['./find-your-claim.component.css'],
    standalone: true,
    imports: [FormsModule, InputMaskModule, NgIf, CalendarModule, AccordionModule, SharedModule, DateMaskDirective]
})
export class FindYourClaimComponent implements OnInit {

  staticInfo = new StaticInfo();
  lbl = this.staticInfo.label;
  errMsg = this.staticInfo.errorMessage;
  urlPath = RoutesStaticInfo;
  api = this.staticInfo.api;
  internalServerError: boolean = false
  isLoadingAuth: boolean = false;
  claimChannel = ClaimChannel;
  incorrectKBA = new AuthenticationRequestBody();
  privacypolicy:string;
  AllowTrustage:boolean;

  /** This calls createToken and returns the token if KBA is successful.
   * Upon completion, this performs actions based on the URL entered. */
  findClaimSubmit(authForm){
    if (authForm.valid){
      this.isLoadingAuth = true;
      this.authService.createToken(this.authService.auth)
      .subscribe(
        (data:AuthResult) => {
          this.claimService.trackingNumber = data.trackingId
          this.authService.token = data.token;
          this.isLoadingAuth = false;
        },
        (err) => {
          this.isLoadingAuth = false;
          this.incorrectKBA.number = this.authService.auth.number
          this.incorrectKBA.dateOfBirth = this.authService.auth.dateOfBirth
          this.claimService.showErrorMsg(err);
        },
        () => {
          this.sessionService.startCounter();
          this.claimService.callLoadContactAPI();
        }
      );
    }
  }

  constructor(
    public authService: AuthenticationService,
    public claimformService: ClaimformService,
    public phoneService: PhoneService,
    public claimService: ClaimService,
    private sessionService: SessionService,
    private configService: ConfigurationService
  ) { }
  ngOnInit() {
    cssVars();
    if (this.configService.configurationData.featureToggles) {
      this.AllowTrustage = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.allowTrustageBranding)
      if (this.AllowTrustage && typeof this.AllowTrustage != 'undefined'){
        this.privacypolicy= this.staticInfo.label.privacypolicyurltrustage;
      }else{
        this.privacypolicy= this.staticInfo.label.privacypolicyurlcmg;
      }  
    } else {
    this.configService.loadFeatureToggleData().toPromise()
    .then(p_configService => {
      this.AllowTrustage = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.allowTrustageBranding)
      if (this.AllowTrustage && typeof this.AllowTrustage != 'undefined'){
        this.privacypolicy= this.staticInfo.label.privacypolicyurltrustage;
      }else{
        this.privacypolicy= this.staticInfo.label.privacypolicyurlcmg;
      }
    })
    .catch(err => console.error(err));
  }
}
}
