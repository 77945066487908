import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CmfgUtilitiesService {

  constructor() { }

  public toTitleCase(stringToCase: string) {
    return stringToCase.replace(
      /\w\S*/g,
      function (matchString: string) {
        return matchString.charAt(0).toUpperCase() + matchString.substr(1).toLowerCase();
      }
    );
  }
}
