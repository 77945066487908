import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors, FormGroup, AbstractControl } from '@angular/forms';
import { ClaimService } from '../../claim.service'
import { StaticInfo } from '../static-info'

@Directive({
    selector: '[appMustMatch]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MustMatchDirective, multi: true }],
    standalone: true
})
export class MustMatchDirective implements Validator {
    @Input('appMustMatch') controlName: string[] = [];

    staticInfo = new StaticInfo();

    constructor(
        public claimService: ClaimService
    ) { }

    validate(formGroup: FormGroup): ValidationErrors {
        return this.mustMatch(this.controlName[0], this.controlName[1])(formGroup);
    }

    mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        // return null if controls haven't initialised yet
        if (!control || !matchingControl) {
            return null;
        }

        // return null if another validator has already found an error on the matchingControl
        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            return null;
        }

        // Don't verify the emails if they are not required
        if (this.claimService.claimRoot &&
            this.claimService.claimRoot.contact &&
            (this.claimService.claimRoot.contact.statusEmailOptIn !== true &&
                this.claimService.claimRoot.contact.prefCorrespondence !== "Email")){
                    matchingControl.setErrors(null);
                    return null;
            }

        // return null if there is no value in both of them
        if ((!control.value || control.value === '' || control.value === null) &&
            (!matchingControl.value || matchingControl.value === '' || matchingControl.value === null)) {
            matchingControl.setErrors(null);
            return null;
        }

        // set error on matchingControl if validation fails,
        // only if the types match (IE don't match empty string and undefined)
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}
}