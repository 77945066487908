<h1>CLAIM SUBMITTED</h1>

<h4>
  <br>
  <span *ngIf="claimService.claimChannel == claimService.enumClaimChannel.Supplemental; else defaultMsg">
    Thank you, the update for your {{claimService.claimRoot.claim.lossType | titlecase}} claim was successfully submitted
    for
    {{claimService.claimRoot.claim.insured.firstName}} {{claimService.claimRoot.claim.insured.lastName}}. Your claim
    number is
    {{claimService.claimRoot.claim.claimNumber}}. One of {{brandname}}’s claim specialists will begin review of this
    updated
    information as soon as possible. At the time of review if any additional information is needed you will be contacted.
    Once
    a decision is made, you will receive an update from us via email or mail based on the method of communication
    selected.
  </span>
  <ng-template #defaultMsg>
    <span *ngIf="claimService.claimChannel !== claimService.enumClaimChannel.MemberRegistration; else memberRegMsg">
      Thank you, your {{claimService.claimRoot.claim.lossType | titlecase}} claim request was successfully submitted for
      {{claimService.claimRoot.claim.insured.firstName}} {{claimService.claimRoot.claim.insured.lastName}}. Your claim
      number
      is {{claimService.claimRoot.claim.claimNumber}}. One of {{brandname}}’s claim specialists will begin review of
      your
      claim as soon as possible. At the
      time of review if any additional information is needed you will be contacted. Once a decision is made, you will
      receive
      an update from us via email or mail based on the method of communication selected.
    </span>
  </ng-template>
  <ng-template #memberRegMsg>
    <p style="font-size: 18px;">
      Thank you, your claim request was submitted successfully. One of {{brandname}}’s claim specialists will begin
      review of
      your claim. At the time of review if any additional information is needed you will be contacted. Once a decision is
      made, you
      will receive an update from us via email or mail based on the method of communication selected.
    </p>
  </ng-template>
  <div *ngIf="claimService.claimChannel !== claimService.enumClaimChannel.MemberRegistration">
    <br><br>
    In order to check the status of your claim online at a later date, you can visit our website:
    <br><br>
    <button class="btn btn-link" (click)="routeToClaimPage()" id="claimPageLink">
      Click here to view your claim information
    </button>
  </div>
  <br><br>
  <a class="btn-link faq-text" href={{feedbackForm}} target="_blank" id="claimSurvery">
    We value your opinion. Please take a moment and click here to answer 2 questions about your experience.
  </a>
</h4>
