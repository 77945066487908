import { Employer } from './employer'

export class Employment{
    category: string
    description: string
    employer: Employer
    estimatedRecoverDate: Date
    hasReturnedToWork: boolean
    hasSameDuty: boolean
    hasSameHours: string
    hoursPerWeek: number
    jobClass: string
    lastDateEmploymentCompPaidThru: Date
    lastDateWorked: Date
    militaryStatus: string
    nextAppointmentDate: Date
    receivingAid: string
    returnDate: Date
    originalJobDuties: string
    noAidDescription: string
    jobContactsPerWeek: number
    currentJobDuties: string
    hasSameWages: string
    wageChangeDescription: string
    restrictionsDescription: string
    selfEmployed: string
    characterOfService: string
    separationDate: Date
    separationReason: string
    hoursWithRestriction: number

    constructor(){
        this.description = null;
        this.category = null;
        this.employer = new Employer();
        this.estimatedRecoverDate = null;
        this.hasReturnedToWork = null;
        this.hasSameDuty = null;
        this.hasSameHours = null;
        this.hoursPerWeek = null;
        this.jobClass = null;
        this.lastDateEmploymentCompPaidThru = null;
        this.lastDateWorked = null;
        this.militaryStatus = null;
        this.nextAppointmentDate = null;
        this.receivingAid = null;
        this.returnDate = null;
        this.originalJobDuties = null;
        this.noAidDescription = null;
        this.jobContactsPerWeek = null;
        this.currentJobDuties = null;
        this.hasSameWages = null;
        this.wageChangeDescription = null;
        this.restrictionsDescription = null;
        this.selfEmployed = null;
        this.characterOfService = null;
        this.separationDate = null;
        this.separationReason = null;
        this.hoursWithRestriction = 0;
    }
}