<fieldset class="form-group">
  <div class="row">
    <div class="col-sm-5">
      <legend>
        {{claimformService.lbl.accOrIll}} <span class="form-error-text">*</span>
      </legend>
    </div>
    <div class="col-sm-7">
      <div class="row" id="accOrIll">
        <div class="col-12 radio-button">
          <p-radioButton name="accOrIll" value="{{claimformService.accOrIll.accident}}"
            label="{{claimformService.accOrIll.accident}}" required
            [(ngModel)]="claimService.claimRoot.claim.incident.disabilityType" inputId="accOrIll1" id="acc"
            inputId="accOrIll" id="acc" #accOrIllNg="ngModel"></p-radioButton>
        </div>
        <div class="col-12 radio-button">
          <p-radioButton name="accOrIll" value="{{claimformService.accOrIll.illness}}"
            label="{{claimformService.accOrIll.illness}}" required
            [(ngModel)]="claimService.claimRoot.claim.incident.disabilityType" inputId="accOrIll" id="ill">
          </p-radioButton>
          <div *ngIf="(accOrIllNg.invalid && !accOrIllNg.pristine) || (claimForm.submitted && accOrIllNg.invalid)"
            class="alert alert-danger">
            {{claimformService.errMsg.genericSelect}}
          </div>
        </div>
      </div>
    </div>
  </div>
</fieldset>
<div class="form-group row">
  <label for="providerName" class="col-sm-5 col-form-label">
    {{claimformService.lbl.providerName}}
  </label>
  <div class="col-sm-7">
    <input type="text" pInputText class="form-control input-styles" id="providerName"
      [(ngModel)]="claimService.claimRoot.claim.incident.physician.name" name="providerName" #providerName="ngModel"
      maxlength="50">
  </div>
</div>
<div class="form-group row">
  <label for="providerPhone" class="col-sm-5 col-form-label">
    {{claimformService.lbl.providerPhone}}
  </label>
  <div class="col-sm-7">
    <p-inputMask styleClass="input-styles" type="tel" id="providerPhone" [(ngModel)]="claimService.claimRoot.claim.incident.physician.phoneNumber"
      name="providerPhone" #providerPhone="ngModel" mask="999-999-9999"></p-inputMask>
  </div>
</div>
<app-form-providers></app-form-providers>
<div class="form-group row">
  <label for="medCodeCategory" class="col-sm-5 col-form-label">
    {{claimformService.lbl.medicalCode}} <span class="form-error-text">*</span>
  </label>
  <div class="col-sm-7">
    <div>
      <ng-select class="fw-normal fs-6" labelForId="medCodeCategory" name="medCodeCategory" #medicalCode="ngModel" bindLabel="label" bindValue="value"
          [(ngModel)]="claimService.claimRoot.claim.incident.medicalCodeCategory" [selectOnTab]="true" [searchable]="false" [clearable]="false"
          [items]="claimformService.medCodeCategory" placeholder="{{claimformService.lbl.medicalCode}}" required>
      </ng-select>
    </div>

    <div *ngIf="(medicalCode.invalid && !medicalCode.pristine) || (claimForm.submitted && medicalCode.invalid)"
      class="alert alert-danger">
      {{claimformService.errMsg.genericSelect}}
    </div>
  </div>
</div>
<div *ngIf="claimService.isLossTypeDisability">
  <app-form-disability-description [claimForm]="claimForm"></app-form-disability-description>
</div>
<div *ngIf="claimService.claimRoot.claim.employment.hasReturnedToWork == false" class="form-group row">
  <label for="nextApptDate" class="col-sm-5 col-form-label">
    {{claimformService.lbl.nextApptDate}}
  </label>
  <div class="col-sm-7">
    <p-calendar id="nextApptDate" [monthNavigator]="true" [yearNavigator]="true" [showOnFocus]="false"
      [touchUI]="claimformService.touchUI" yearRange="{{claimformService.lbl.todayPlusMinus90Yrs}}" dataType="string"
      rPCalendarMask [(ngModel)]="claimService.claimRoot.claim.employment.nextAppointmentDate" class="ui-fluid"
      name="nextApptDate" placeholder="MM/DD/YYYY" #nextApptDate="ngModel" [showIcon]="true" styleClass="input-styles" dateMask></p-calendar>
  </div>
</div>
<div *ngIf="claimService.claimRoot.claim.employment.hasReturnedToWork == false" class="form-group row">
  <label for="expectedReturnDate" class="col-sm-5 col-form-label">
    {{claimformService.lbl.expectedReturnDate}}
  </label>
  <div class="col-sm-7">
    <p-calendar id="expectedReturnDate" [monthNavigator]="true" [yearNavigator]="true" [showOnFocus]="false"
      [touchUI]="claimformService.touchUI" yearRange="{{claimformService.lbl.todayPlusMinus90Yrs}}" dataType="string"
      rPCalendarMask [(ngModel)]="claimService.claimRoot.claim.employment.estimatedRecoverDate" class="ui-fluid"
      name="expectedReturnDate" placeholder="MM/DD/YYYY" #expectedReturnDate="ngModel" [showIcon]="true" styleClass="input-styles" dateMask></p-calendar>
  </div>
</div>
<fieldset class="form-group" *ngIf="claimService.isLossTypeDisability">
  <div class="row">
    <div class="col-sm-5">
      <legend>
          {{claimformService.lbl.hasBeenHospitalized}}
      </legend>
    </div>
    <div class="col-sm-7">
      <div class="row" id="hasBeenHospitalized">
        <div class="col-12 radio-button">
          <p-radioButton name="hasBeenHospitalized" [value]="true"
            label="{{claimformService.yesNo.yes}}" [(ngModel)]="claimService.claimRoot.claim.incident.hasBeenHospitalized"
            inputId="hasBeenHospitalized1" id="hasBeenHospitalizedYes"></p-radioButton>
        </div>
        <div class="col-12 radio-button">
          <p-radioButton name="hasBeenHospitalized" [value]="false" label="{{claimformService.yesNo.no}}"
            [(ngModel)]="claimService.claimRoot.claim.incident.hasBeenHospitalized" inputId="hasBeenHospitalized2" id="hasBeenHospitalizedNo"
            (click)="claimformService.clearField(claimformService.clearFormField.hasBeenHospitalized)"></p-radioButton>
        </div>
      </div>
    </div>
  </div>
</fieldset>
<div *ngIf="claimService.claimRoot.claim.incident.hasBeenHospitalized" class="form-group row">
  <label for="hospitalStartDate" class="col-sm-5 col-form-label">
    {{claimformService.lbl.hospitalizationStartDate}}
  </label>
  <div class="col-sm-7">
    <p-calendar id="hospitalStartDate" [monthNavigator]="true" [yearNavigator]="true" [showOnFocus]="false"
      [touchUI]="claimformService.touchUI" yearRange="{{claimformService.lbl.todayPlusMinus90Yrs}}" dataType="string"
      rPCalendarMask [(ngModel)]="claimService.claimRoot.claim.incident.hospitalizationStartDate" class="ui-fluid"
      name="hospitalStartDate" placeholder="MM/DD/YYYY" #hospitalStartDate="ngModel" [showIcon]="true" styleClass="input-styles" dateMask></p-calendar>
  </div>
</div>
<div *ngIf="claimService.claimRoot.claim.incident.hasBeenHospitalized" class="form-group row">
  <label for="hospitalEndDate" class="col-sm-5 col-form-label">
    {{claimformService.lbl.hospitalizationEndDate}}
  </label>
  <div class="col-sm-7">
    <p-calendar id="hospitalEndDate" [monthNavigator]="true" [yearNavigator]="true" [showOnFocus]="false"
      [touchUI]="claimformService.touchUI" yearRange="{{claimformService.lbl.todayPlusMinus90Yrs}}" dataType="string"
      rPCalendarMask [(ngModel)]="claimService.claimRoot.claim.incident.hospitalizationEndDate" class="ui-fluid"
      name="hospitalEndDate" placeholder="MM/DD/YYYY" #hospitalEndDate="ngModel" [showIcon]="true" styleClass="input-styles" dateMask></p-calendar>
  </div>
</div>
<div class="form-group row" *ngIf="claimService.claimChannel == claimService.enumClaimChannel.Intake">
  <div class="col-sm-5"></div>
  <div class="col-sm-7">
    <button pButton type="button" class="ui-button-warning h3-btn" icon="pi pi-search" label="Check Pre-Existing"
      (click)="claimformService.checkPreExisting()"></button>
    <div *ngIf="claimForm.submitted && !claimService.isPeClicked" class="alert alert-danger">
      {{claimformService.errMsg.checkPreExistingClick}}
    </div>
    <div *ngIf="claimformService.isLoadingPreExisting">
      {{claimformService.lbl.checkForPreExisting}}<i class="pi pi-spin pi-spinner" style="font-size: 3em"></i>
    </div>
  </div>
</div>
<div class="form-group row" *ngIf="claimService.claimChannel == claimService.enumClaimChannel.Intake">
  <div class="col-sm-5"></div>
  <div class="col-sm-7">
    <p-messages [(value)]="claimformService.preExistingMessage"></p-messages>
  </div>
</div>
<div *ngIf="claimformService.showPreExistingQuestions">
  <div class="form-group row">
    <label for="preExistingIllDate" class="col-sm-5 col-form-label">
      {{claimformService.lbl.preExistingIllDate}}
    </label>
    <div class="col-sm-7">
      <p-calendar id="preExistingIllDate" [monthNavigator]="true" [yearNavigator]="true" [showOnFocus]="false"
        [touchUI]="claimformService.touchUI" yearRange="{{claimformService.lbl.todayPlusMinus90Yrs}}" dataType="string"
        placeholder="MM/DD/YYYY" rPCalendarMask [(ngModel)]="claimService.claimRoot.claim.incident.incidentDate"
        class="ui-fluid" name="preExistingIllDate" #preExistingIllDate="ngModel" [showIcon]="true" styleClass="input-styles" dateMask></p-calendar>
    </div>
  </div>
  <div class="form-group row">
    <label for="preExistingProviderDate" class="col-sm-5 col-form-label">
      {{claimformService.lbl.preExistingProviderDate}}
    </label>
    <div class="col-sm-7">
      <p-calendar id="preExistingProviderDate" [monthNavigator]="true" [yearNavigator]="true" [showOnFocus]="false"
        [touchUI]="claimformService.touchUI" yearRange="{{claimformService.lbl.todayPlusMinus90Yrs}}" dataType="string"
        placeholder="MM/DD/YYYY" rPCalendarMask
        [(ngModel)]="claimService.claimRoot.claim.incident.initialAppointmentDate" class="ui-fluid"
        name="preExistingProviderDate" #preExistingProviderDate="ngModel" [showIcon]="true" styleClass="input-styles" dateMask></p-calendar>
    </div>
  </div> 
</div>
<div *ngIf="claimService.isLossTypeDisability == false">
  <app-form-description></app-form-description>
</div>