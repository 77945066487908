<div class="modal-body">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Community Resources</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="contactUs.modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <br>
  <div>
    <b>
      We believe that a brighter financial future should be accessible to everyone; as such, we focus on the interests
      of our customers and communities to empower each other to achieve great things together. In this spirit, below are
      community resources that may be able to provide support or assistance to you.
    </b>
  </div>
  <br>
  <div>
    <p-fieldset [toggleable]="true">
      <p-header>
        <b>
          Food, Shelter, Housing Expenses
        </b>
      </p-header>
      <b><u>Credit Union</u>:</b> Your local credit union may have programs, options or resources to consider during
      times
      of
      financial hardship.
      <br>
      <br>
      <b><u>United Way</u>:</b> There are approximately 1800 local United Way agencies world-wide to assist with basic
      needs, such as
      food, shelter, housing expenses and healthcare.
      <ul>
        <li><b>Website:</b>&nbsp; <a href="https://www.211.org/" target="_blank">211.org</a></li>
        <li><b>Phone:</b> Dial 2-1-1. Operated by the United Way 24 hours a day, 7 days a week.</li>
      </ul>
      <b><u>Feeding America</u>:</b> A nationwide network of food banks that distributes over 4.3 billion meals each
      year
      through food
      pantries and meal programs.
      <ul>
        <li><b>Website:</b>&nbsp; <a href="https://www.feedingamerica.org/" target="_blank">FeedingAmerica.org</a>
          (Click
          on ‘Find A Food
          Bank’ and search by zip code or state)</li>
      </ul>
    </p-fieldset>
    <br>
    <p-fieldset [toggleable]="true">
      <p-header>
        <b>
          Financial Needs
        </b>
      </p-header>
      <b><u>Credit Union</u>:</b> Your local credit union may have programs, options or resources to consider during
      times
      of financial
      hardship.
      <br>
      <br>
      <b><u>Bill Paying</u>:</b> Learn about government programs to help pay for phone bills, medical bills, home energy
      bills,
      prescription drugs and other expenses.
      <ul>
        <li><b>Website:</b>&nbsp; <a href="https://www.usa.gov/" target="_blank">USA.gov</a> (Click on ‘Benefits, Grants
          &
          Loans’ link at
          the top of the page)</li>
        <li><b>Phone:</b> 844.872.4681</li>
      </ul>
      <b><u>Involuntary Unemployment</u>:</b> Government sponsored sit to learn how to apply for unemployment benefits,
      workers’
      compensation, welfare or temporary assistance, and other programs and services that can help if you lose your job.
      <ul>
        <li><b>Website:</b>&nbsp; <a href="https://www.usa.gov/" target="_blank">USA.gov</a> (Click on ‘Jobs &
          Unemployment” link at the
          top of the page)</li>
        <li><b>Phone:</b> 844.872.4681</li>
      </ul>
      <br>
    </p-fieldset>
    <p-fieldset [toggleable]="true">
      <p-header>
        <b>
          Family Resources, Eldercare & Human Needs
        </b>
      </p-header>
      <ul>
        <li>
          <a href="https://www.211.org/" target="_blank">
            United Way-211: Assist with basic needs, such as food, shelter and healthcare</a>
        </li>
        <li>
          <a href="https://eldercare.acl.gov/Public/Index.aspx" target="_blank">
            Eldercare: U.S. Administration on Aging- assist with housing, transportation and other benefits</a>
        </li>
        <li>
          <a href="https://www.salvationarmyusa.org/usn/" target="_blank">
            Salvation Army: Assist with Human Needs</a>
        </li>
        <li>
          <a href="https://www.bgca.org/" target="_blank">
            Boys & Girls Club of America: After school programs focused on academics & health</a>
        </li>
        <li>
          <a href="https://www.cdc.gov/coronavirus/2019-ncov/community/schools-childcare/decision-tool.html"
            target="_blank">
            Centers for Disease Control: School Decision-Making Tool for Parents and Caregivers</a>
        </li>
        <li>
          <a href="http://blog.healthadvocate.com/2020/03/resources-for-parents-supporting-at-home-children/"
            target="_blank">
            Health Advocate: Parent Resource List for Supporting Children at Home</a>
        </li>
      </ul>
    </p-fieldset>
  </div>
</div>