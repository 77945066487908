import { Component, OnInit } from '@angular/core';
import { headerjs,headerhide } from '../main-header/header.js'

@Component({
    selector: 'app-main-header',
    templateUrl: './main-header.component.html',
    styleUrls: ['./main-header.component.css'],
    standalone: true
})


export class MainHeaderComponent implements OnInit {

  // title = "MEMBER CLAIMS REGISTRATION FOR PAYMENT PROTECTION";

  constructor() { }

  ngOnInit() {
    headerjs()
  }

  ngAfterViewChecked(){
    headerhide()
  }

}
