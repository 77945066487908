<span class="claim-form">
  <div class="form-group row">
    <label for="homePhone" class="col-sm-5 col-form-label">
      {{claimformService.lbl.homePhone}}
    </label>
    <div class="col-sm-7">
      <p-inputMask styleClass="input-styles" type="tel" id="homePhone" [(ngModel)]="claimService.claimRoot.contact.homePhone" name="homePhone"
        mask="999-999-9999" styleClass="input-styles"></p-inputMask>
    </div>
  </div>
  <div class="form-group row">
    <label for="cellPhone" class="col-sm-5 col-form-label">
      {{claimformService.lbl.cellPhone}} <span class="form-error-text" [hidden]="!claimformService.blnTxtUpdate">*</span>
    </label>
    <div class="col-sm-7">
      <p-inputMask styleClass="input-styles" type="tel" id="cellPhone" [(ngModel)]="claimService.claimRoot.contact.cellPhone" name="cellPhone"
        required="{{claimformService.blnTxtUpdate}}" #cellPhone="ngModel" mask="999-999-9999" styleClass="input-styles"></p-inputMask>
      <div [hidden]="!claimformService.blnTxtUpdate">
        <div *ngIf="(cellPhone.invalid && !cellPhone.pristine) || (claimForm.submitted && cellPhone.invalid)"
          class="alert alert-danger">
          {{claimformService.errMsg.cellPhoneRequired}}
        </div>
      </div>
    </div>
  </div>
  <fieldset class="form-group" *ngIf="!internationalAddressService.isForeignCountry">
    <div class="row">
      <div class="col-sm-5">
        <legend>
          {{claimformService.lbl.txtUpdate}}
        </legend>
      </div>
      <div class="col-sm-7">
        <div class="row" id="txtUpdates">
          <div class="col-12 radio-button">
            <p-radioButton name="txtUpdates" [value]="true"
              label="{{claimformService.yesNo.yes}}" [(ngModel)]="claimService.claimRoot.contact.statusTextOptIn"
              inputId="txtOpt1" id="txtUpdatesYes" (click)="claimformService.txtUpdate()"></p-radioButton>
          </div>
          <div class="col-12 radio-button">
            <p-radioButton name="txtUpdates" [value]="false" label="{{claimformService.yesNo.no}}"
              [(ngModel)]="claimService.claimRoot.contact.statusTextOptIn" inputId="txtOpt2" id="txtUpdatesNo"
              (click)="claimformService.txtUpdate()"></p-radioButton>
          </div>
        </div>
      </div>
    </div>
  </fieldset>

  <div class="form-group row">
    <label for="prefCorrespondence" class="col-sm-5 col-form-label">
      {{claimformService.lbl.preferredContactMethod}}
      <br>{{claimformService.lbl.emailDisclaimer}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <div>
        <ng-select class="fw-normal fs-6" labelForId="prefCorrespondence" name="prefCorrespondence" #prefCorrespondence="ngModel" bindLabel="value" bindValue="value"
          [(ngModel)]="claimService.claimRoot.contact.prefCorrespondence" [selectOnTab]="true" [searchable]="false" [clearable]="false" (ngModelChange)="setPreferredMethod()"
          [items]="claimformService.preferredContactMethod" placeholder="" required>
        </ng-select>
        </div>
      <div
        *ngIf="(prefCorrespondence.invalid && !prefCorrespondence.pristine) || (claimForm.submitted && prefCorrespondence.invalid)"
        class="alert alert-danger">
        {{claimformService.errMsg.genericSelect}}
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="email" class="col-sm-5 col-form-label">
      {{claimformService.lbl.email}} <span class="form-error-text"
        *ngIf="claimformService.blnEmailUpdate || claimformService.blnEmailPreferred">*</span>
    </label>
    <div class="col-sm-7">
      <input type="email" pInputText appEmailDomainCheck class="form-control input-styles" id="email"
        [(ngModel)]="claimService.claimRoot.contact.email" name="email"
        required="{{claimformService.blnEmailPreferred || claimformService.blnEmailUpdate}}" #email="ngModel" email>
      <div *ngIf="claimService.emailDomainCheck" class="alert alert-warning">
        {{claimformService.errMsg.emailDomainCheck}}</div>
      <div *ngIf="(email.invalid && !email.pristine) || (claimForm.submitted && email.invalid)"
        class="alert alert-danger">
        <span *ngIf="!claimformService.blnEmailPreferred && !claimformService.blnEmailUpdate; else emailRequired">
          {{claimformService.errMsg.emailInvalid}}
        </span>
        <ng-template #emailRequired>
          {{claimformService.errMsg.emailRequired}}
        </ng-template>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="verifyEmail" class="col-sm-5 col-form-label">
      {{claimformService.lbl.verifyEmail}} <span class="form-error-text"
        *ngIf="claimformService.blnEmailUpdate || claimformService.blnEmailPreferred">*</span>
    </label>
    <div class="col-sm-7">
      <input type="email" pInputText class="form-control" id="verifyEmail" [(ngModel)]="claimService.verifyEmail"
        name="verifyEmail" #matchEmail="ngModel" class="input-styles">
      <div *ngIf="(matchEmail.invalid && !matchEmail.pristine) || (claimForm.submitted && matchEmail.invalid)"
        class="alert alert-danger">
        {{claimformService.errMsg.emailVerify}}
      </div>
    </div>
  </div>
  <fieldset class="form-group" [hidden]="!claimformService.showEmailStatus">
    <div class="row">
      <div class="col-sm-5">
        <legend>
          {{claimformService.lbl.emailUpdate}}
        </legend>
      </div>
      <div class="col-sm-7">
        <div class="row" id="emailUpdates">
          <div class="col-12 radio-button">
            <p-radioButton name="emailUpdates" [value]="true"
              label="{{claimformService.yesNo.yes}}" [(ngModel)]="claimService.claimRoot.contact.statusEmailOptIn"
              inputId="emailOpt1" id="emailUpdatesYes" (click)="claimformService.emailUpdate()"></p-radioButton>
          </div>
          <div class="col-12 radio-button">
            <p-radioButton name="emailUpdates" [value]="false" label="{{claimformService.yesNo.no}}"
              [(ngModel)]="claimService.claimRoot.contact.statusEmailOptIn" inputId="emailOpt2" id="emailUpdatesNo"
              (click)="claimformService.emailUpdate()"></p-radioButton>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</span>