<div class="form-group row">
  <label for="describeDisability" class="col-sm-5 col-form-label">
    {{claimformService.lbl.describeDisability}} <span class="form-error-text">*</span>
  </label>
  <div class="col-sm-7">
    <textarea class="input-styles" rows="5" cols="30" id="description" required [(ngModel)]="claimService.claimRoot.claim.description"
    placeholder="{{claimService.descriptionPlaceHolder}}" name="description" maxlength="1500" pInputTextarea #description="ngModel"></textarea>
    <div *ngIf="(description.invalid && !description.pristine) || (claimForm.submitted && description.invalid)"
      class="alert alert-danger">
      {{claimformService.errMsg.descriptionRequired}}
    </div>
  </div>
  
</div>