import { Injectable } from '@angular/core';
import { Subscription, Observable, timer } from 'rxjs';
import { UUID } from './uuid';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  // For google analytics
  sessionId: string;

  timerSubscription: Subscription;
  counter: number = 0;
  timer: Observable<number>;

  timeLeftSubscription: Subscription;
  timeLeftCounter: number = 0;
  timeLeft: Observable<number>;

  /** Starts the session timer. */
  startCounter() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    this.counter = 0;
    this.timer = timer(1000, 1000);
    this.timerSubscription = this.timer.subscribe(n => {
      this.counter++;
    });
  }

  /** Shows how much time is left for the user until their session expires. */
  startSessionWarningCounter(){
    if (this.timeLeftSubscription) {
      this.timeLeftSubscription.unsubscribe();
    }

    this.timeLeftCounter = 600;
    this.timeLeft = timer(1000, 1000);
    this.timeLeftSubscription = this.timeLeft.subscribe(n => {
      this.timeLeftCounter--;
    });
  }

  /** Restarts the session timer. */
  restartCounter(){
    this.counter = 0;
    this.startCounter();
  }

constructor() {
  this.sessionId = UUID.UUID();
}
}
