import { Injectable } from '@angular/core';
import { ClaimChannel } from './enum-claim-channel';
import { LossType } from './claim-data/enum-losstype';
import { StaticInfo } from './_helpers/static-info';


@Injectable({
  providedIn: 'root'
})


export class PhoneService {

  staticInfo = new StaticInfo();

  contactPhoneNumber = this.staticInfo.contactUsPhoneNumber

  phoneNumber: string = this.contactPhoneNumber.default;

  constructor() { }

  /** Gets the contact phone number based on the user's role and loss type.  */
  getPhoneNumber(claimChannel:ClaimChannel, lossType?:LossType){
    if(claimChannel == ClaimChannel.MemberRegistration){
      this.phoneNumber = this.contactPhoneNumber.memberRegistrationClaimChannel;
    }else if(claimChannel == ClaimChannel.PostRegistration){
      this.phoneNumber = this.contactPhoneNumber.postRegistrationClaimChannel;
    }else if((claimChannel == ClaimChannel.Supplemental) && (lossType == LossType.Disability)){
      this.phoneNumber = this.contactPhoneNumber.supplementalClaimChannelAndDisability;
    }else if((claimChannel == ClaimChannel.Supplemental) &&(lossType == LossType.Unemployment)){
      this.phoneNumber = this.contactPhoneNumber.supplementalClaimChannelAndUnemployment;
    }else{
      this.phoneNumber = this.contactPhoneNumber.default;
    }
  }
}
