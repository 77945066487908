export class Insured{
    dateOfBirth: Date
    firstName: string
    lastName: string
    gender: string
    last4SSN: string

    constructor(){
        this.dateOfBirth = null;
        this.firstName = null;
        this.lastName = null;
        this.gender = null;
        this.last4SSN = null;
    }
}