<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
<span *ngIf="sessionService.counter == 3000">{{sessionWarning(sessionRenew)}}</span> <!-- Warning at 50 minutes-->
<span *ngIf="sessionService.counter == 3540">{{sessionExpired()}}</span> <!-- Expires at about 59 minutes-->

<ng-template #sessionRenew>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Session Renew</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" *ngIf="!isRenewingSession">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <span *ngIf="isRenewingSession">
      Renewing this session...<i class="pi pi-spin pi-spinner" style="font-size: 3em"></i>
    </span>
    <p-messages [(value)]="renewSessionMessage" [closable]="false"></p-messages>
  </div>
  <div class="modal-footer" *ngIf="!isRenewingSession">
    <button type="button" class="btn btn-secondary" (click)="closeModal(sessionRenew)">Close</button>
  </div>
</ng-template>