import { IEncodedRequestBody } from "../interfaces/iencoded-request-body";

export class EncodedRequestBody implements IEncodedRequestBody {
    data: string;

    constructor(objectToWrap: any) {
        var jsonString = JSON.stringify(objectToWrap);
        var encodedString = btoa(jsonString);

        this.data = encodedString;
    }
}