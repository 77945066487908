import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesStaticInfo } from './_helpers/routes-static-info';
import { LossType } from './claim-data/enum-losstype';

@Injectable({
  providedIn: 'root'
})
export class ClaimRouterService {

  routesUrl = RoutesStaticInfo;
  lossType = LossType;
  pageBeforeError: string;

  /** Upon valid contact form, routes to /claimform-work */
  contactSubmit(isFormValid: boolean){
    if (isFormValid) {
      this.router.navigate([this.routesUrl.claimWork])
    }
  }

  /** Upon valid work form, routes to the passed route parameter. */
  workSubmit(isFormValid: boolean, route){
    if (isFormValid) {
      this.router.navigate([route]);
    }
  }

  /** Upon valid medical form, routes to /claimform-medical */
  medicalSubmit(isFormValid: boolean){
    if(isFormValid){
      this.router.navigate([this.routesUrl.summary]);
    }
  }

  /** Direct route to /claimform-contract */
  routeToContact(){
    this.router.navigate([this.routesUrl.claimContact]);
  }

  /** Direct route to /claimform-work */
  routeToWork(){
    this.router.navigate([this.routesUrl.claimWork]);
  }

  /** Direct route to /claimform-medical */
  routeToMedical(){
    this.router.navigate([this.routesUrl.claimMedical]);
  }

  /** Direct route to /claim-submit-success */
  routeToClaimSubmitSuccess(){
    this.router.navigate([this.routesUrl.claimSubmitSuccess]);
  }

  /** Direct route to /claim */
  routeToClaimPage(){
    this.router.navigate([this.routesUrl.claim]);
  }

  /** Direct route to /summary */
  routeToSummary(){
    this.router.navigate([this.routesUrl.summary]);
  }

  /** Direct route to /claimform-loss-type */
  routeToLossType(){
    this.router.navigate([this.routesUrl.claimLossType]);
  }

  /** Direct route to /claimform-supplemental */
  routeToSupplemental(){
    this.router.navigate([this.routesUrl.claimSupp]);
  }

  /** Direct route to generic error page */
  routeToError(){
    this.pageBeforeError = window.location.href;
    this.router.navigate([this.routesUrl.error]);
  }

  /** Direct route to GDPR consent page */
  routeToGDPRConsent(){
    this.router.navigate([this.routesUrl.GDPRConsent])
  }

  /** Routes to link/claim */
  routeToLinkClaimFindYourClaim(){
    this.router.navigate([this.routesUrl.linkClaim])
  }

  /** Routes to home */
  routeToHome(){
    this.router.navigate([this.routesUrl.home])
  }

  constructor(
    private router: Router
  ) { }
}
