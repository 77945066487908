<div class="container">
  <div class="row">
    <div class="col-md-12">
      <h4>To view or update your claim, please fill out the following information. If this is a life claim, please enter in the information of the deceased individual.</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8">
    </div>
    <div class="col-md-4">
      <p class="form-error-text">* denotes required fields.</p>
    </div>
  </div>
  <form (ngSubmit)="findClaimSubmit(findClaim)" #findClaim="ngForm" autocomplete="off" class="margin-bottom">

    <div class="form-group row">
      <label for="claimNumber" class="col-sm-5 col-form-label">
        {{lbl.claimNumber}} <span class="form-error-text">*</span>
      </label>
      <div class="col-sm-7">
        <p-inputMask  styleClass="input-styles" type="tel" id="claimNumber" [(ngModel)]="authService.auth.number" name="claimNumber"
        mask="9999999999" #claimNumber="ngModel" styleClass="input-styles" required></p-inputMask>
        <div *ngIf="(claimNumber.invalid && !claimNumber.pristine) || (findClaim.submitted && claimNumber.invalid)" class="alert alert-danger">
          {{errMsg.claimNumberRequired}}
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="birthDate" class="col-sm-5 col-form-label">
        {{lbl.birthDate}} <span class="form-error-text">*</span>
      </label>
      <div class="col-sm-7">
        <p-calendar id="birthDate" required [monthNavigator]="true" [yearNavigator]="true" [showOnFocus]="false" [touchUI]="claimformService.touchUI"
          yearRange="{{lbl.todayPlusMinus90Yrs}}" placeholder="MM/DD/YYYY" [(ngModel)]="authService.auth.dateOfBirth" rPCalendarMask
          class="ui-fluid" name="birthDate" #birthDate="ngModel" dataType="string" [showIcon]="true" styleClass="input-styles" dateMask>
        </p-calendar>
        <div *ngIf="(birthDate.invalid && !birthDate.pristine) || (findClaim.submitted && birthDate.invalid)" class="alert alert-danger">
          {{errMsg.dateRequired}}
        </div>
      </div>
    </div>

    <div *ngIf="findClaim.submitted && !findClaim.form.valid" class="form-error-text" class="alert alert-danger">
      {{errMsg.formRequired}}
    </div>
    <div class="row">
      <div class="col-md-12">
        <div *ngIf="claimService.apiError" class="form-error-text" class="alert alert-danger">
          <span *ngIf="claimService.apiErrorType == 400 || claimService.apiErrorType == 401; else error500">
            {{errMsg.badRequest(phoneService.phoneNumber)}}
            <br><br>
            Information entered:
            <br>
            {{lbl.claimNumber}}: {{incorrectKBA.number}}<br>
            {{lbl.birthDate}}: {{incorrectKBA.dateOfBirth}}<br>
          </span>
          <ng-template #error500>
            {{errMsg.internalServer(phoneService.phoneNumber)}}
          </ng-template>
        </div>
        <span *ngIf="isLoadingAuth">
          Authenticating...<i class="pi pi-spin pi-spinner" style="font-size: 3em"></i>
        </span>
        <span *ngIf="claimService.isLoadingClaimData">
          Loading claim data...<i class="pi pi-spin pi-spinner" style="font-size: 3em"></i>
        </span>
        <button type="submit" class="btn btn-primary float-right" id="claimSubmit" 
        [disabled]="(findClaim.submitted && !findClaim.form.valid) || (isLoadingAuth || claimService.isLoadingClaimData)">Continue</button>
      </div>
    </div>
  </form>

  <p-accordion [multiple]="true" styleClass="question-header">
    <p-accordionTab>
      <p-header class="question-header">
        Need help finding your claim number?
      </p-header>
      Your 10-digit claim number can be found within the text, email or mail notification sent to you. If you cannot locate your claim number, please call us at {{phoneService.phoneNumber}}.
    </p-accordionTab>
    <p-accordionTab>
      <p-header class="question-header">
        Why do we need this information?
      </p-header>
      This information is necessary to authenticate your identity and administer your claim. We are committed to
      protecting your personal privacy as you visit this website. Please refer to our
      <a href={{privacypolicy}} target="_blank">privacy policy </a>
      for additional information.
    </p-accordionTab>
  </p-accordion>

</div>