import { Component, OnInit, TemplateRef } from '@angular/core';
import { ClaimService } from '../claim.service';
import cssVars from 'css-vars-ponyfill';
import { Message, SharedModule } from 'primeng/api';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfigurationService } from 'src/app/configuration.service';
import { StaticInfo } from '../_helpers/static-info';
import { MessagesModule } from 'primeng/messages';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-form-document',
    templateUrl: './form-document.component.html',
    styleUrls: ['./form-document.component.css'],
    standalone: true,
    imports: [NgIf, TableModule, SharedModule, ButtonModule, MessagesModule]
})
export class FormDocumentComponent implements OnInit {

  reader = new FileReader();
  fileName:string;
  fileData:string;
  duplicateFile:boolean;
  duplicateErrorMsg:string;
  documentsErrorMsg: Message[] = [];
  acceptedExtensions = '.pdf, .jpg, .jpeg, .tif, .tiff';
  modalRef: BsModalRef;
  staticInfo: StaticInfo = new StaticInfo();
  AllowTrustage:boolean;
  brandname: string;

  /** Displays a modal on the page. */
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md' });
  }

  /** When user selects a valid document, this sends the document data to ClaimService. */
  uploadDocument(event) {
    this.documentsErrorMsg = [];
    this.fileName = event.target.files[0].name;
    let fileExtension = this.fileName.split('.')[1];
    if (this.acceptedExtensions.includes(fileExtension)) {
      this.reader.readAsDataURL(event.target.files[0]);
      this.reader.onload = () => {
        this.fileData = (this.reader.result as string).match(/.+;base64,(.+)/)[1]
        if (this.duplicateFile = this.claimService.addDocument(this.fileName, this.fileData)) {
          this.documentsErrorMsg.push({ severity: 'error', summary: null, detail: 'Document "' + this.fileName + '" is already uploaded to this claim.' });
        }
      }
    } else {
      this.documentsErrorMsg.push({ severity: 'error', summary: null, detail: this.fileName + ': Invalid file type, allowed file types: ' + this.acceptedExtensions });
    }
  }

  /** When user clicks the X button by the duplicate error message, this removes the duplicate error message. */
  removeDuplicateError(){
    this.duplicateFile = false;
  }

  constructor(
    public claimService: ClaimService,
    private modalService: BsModalService,
    private configService: ConfigurationService) { }

  ngOnInit() {
    if (this.configService.configurationData.featureToggles) {
      this.AllowTrustage = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.allowTrustageBranding);
        (this.AllowTrustage && typeof this.AllowTrustage != 'undefined')?
        this.brandname= this.staticInfo.brandname.trustage:
        this.brandname=this.staticInfo.brandname.cmg 
    } else {
    this.configService.loadFeatureToggleData().toPromise()
    .then(p_configService => {
      this.AllowTrustage = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.allowTrustageBranding);
        (this.AllowTrustage && typeof this.AllowTrustage != 'undefined')?
        this.brandname= this.staticInfo.brandname.trustage:
        this.brandname=this.staticInfo.brandname.cmg
    })
    
    .catch(err => console.error(err));
    cssVars();
  }
}

}
