<span class="claim-form">
  <div>
    <span>
      <br/><br/>
      <h4>What were the dates you were hospitalized?</h4>
    </span>
  </div>
  <div class="form-group row" *ngIf="claimService.showIncurredDate">
    <label for="incurredDate" class="col-sm-5 col-form-label">
      {{claimService.incurredDateText}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <p-calendar id="incurredDate" required [monthNavigator]="true" [yearNavigator]="true" [showOnFocus]="false" [touchUI]="claimformService.touchUI"
        yearRange="{{claimformService.lbl.todayPlusMinus90Yrs}}" dataType="string" rPCalendarMask
        placeholder="MM/DD/YYYY" [(ngModel)]="claimService.claimRoot.claim.incurredDate" class="ui-fluid"
        name="incurredDate" #incurredDate="ngModel" [showIcon]="true" styleClass="input-styles" dateMask></p-calendar>
      <div class="alert alert-danger" *ngIf="(incurredDate.invalid && !incurredDate.pristine) || (claimForm.submitted && incurredDate.invalid)">
        <span *ngIf="incurredDate.errors.required">{{claimformService.errMsg.dateRequired}}</span>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="hospitalEndDate" class="col-sm-5 col-form-label">
      {{claimformService.lbl.hospitalEndDate}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <p-calendar id="hospitalEndDate" required [monthNavigator]="true" [yearNavigator]="true" [showOnFocus]="false"
        [touchUI]="claimformService.touchUI" yearRange="{{claimformService.lbl.todayPlusMinus90Yrs}}" dataType="string"
        rPCalendarMask [(ngModel)]="claimService.claimRoot.claim.incident.hospitalizationEndDate" class="ui-fluid"
        name="hospitalEndDate" placeholder="MM/DD/YYYY" #hospitalEndDate="ngModel" [showIcon]="true" styleClass="input-styles" dateMask></p-calendar>
        <div *ngIf="(hospitalEndDate.invalid && !hospitalEndDate.pristine) || (claimForm.submitted && hospitalEndDate.invalid)" class="alert alert-danger">
          <span>{{claimformService.errMsg.dateRequired}}</span>
        </div>
    </div>    
  </div>

  <div class="form-group row">
    <label for="hospitalName" class="col-sm-5 col-form-label">
      {{claimformService.lbl.hospitalName}} 
    </label>
    <div class="col-sm-7">
      <input type="text" pInputText class="form-control input-styles" id="hospitalName" 
        [(ngModel)]="claimService.acpValues.hospitalName" name="hospitalName" maxlength=100 #hospitalName="ngModel">
    </div>
  </div>

  <div class="form-group row">
    <label for="city" class="col-sm-5 col-form-label">
      {{claimformService.lbl.hospitalCity}} 
    </label>
    <div class="col-sm-7">
      <input type="text" pInputText class="form-control input-styles" id="hospitalCity" 
        [(ngModel)]="claimService.acpValues.hospitalCity" name="hospitalCity" maxlength="25" #city="ngModel">
    </div>
  </div>
  <div class="form-group row" *ngIf="!internationalAddressService.isForeignCountry">
    <label for="state" class="col-sm-5 col-form-label">
      {{claimformService.lbl.hospitalState}}
    </label>
    <div class="col-sm-7">
      <div>
        <ng-select class="fw-normal fs-6" labelForId="hospitalState" name="hospitalState" #state="ngModel" bindLabel="label" bindValue="value"
          [(ngModel)]="claimService.acpValues.hospitalState" [selectOnTab]="true"
          [items]="claimformService.states" placeholder="{{claimformService.lbl.hospitalState}}"
          [searchFn]="claimService.customSearchState" (search)="claimService.onStateSearch($event, state)">
        </ng-select>
      </div>
    </div>
  </div>
  
</span>
