<span class="claim-form">
    <fieldset class="form-group">
        <div class="row">
            <div class="col-sm-5">
                <legend>
                    {{claimformService.lbl.returnedToWork}} <span class="form-error-text">*</span>
                </legend>
            </div>
            <div class="col-sm-7">
                <div class="row" id="returnedToWork">
                    <div class="col-12 radio-button">
                        <p-radioButton name="returnedToWork" [value]="true" required
                            label="{{claimformService.yesNo.yes}}"
                            [(ngModel)]="claimService.claimRoot.claim.employment.hasReturnedToWork"
                            inputId="returnedToWork1" id="returnedToWorkYes" #returnedToWork="ngModel"
                            (click)="claimformService.clearField(claimformService.clearFormField.hasReturnedToWorkYes)">
                        </p-radioButton>
                    </div>
                    <div class="col-12 radio-button">
                        <p-radioButton name="returnedToWork" [value]="false" required
                            label="{{claimformService.yesNo.no}}"
                            [(ngModel)]="claimService.claimRoot.claim.employment.hasReturnedToWork"
                            inputId="returnedToWork2" id="returnedToWorkNo" #returnedToWork="ngModel"
                            (click)="claimformService.clearField(claimformService.clearFormField.hasReturnedToWork)">
                        </p-radioButton>
                    </div>
                </div>
                <div *ngIf="(returnedToWork.invalid && !returnedToWork.pristine) || (claimForm.submitted && returnedToWork.invalid)"
                    class="alert alert-danger">
                    {{claimformService.errMsg.generic}}
                </div>
            </div>
        </div>
    </fieldset>
    <div *ngIf="claimService.claimRoot.claim.employment.hasReturnedToWork" class="form-group row">
        <label for="returnedToWorkDate" class="col-sm-5 col-form-label">
            {{claimformService.lbl.returnedToWorkDate}} <span class="form-error-text">*</span>
        </label>
        <div class="col-sm-7">
            <p-calendar id="returnedToWorkDate" required [monthNavigator]="true" [yearNavigator]="true"
                [showOnFocus]="false" [touchUI]="claimformService.touchUI" rPCalendarMask
                yearRange="{{claimformService.lbl.todayPlusMinus90Yrs}}" dataType="string"
                [(ngModel)]="claimService.claimRoot.claim.employment.returnDate" class="ui-fluid"
                name="returnedToWorkDate" placeholder="MM/DD/YYYY" #returnedToWorkDate="ngModel" [showIcon]="true" styleClass="input-styles" dateMask>
            </p-calendar>
            <div *ngIf="(returnedToWorkDate.invalid && !returnedToWorkDate.pristine) || (claimForm.submitted && returnedToWorkDate.invalid)"
                class="alert alert-danger">
                {{claimformService.errMsg.dateRequired}}
            </div>
        </div>
    </div>
    <div *ngIf="claimService.claimRoot.claim.employment.hasReturnedToWork" class="form-group row">
        <label for="hasSameHours" class="col-sm-5 col-form-label">
            {{claimformService.lbl.hasSameHoursSupplemental}} <span class="form-error-text">*</span>
        </label>
        <div class="col-sm-7">
            <div>
            <ng-select class="fw-normal fs-6" labelForId="hasSameHours" name="hasSameHours" #hasSameHours="ngModel" bindLabel="value" bindValue="value"
                [(ngModel)]="claimService.claimRoot.claim.employment.hasSameHours" [selectOnTab]="true" [searchable]="false" [clearable]="false"
                (ngModelChange)="claimformService.setSameHours(claimService.claimRoot.claim.employment.hasSameHours)"
                [items]="claimformService.yesNoUnsure" placeholder="{{claimformService.lbl.hasSameHoursSupplemental}}" required>
            </ng-select>
            </div>
            <div *ngIf="(hasSameHours.invalid && !hasSameHours.pristine) || (claimForm.submitted && hasSameHours.invalid) || hasSameHours == claimformService.yesNoUnsure.null"
                class="alert alert-danger">
                {{claimformService.errMsg.genericSelect}}
            </div>
        </div>
    </div>
    <div class="form-group row" *ngIf="claimformService.sameHours == true">
        <label for="normalJobDutiesAndHours" class="col-sm-5 col-form-label">
            {{claimformService.lbl.normalJobDutiesAndHours}} <span class="form-error-text">*</span>
        </label>
        <div class="col-sm-7">
            <textarea class="input-styles" rows="5" cols="30" id="normalJobDutiesAndHours" required
                [(ngModel)]="claimService.claimRoot.claim.employment.originalJobDuties" name="normalJobDutiesAndHours"
                maxlength="1500" pInputTextarea #normalJobDutiesAndHours="ngModel"></textarea>
            <div *ngIf="(normalJobDutiesAndHours.invalid && !normalJobDutiesAndHours.pristine) || (claimForm.submitted && normalJobDutiesAndHours.invalid)"
                class="alert alert-danger">
                {{claimformService.errMsg.generic}}
            </div>
        </div>
    </div>
    <div class="form-group row" *ngIf="claimformService.sameHours == true">
        <label for="currentJobDutiesAndHours" class="col-sm-5 col-form-label">
            {{claimformService.lbl.currentJobDutiesAndHours}} <span class="form-error-text">*</span>
        </label>
        <div class="col-sm-7">
            <textarea class="input-styles" rows="5" cols="30" id="currentJobDutiesAndHours" required
                [(ngModel)]="claimService.claimRoot.claim.employment.currentJobDuties" name="currentJobDutiesAndHours"
                maxlength="1500" pInputTextarea #currentJobDutiesAndHours="ngModel"></textarea>
            <div *ngIf="(currentJobDutiesAndHours.invalid && !currentJobDutiesAndHours.pristine) || (claimForm.submitted && currentJobDutiesAndHours.invalid)"
                class="alert alert-danger">
                {{claimformService.errMsg.generic}}
            </div>
        </div>
    </div>
    <div class="form-group row" *ngIf="claimformService.sameHours == true">
        <label for="specificRestrictions" class="col-sm-5 col-form-label">
            {{claimformService.lbl.specificRestrictions}} <span class="form-error-text">*</span>
        </label>
        <div class="col-sm-7">
            <textarea class="input-styles" rows="5" cols="30" id="specificRestrictions" required
                [(ngModel)]="claimService.claimRoot.claim.employment.restrictionsDescription"
                name="specificRestrictions" maxlength="1500" pInputTextarea #specificRestrictions="ngModel"></textarea>
            <div *ngIf="(specificRestrictions.invalid && !specificRestrictions.pristine) || (claimForm.submitted && specificRestrictions.invalid)"
                class="alert alert-danger">
                {{claimformService.errMsg.generic}}
            </div>
        </div>
    </div>
    <fieldset class="form-group" *ngIf="claimformService.sameHours == true">
        <div class="row">
            <div class="col-sm-5">
                <legend>
                    {{claimformService.lbl.returnToWorkSameWage}} <span class="form-error-text">*</span>
                </legend>
            </div>
            <div class="col-sm-7">
                <div class="row" id="returnToWorkSameWage">
                    <div class="col-12 radio-button">
                        <p-radioButton name="returnToWorkSameWage" [value]="claimformService.yesNo.yes" required
                            label="{{claimformService.yesNo.yes}}"
                            [(ngModel)]="claimService.claimRoot.claim.employment.hasSameWages"
                            inputId="returnToWorkSameWage1" id="returnToWorkSameWageYes" #returnToWorkSameWage="ngModel"
                            (click)="claimformService.clearField(claimformService.clearFormField.hasSameWagesYes)">
                        </p-radioButton>
                    </div>
                    <div class="col-12 radio-button">
                        <p-radioButton name="returnToWorkSameWage" [value]="claimformService.yesNo.no" required
                            label="{{claimformService.yesNo.no}}"
                            [(ngModel)]="claimService.claimRoot.claim.employment.hasSameWages"
                            inputId="returnToWorkSameWage2" id="returnToWorkSameWageNo" #returnToWorkSameWage="ngModel"
                            (click)="claimformService.clearField(claimformService.clearFormField.hasSameWages)">
                        </p-radioButton>
                    </div>
                </div>
                <div *ngIf="(returnToWorkSameWage.invalid && !returnToWorkSameWage.pristine) || (claimForm.submitted && returnToWorkSameWage.invalid)"
                    class="alert alert-danger">
                    {{claimformService.errMsg.generic}}
                </div>
            </div>
        </div>
    </fieldset>
    <div class="form-group row"
        *ngIf="claimService.claimRoot.claim.employment.hasSameWages === claimformService.yesNo.no">
        <label for="sameWageExplain" class="col-sm-5 col-form-label">
            {{claimformService.lbl.pleaseExplain}} <span class="form-error-text">*</span>
        </label>
        <div class="col-sm-7">
            <textarea class="input-styles" rows="5" cols="30" id="sameWageExplain" required
                [(ngModel)]="claimService.claimRoot.claim.employment.wageChangeDescription" name="sameWageExplain"
                maxlength="1500" pInputTextarea #sameWageExplain="ngModel"></textarea>
            <div *ngIf="(sameWageExplain.invalid && !sameWageExplain.pristine) || (claimForm.submitted && sameWageExplain.invalid)"
                class="alert alert-danger">
                {{claimformService.errMsg.generic}}
            </div>
        </div>
    </div>
    <div class="form-group row" *ngIf="claimService.claimRoot.claim.employment.hasReturnedToWork == false">
        <label for="providerName" class="col-sm-5 col-form-label">
            {{claimformService.lbl.currentTreatingPhysician}}
        </label>
        <div class="col-sm-7">
            <input type="text" pInputText class="form-control input-styles" id="providerName"
                [(ngModel)]="claimService.claimRoot.claim.incident.physician.name" name="providerName"
                #providerName="ngModel" maxlength="50">
        </div>
    </div>
    <div class="form-group row" *ngIf="claimService.claimRoot.claim.employment.hasReturnedToWork == false">
        <label for="providerPhone" class="col-sm-5 col-form-label">
            {{claimformService.lbl.currentTreatingPhysicianPhone}}
        </label>
        <div class="col-sm-7">
            <p-inputMask styleClass="input-styles" type="tel" id="providerPhone"
                [(ngModel)]="claimService.claimRoot.claim.incident.physician.phoneNumber" name="providerPhone"
                #providerPhone="ngModel" mask="999-999-9999" styleClass="input-styles"></p-inputMask>
        </div>
    </div>
    <app-form-providers *ngIf="claimService.claimRoot.claim.employment.hasReturnedToWork == false"></app-form-providers>
    <div *ngIf="(claimService.claimRoot.claim.employment.hasReturnedToWork == false) || (claimformService.sameHours == true)"
        class="form-group row">
        <label for="anticipatedRecoveryTime" class="col-sm-5 col-form-label">
            {{claimformService.lbl.anticipatedRecoveryTime}}
        </label>
        <div class="col-sm-7">
            <p-calendar id="anticipatedRecoveryTime" [monthNavigator]="true" [yearNavigator]="true"
                [showOnFocus]="false" [touchUI]="claimformService.touchUI" rPCalendarMask
                yearRange="{{claimformService.lbl.todayPlusMinus90Yrs}}" dataType="string"
                [(ngModel)]="claimService.claimRoot.claim.employment.estimatedRecoverDate" class="ui-fluid"
                name="anticipatedRecoveryTime" placeholder="MM/DD/YYYY" #anticipatedRecoveryTime="ngModel"
                [showIcon]="true" styleClass="input-styles"></p-calendar>
        </div>
    </div>
    <div *ngIf="(claimService.claimRoot.claim.employment.hasReturnedToWork == false) || (claimformService.sameHours == true)"
        class="form-group row">
        <label for="nextDateWithPhysician" class="col-sm-5 col-form-label">
            {{claimformService.lbl.nextDateWithPhysician}}
        </label>
        <div class="col-sm-7">
            <p-calendar id="nextDateWithPhysician" [monthNavigator]="true" [yearNavigator]="true" [showOnFocus]="false"
                [touchUI]="claimformService.touchUI" rPCalendarMask
                yearRange="{{claimformService.lbl.todayPlusMinus90Yrs}}" dataType="string"
                [(ngModel)]="claimService.claimRoot.claim.employment.nextAppointmentDate" class="ui-fluid"
                name="nextDateWithPhysician" placeholder="MM/DD/YYYY" #nextDateWithPhysician="ngModel"
                [showIcon]="true" styleClass="input-styles"></p-calendar>
        </div>
    </div>
</span>