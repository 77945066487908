import { Component, OnInit, Input } from '@angular/core';
import { ClaimService } from '../claim.service';
import { NgForm, ControlContainer, FormsModule } from '@angular/forms';
import { ClaimformService } from '../claimform.service';
import cssVars from 'css-vars-ponyfill';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { InputTextModule } from 'primeng/inputtext';
import { DateMaskDirective } from '../date-mask.directive';
import { CalendarModule } from 'primeng/calendar';
import { NgIf, NgFor } from '@angular/common';
import { RadioButtonModule } from 'primeng/radiobutton';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
    selector: 'app-form-supp-unemployment',
    templateUrl: './form-supp-unemployment.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    styleUrls: ['./form-supp-unemployment.component.css'],
    standalone: true,
    imports: [RadioButtonModule, FormsModule, NgIf, CalendarModule, DateMaskDirective, InputTextModule, KeyFilterModule, InputTextareaModule, NgFor, NgSelectModule]
})
export class FormSuppUnemploymentComponent implements OnInit {

  @Input() claimForm: NgForm

  constructor(
    public claimformService: ClaimformService,
    public claimService: ClaimService
  ) { }

  ngOnInit() {
    cssVars();
  }

}
