import { Component, OnInit, Input } from '@angular/core';
import { ClaimService } from '../claim.service';
import { NgForm, ControlContainer, FormsModule } from '@angular/forms';
import { ClaimformService } from '../claimform.service';
import cssVars from 'css-vars-ponyfill';
import { ConfigurationService } from '../configuration.service';
import { FormDescriptionComponent } from '../form-description/form-description.component';
import { DateMaskDirective } from '../date-mask.directive';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { KeyFilterModule } from 'primeng/keyfilter';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { NgFor, NgIf } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
    selector: 'app-form-work-unemployment',
    templateUrl: './form-work-unemployment.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    styleUrls: ['./form-work-unemployment.component.css'],
    standalone: true,
    imports: [FormsModule, NgFor, NgIf, InputTextModule, InputMaskModule, KeyFilterModule, RadioButtonModule, CalendarModule, DateMaskDirective, FormDescriptionComponent, NgSelectModule]
})
export class FormWorkUnemploymentComponent implements OnInit {

  showCovid19Information: boolean = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.showCovid19Information);

  @Input() claimForm: NgForm

  clearFieldsIU(question:string){
    switch (question){
      case this.claimformService.lblIU.selfEmployed:
        if(this.claimService.claimRoot.claim.employment.selfEmployed === this.claimformService.staticInfo.yes){
          this.claimService.claimRoot.claim.employment.employer.name = null;
          this.claimService.claimRoot.claim.employment.employer.phoneNumber = null;
        }
        break;
      case this.claimformService.lblIU.employedOneYearOrMore:
        if(this.claimService.acpValues.employedLongerThanOneYear === this.claimformService.staticInfo.yes){
          this.claimService.acpValues.isUnionMember = null;
          this.claimService.acpValues.unionName = null;
          this.claimService.acpValues.isTemporaryEmployee = null;
          this.claimService.acpValues.isContractBasis = null;
          this.claimService.acpValues.knewLayoffWasPossible = null;
        }else{
          this.claimService.acpValues.hasSeasonalEmployment = null;
        }
        break;
      case this.claimformService.lblIU.reasonForUnemployment:
        if(this.claimService.acpValues.reasonForUnemployment === this.claimformService.enumUnemploymentReason.Other){
          //do nothing
        }else if(this.claimService.acpValues.reasonForUnemployment === this.claimformService.enumUnemploymentReason.MissedWork ||
          this.claimService.acpValues.reasonForUnemployment === this.claimformService.enumUnemploymentReason.ResignedOrRelocated ||
          this.claimService.acpValues.reasonForUnemployment === this.claimformService.enumUnemploymentReason.RuleOrPolicyViolation ||
          this.claimService.acpValues.reasonForUnemployment === this.claimformService.enumUnemploymentReason.Misconduct){
          this.claimService.acpValues.differentReasonForUnemployment = null;
        }else{
          this.claimService.acpValues.differentReasonForUnemployment = null;
          this.claimService.claimRoot.claim.employment.receivingAid = null;
        }
        break;
      case this.claimformService.lblIU.isUnemploymentFromCOVID19:
        if(this.claimService.acpValues.isUnemploymentFromCOVID19 === this.claimformService.staticInfo.no){
          this.claimService.acpValues.isReceivingWagesDuringCOVID19Unemployment = null;
        }
        break;
      case this.claimformService.lblIU.isUnionMember:
        if(this.claimService.acpValues.isUnionMember == this.claimformService.staticInfo.no){
          this.claimService.acpValues.unionName = null;
        }else{
          this.claimService.acpValues.isTemporaryEmployee = null;
          this.claimService.acpValues.isContractBasis = null;
        }
        break;
      case this.claimformService.lblIU.hasReturnedToWork:
        if(this.claimService.claimRoot.claim.employment.hasReturnedToWork == true){
          this.claimService.acpValues.plansToWorkWithSameEmployer = null;
          this.claimService.acpValues.isSeekingEmployment = null;
        }else{
          this.claimService.claimRoot.claim.employment.returnDate = null;
          this.claimService.acpValues.currentHoursPerWeek = null;
          this.claimService.acpValues.isSeekingEmployment = null;
        }
        break;
    }
  }

  _showSeekingEmploymentQuestion:boolean = false
  
  weeksCounterStrings(i: number){
    let strArr = new Array(i);
    for(let j =1; j< strArr.length; j++){
      strArr[j] = j.toString();
    }
    return strArr;
  }

  hoursWorkingLessThan25():boolean{
    if(this.claimService.acpValues.currentHoursPerWeek == null){
      return false;
    }
    return Number(this.claimService.acpValues.currentHoursPerWeek) < 25
  }

  showSeekingEmploymentQuestion(){
    this._showSeekingEmploymentQuestion = 
      (this.claimService.claimRoot.claim.employment.hasReturnedToWork === true && this.hoursWorkingLessThan25()) || 
      (this.claimService.claimRoot.claim.employment.hasReturnedToWork === false && (this.claimService.acpValues.plansToWorkWithSameEmployer === this.claimformService.staticInfo.no || this.claimService.acpValues.plansToWorkWithSameEmployer === this.claimformService.staticInfo.unsure))
    
    if(this._showSeekingEmploymentQuestion === false){
      this.claimService.acpValues.isSeekingEmployment = null
    }

    return this._showSeekingEmploymentQuestion
    }

  constructor(
    public claimService: ClaimService,
    public claimformService: ClaimformService,
    public configService: ConfigurationService
  ) { }

  ngOnInit() {
    cssVars();
  }

}
