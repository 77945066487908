import { Injectable } from '@angular/core';

import { AuthenticationService } from 'src/app/authentication.service';
import { ClaimRouterService } from 'src/app/claim-router.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private authService: AuthenticationService,
    private claimRouterService: ClaimRouterService
  ) { }

  canActivate():boolean {
    if (this.authService.token == null || ''){
      this.claimRouterService.routeToError();
      return false;
    } else {
      return true;
    }
  }

}

