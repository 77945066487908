<div class="modal-header">
    <h4 class="modal-title pull-left">E-Delivery Consent</h4>
    <div *ngIf="claimformService.showEmailConsentButtons === false">
      <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
</div>
<div class="modal-body">
    Please take time to read the information below. If you have any questions or need any assistance, please call us at 1-844-379-3949. <br><br>

    IMPORTANT INFORMATION <br>
    By agreeing to this Esign Consent and Agreement to Electronic Records, you consent to receiving certain information electronically that we would otherwise be required by law to give you “in writing,” conduct transactions in electronic form, and using electronic signatures in our relationship with you. You also acknowledge that: <br>
    • You can access, read, and print this Esign Consent and Agreement to Electronic Records and Signature. <br>
    • Your electronic signature is the legal equivalent of your written signature, just as if you had signed a paper document. <br><br>

    You are not obligated to enter into transactions electronically and may conduct insurance transactions in paper format instead. If you choose to conduct transactions in paper form, withdraw your consent to electronic transactions, or would like to update your contact information, you must contact CMFG Life. You can call us toll-free at 1-844-379-3949 or write to CMFG Life Insurance Company, Claims PO Box 667 
    Waverly, IA 50677-0667. There is no fee charged for such requests. <br><br>

    You are consenting to use electronic communications, electronic records, electronic documents, electronic postings, and electronic signatures rather than paper documents for all your communications and transactions with CMFG Life Insurance Company, including CMFG Life and its direct and indirect parent, subsidiaries, and affiliates, including TruStage Insurance Agency, LLC and CUMIS Insurance Society, Inc. (collectively, the “Company”), relating to any Company product or service, for the following (including, but not limited to): <br>
    • Agreements and contracts, including this Consent and Agreement. <br>
    • Certificates of Insurance for Group Mortgage Protection Insurance. <br>
    • Notices and/or disclosures that various federal and/or state laws or regulations require that we provide to you. <br>
    • Notices, documents, statements, data, records or communications regarding your coverage, including changes in terms of coverage. <br>
    • Claims related documents and communications. <br>
    • Privacy policies and notices. <br>
    • Periodic billing or account statements. <br><br>

    The following Privacy Notices are only applicable for Credit Insurance and for the following states:<br>
    • HI, MT, NY, OR, UT: <a href="https://www.trustage.com/-/media/pdfs/trustage/privacy-page/privacy-notice-all-other-states-2023.pdf?la=en&hash=9E267A30690E2E3D305354E5809BD7FBE000BFEA" target="_blank" aria-label="Privacy Notice for HI, MT, NY, OR, UT">1861-1</a><br>
    •	ME: <a href="https://www.trustage.com/-/media/pdfs/trustage/privacy-page/privacy-notice-az-cn-ga-ma-mn-nj-nv-oh-2023.pdf?la=en&hash=D60AAA4D8C6734881E867D1B36B962B8A2E1D83B" target="_blank" aria-label="Privacy Notice for ME">1861-2</a><br><br>
    
    In order to access the documents we send you electronically, a minimum standard of technology is required, the fully functional hardware, software and services necessary include a computer with a Microsoft Windows or Macintosh operating system, Internet access, a working email address and email software, compatible web-browsing software such as Internet Explorer and Internet access services, a current version of a program that accurately reads and displays PDF files (such as Adobe Acrobat Reader), and a printer if you wish to print out and retain records on paper, and electronic storage if you wish to retain records in electronic form. It is your responsibility to provide us with an accurate email address as well as other contact information, and to inform us of any changes in this information. You understand you will be deemed to have received the foregoing documents even if you fail to provide the necessary technology.
</div>
<div class="modal-footer">
  <div *ngIf="claimformService.showEmailConsentButtons">
    <button
      role="button"
      name="acceptEmailButton"
      id="acceptEmailButton"
      class="btn btn-primary"
      (click)="acceptConsentAndCloseModal()">I Accept</button>
    <button
      role="button"
      name="declineEmailButton"
      id="declineEmailButton"
      class="btn btn-secondary"
      (click)="declineConsentAndCloseModal()">I Decline</button>
  </div>
</div>