import { Injectable } from '@angular/core';
import { ClaimRoot } from './claim-data/claim-root';
import { Claim } from './claim-data/claim';
import { Contact } from './claim-data/contact';
import { Employment } from './claim-data/employment';
import { Incident } from './claim-data/incident';
import { Insured } from './claim-data/insured';
import { Address } from './claim-data/address';
import { CmfgUtilitiesService } from './cmfg-utilities.service';
import { StaticInfo } from './_helpers/static-info';
import { InternationalAddressService } from './international-address.service';
import { LossType } from './claim-data/enum-losstype'
import { CreditUnion } from './configuration-data/credit-union';
import { FeatureToggle } from './configuration-data/feature-toggle';

@Injectable({
  providedIn: 'root'
})
export class ClaimMapperService {

  cmfgUtilities: CmfgUtilitiesService;
  staticInfo: StaticInfo;

  public mapClaimRoot(claimRoot: ClaimRoot):ClaimRoot{

    let _claimRoot : ClaimRoot = new ClaimRoot();
    _claimRoot.claim = this.mapClaim(claimRoot.claim);
    _claimRoot.cuContractNumber = claimRoot.cuContractNumber;
    _claimRoot.contact = this.mapContact(claimRoot.contact);

    return _claimRoot ;
  }

  mapClaim(claim: Claim):Claim{

    let _claim : Claim = new Claim();
    _claim.employment = this.mapEmployment(claim.employment);
    _claim.incident = this.mapIncident(claim.incident);
    _claim.insured = this.mapInsured(claim.insured);
    _claim.lossType = this.cmfgUtilities.toTitleCase(claim.lossType);
    _claim.otherCU = claim.otherCU;
    _claim.loansOtherCU = claim.loansOtherCU;
    _claim.incurredDate = claim.incurredDate;
    _claim.description = claim.description;
    _claim.hasCreditCard = claim.hasCreditCard;
    _claim.claimNumber = claim.claimNumber;
    _claim.areLoansActive = claim.areLoansActive;
    _claim.additionalClaimProperties = claim.additionalClaimProperties;
    _claim.formType = null;

    return _claim;
  }

  mapEmployment(employment: Employment):Employment{

    let _employment : Employment = new Employment();
    _employment.category = employment.category;
    _employment.description = employment.description;
    _employment.employer = this.mapPerson(employment.employer.name, employment.employer.phoneNumber)
    _employment.estimatedRecoverDate = this.mapToEmptyStringIfNull(employment.estimatedRecoverDate)
    _employment.hasReturnedToWork = employment.hasReturnedToWork != null ? employment.hasReturnedToWork : false;
    _employment.hasSameDuty = employment.hasSameDuty != null ? employment.hasSameDuty : false;
    _employment.hasSameHours = employment.hasSameHours;
    _employment.hoursPerWeek = employment.hoursPerWeek;
    _employment.jobClass = employment.jobClass;
    _employment.lastDateEmploymentCompPaidThru = this.mapToEmptyStringIfNull(employment.lastDateEmploymentCompPaidThru);
    _employment.lastDateWorked = this.mapToEmptyStringIfNull(employment.lastDateWorked);
    _employment.militaryStatus = this.mapMilitaryStatus(employment.militaryStatus);
    _employment.nextAppointmentDate = this.mapToEmptyStringIfNull(employment.nextAppointmentDate);
    _employment.receivingAid = this.mapToEmptyStringIfNull(employment.receivingAid);
    _employment.returnDate = this.mapToEmptyStringIfNull(employment.returnDate);
    _employment.originalJobDuties = employment.originalJobDuties;
    _employment.noAidDescription = employment.noAidDescription;
    _employment.jobContactsPerWeek = employment.jobContactsPerWeek != null ? employment.jobContactsPerWeek : null;
    _employment.currentJobDuties = employment.currentJobDuties;
    _employment.hasSameWages = this.mapToEmptyStringIfNull(employment.hasSameWages);
    _employment.wageChangeDescription = employment.wageChangeDescription;
    _employment.restrictionsDescription = employment.restrictionsDescription;
    _employment.selfEmployed = employment.selfEmployed;
    _employment.characterOfService = employment.characterOfService != null ? employment.characterOfService : null;
    _employment.separationDate = this.mapToEmptyStringIfNull(employment.separationDate);
    _employment.separationReason = employment.separationReason != null ? employment.separationReason : null;
    _employment.hoursWithRestriction = employment.hoursWithRestriction != null ? employment.hoursWithRestriction : 0;


    return _employment; 
  }

  mapPerson(name: string, phoneNumber: string){
    return {
      name: name != '' && name != null ? name : null,
      phoneNumber: phoneNumber != '' && phoneNumber != null ? phoneNumber : null
    };
  }

  mapNullablePerson(person: any){
    var name = (
      person != null &&
      person.name != undefined &&
      person.name != null &&
      person.name != ''
    ) ? person.name : null;

    var phoneNumber = (
      person != null &&
      person.phoneNumber != undefined &&
      person.phoneNumber != null &&
      person.phoneNumber != ''
    ) ? person.phoneNumber : null;

    return this.mapPerson(name, phoneNumber);
  }

  mapIncident(incident: Incident){
    return {
      disabilityType: incident.disabilityType,
      hasWorkmansComp: incident.hasWorkmansComp != null ? incident.hasWorkmansComp : false,
      physician: this.mapPerson(incident.physician.name, incident.physician.phoneNumber),
      physician2: this.mapNullablePerson(incident.physician2),
      physician3: this.mapNullablePerson(incident.physician3),
      physician4: this.mapNullablePerson(incident.physician4),
      physician5: this.mapNullablePerson(incident.physician5),
      medicalCodeCategory: incident.medicalCodeCategory,
      hasBeenHospitalized: incident.hasBeenHospitalized != null ? incident.hasBeenHospitalized : false,
      hospitalizationStartDate: this.mapToEmptyStringIfNull(incident.hospitalizationStartDate),
      hospitalizationEndDate: this.mapToEmptyStringIfNull(incident.hospitalizationEndDate),
      drOrderedNoWorkDate: this.mapToEmptyStringIfNull(incident.drOrderedNoWorkDate),
      incidentDate: this.mapToEmptyStringIfNull(incident.incidentDate),
      initialAppointmentDate: this.mapToEmptyStringIfNull(incident.initialAppointmentDate),
      underProviderCare: incident.underProviderCare
    };
  }

  mapInsured(insured: Insured){
    return {
      firstName: insured.firstName,
      lastName: insured.lastName,
      gender: insured.gender,
      last4SSN: insured.last4SSN,
      dateOfBirth: insured.dateOfBirth
    };
  }

  mapContact(contact: Contact):Contact{

    let _contact : Contact = new Contact();
    _contact.firstName = contact.firstName;
    _contact.lastName = contact.lastName;
    _contact.address = this.mapAddress(contact.address);
    _contact.homePhone = contact.homePhone;
    _contact.statusTextOptIn = contact.statusTextOptIn;
    _contact.prefCorrespondence = contact.prefCorrespondence;
    _contact.suffix = this.mapToEmptyStringIfNull(contact.suffix);
    _contact.cellPhone = contact.cellPhone;
    _contact.email = contact.email;
    _contact.statusEmailOptIn = contact.statusEmailOptIn;


    return _contact;
  }

  mapAddress(address: Address):Address{

    let _address : Address = new Address();
    _address.zip = this.mapToEmptyStringIfNull(address.zip);
    _address.city = this.mapToEmptyStringIfNull(address.city);
    _address.state = this.mapToEmptyStringIfNull(address.state);
    _address.line1 = this.mapToEmptyStringIfNull(address.line1);
    _address.line2 = this.mapToEmptyStringIfNull(address.line2);

    if(this.internationalAddressService.isForeignCountry === true){
      address.state = '';
      address.zip = '';
    }
    return _address; 
  }

  mapMilitaryStatus(status: string){
    return (status != '' && status != null) ? status : 'no value';
  }

  mapToEmptyStringIfNull(valueToMap: any){
    return valueToMap != null ? valueToMap : '';
  }

  /** Adds single quotes. Note*: For some odd reason, a string of 'true' or 'false' seems to still register as a boolean in BPM. */
  addSingleQuotes(value):string{
    return "'" + value + "'";
  }

  /** Maps the feature toggle data from API into an array of FeatureToggle objects */
  mapfeatureTogglesData(value): FeatureToggle[]{
    let mappedFeatureToggle: FeatureToggle[] =[];
    value.FeatureToggles.forEach(element => {
      mappedFeatureToggle.push({
        key: element.key,
        value: element.value
      });
    });
    return mappedFeatureToggle;
  }

  /** Maps the Credit Union data from API into an array of CreditUnion objects */
  mapCreditUnionData(value): CreditUnion[]{
    let mappedCreditUnion: CreditUnion[] = []
    value.creditUnion.forEach(element => {
      mappedCreditUnion.push({
        contractNumber: element.contractNumber,
        name: element.name,
        lossTypes: this.mapCreditUnionDataLossTypes(element.lossTypes),
        activeDate: element.activeDate
      });
    });
    return mappedCreditUnion;
  }

  /** Maps the loss types from CU Data to the correct loss type enumeration */
  mapCreditUnionDataLossTypes(lossTypes:[]): LossType[]{
    let mappedLossType: LossType[] = [];
    lossTypes.forEach(element => {
      Object.values(LossType).forEach((value) => {
        if (element === value){
          mappedLossType.push(element)
        }
      })
    })
    return mappedLossType;
  }

  /** Returns true or false based on string. If string is not 'true' nor 'false', return back the string untouched. */
  mapStringToBooleanIfValueIsTrueOrFalse(value){
    if (value == 'true'){
      return true;
    } else if (value == 'false'){
      return false
    } else {
      return value;
    }
  }


  constructor(
    public internationalAddressService: InternationalAddressService
  ) {
    this.cmfgUtilities = new CmfgUtilitiesService();
    this.staticInfo = new StaticInfo();
  }
}
