import { Component, OnInit, Input } from '@angular/core';
import { ClaimService } from '../claim.service';
import { NgForm, ControlContainer, FormsModule } from '@angular/forms';
import { ClaimformService } from '../claimform.service';
import cssVars from 'css-vars-ponyfill';
import { ConfigurationService } from '../configuration.service';
import { InputTextModule } from 'primeng/inputtext';
import { NgIf, NgFor } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
    selector: 'app-form-other-benefits',
    templateUrl: './form-other-benefits.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    styleUrls: ['./form-other-benefits.component.css'],
    standalone: true,
    imports: [NgIf, FormsModule, NgFor, InputTextModule, NgSelectModule]
})
export class FormOtherBenefitsComponent implements OnInit {

  showLoansOtherCU:boolean = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.showLoansOtherCU);

  @Input() claimForm: NgForm

  constructor(
    public claimService: ClaimService,
    public claimformService: ClaimformService,
    public configService: ConfigurationService
  ) { }

  ngOnInit() {
    cssVars();
  }

}
