import { Injectable } from '@angular/core';
import { ClaimService } from './claim.service';
import { LossType } from './claim-data/enum-losstype';
import { StaticInfo } from './_helpers/static-info';
import { RoutesStaticInfo } from './_helpers/routes-static-info';
import { IsPreExisting } from './api-data/is-pre-existing';
import { Message } from 'primeng/api';
import { PhoneService } from './phone.service';
import { ClaimChannel } from './enum-claim-channel';
import { ClaimRouterService } from './claim-router.service';
import { CreditUnionService } from './credit-union.service';
import { UnemploymentReason } from './claim-data/enum-UnemploymentReason';
import { ConfigurationService } from 'src/app/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimformService {

  enumLossType = LossType;
  blnTxtUpdate: boolean = false;
  blnEmailUpdate: boolean = false;
  blnEmailPreferred: boolean = false;
  blnloansOtherCU: boolean = false;
  verifyEmail: string = '';
  lastEmployerMilitary: string;
  isSubmittingClaim: boolean = false;
  claimSubmitError: string;
  isLoadingPreExisting: boolean = false;
  showPreExistingQuestions: boolean = false;
  showIncurredDate: boolean;
  showEmailStatus: boolean;
  incurredDateText: string;
  descriptionPlaceHolder: string;
  preExistingMessage: Message[] = [];
  sameHours: boolean;
  showProvider = {
    two: false,
    three: false,
    four: false,
    five: false
  }
  showEmailConsentButtons: boolean = false;
  staticInfo = new StaticInfo();
  states = this.staticInfo.states;
  countries = this.staticInfo.country;
  genderOption = this.staticInfo.gender;
  yesNoUnsure = this.staticInfo.yesNoUnsure;
  yesNo = this.staticInfo.yesNo;
  preferredContactMethod = this.staticInfo.preferredContactMethod;
  empOccupation = this.staticInfo.empOccupation;
  employmentCategory = this.staticInfo.employmentCategory;
  jobClassOption = this.staticInfo.jobClass;
  accOrIll = this.staticInfo.accOrIll;
  medCodeCategory = this.staticInfo.medCodeCategory;
  header = this.staticInfo.header;
  lbl = this.staticInfo.label;
  lblIU = this.staticInfo.label_IU;
  lblIUSupp = this.staticInfo.label_IU_Supp;
  errMsg = this.staticInfo.errorMessage;
  urlPath = RoutesStaticInfo;
  touchUI = this.staticInfo.touchUI;
  dropDown_lossType = this.staticInfo.lossType;
  clearFormField = this.staticInfo.clearField;
  reasonJobEnded = this.staticInfo.reasonJobEnded;
  enumUnemploymentReason = UnemploymentReason;
  memberRegistrationLossTypes = this.creditUnionService.memberRegistrationCreditUnion == null ? null : this.staticInfo.memberRegistrationLossTypes(this.creditUnionService.memberRegistrationCreditUnion.lossTypes)

  setSameHours(_yesNoUnsure: string){
    if (_yesNoUnsure == this.staticInfo.yes || _yesNoUnsure == null){
      this.clearField(this.clearFormField.hasSameHoursYes);
      this.sameHours = false;
    } else {
      this.sameHours = true;
    }
  }

  /**
   * If the user declines consent to Email messages, then hide the email consent buttons
   * as they are no longer needed, and set the preferred method of correspondence to Mail
   */
  declineEmailConsent(){
    this.showEmailConsentButtons = false;
    this.claimService.claimRoot.contact.prefCorrespondence = "Mail";
  }

  /** If user opts in for text status updates, this makes the cell phone field required.
   * If user decides to not opt-in, makes the field not required. */
  txtUpdate() {
    this.blnTxtUpdate = this.claimService.claimRoot.contact.statusTextOptIn === true ? true : false;
  }

  /** If user opts in for email status updates, this makes the email field required.
   * If user decides to not opt-in, makes the field not required. */
  emailUpdate() {
    this.blnEmailUpdate = this.claimService.claimRoot.contact.statusEmailOptIn === true ? true : false;
  }

  /** If user's preferred method of contact is Email, set email updates to Yes and hide control */
  emailUpdatesSet() {
    if (this.claimService.claimRoot.contact.prefCorrespondence == this.staticInfo.email) {
      this.claimService.claimRoot.contact.statusEmailOptIn = true;
      // Hide email status control
      this.showEmailStatus = false
    }
  }

  /** If user's preferred method of contact is Email, this makes email field required.
   * If user's preferred method of contact is Mail, then this makes email is not required. */
  emailPreferred() {
    if (this.claimService.claimRoot.contact.prefCorrespondence == this.staticInfo.email) {
      this.blnEmailPreferred = true
    } else {
      this.blnEmailPreferred = false
      this.showEmailStatus = true
    }
  }

  /** Shows or hides a field based on user input. */
  showField(value: string) {
    if (value == this.staticInfo.yes || value == this.staticInfo.unsure) {
      this.blnloansOtherCU = true;
    } else {
      this.blnloansOtherCU = false;
      this.claimService.claimRoot.claim.otherCU = null;
    }
  }

  /** Shows or hides the credit card field based upon the claim channel */
  showCCField(value: ClaimChannel){
      return value === ClaimChannel.MemberRegistration;
  }

  /** Removes the user's chosen additional provider. */
  removeProvider(num: number) {
    switch (num) {
      case 2: {
        this.showProvider.two = false;
        this.claimService.claimRoot.claim.incident.physician2 = {
          name: null,
          phoneNumber: null,
        }
        break;
      }
      case 3: {
        this.showProvider.three = false;
        this.claimService.claimRoot.claim.incident.physician3 = {
          name: null,
          phoneNumber: null,
        }
        break;
      }
      case 4: {
        this.showProvider.four = false;
        this.claimService.claimRoot.claim.incident.physician4 = {
          name: null,
          phoneNumber: null,
        }
        break;
      }
      case 5: {
        this.showProvider.five = false;
        this.claimService.claimRoot.claim.incident.physician5 = {
          name: null,
          phoneNumber: null,
        }
        break;
      }
    }
  }

  /** Clears the value of the 2-way binding value if the user triggers a field to be hidden. */
  clearField(value: string) {
    switch (value) {
      case this.clearFormField.hasBeenHospitalized: {
        this.claimService.claimRoot.claim.incident.hospitalizationStartDate = null;
        this.claimService.claimRoot.claim.incident.hospitalizationEndDate = null;
        break;
      }
      case this.clearFormField.hasReturnedToWork: {
        this.claimService.claimRoot.claim.employment.returnDate = null;
        this.claimService.claimRoot.claim.employment.hasSameHours = null;
        this.sameHours = null;
        this.claimService.claimRoot.claim.employment.nextAppointmentDate = null;
        this.claimService.claimRoot.claim.employment.estimatedRecoverDate = null;
        if (window.location.pathname.includes(this.urlPath.claimSupp)){
          this.claimService.claimRoot.claim.employment.receivingAid = null;
          this.claimService.claimRoot.claim.employment.hoursPerWeek = null;
          this.claimService.acpValues.plansToWorkWithSameEmployer = null;
        }
        break;
      }
      case this.clearFormField.hasReturnedToWorkYes: {
        this.claimService.claimRoot.claim.employment.jobContactsPerWeek = null;
        this.claimService.claimRoot.claim.employment.noAidDescription = null;
        this.claimService.claimRoot.claim.incident.physician.name = null;
        this.claimService.claimRoot.claim.incident.physician.phoneNumber = null;
        for(let i = 2; i <= 5; i++){
          this.removeProvider(i);
        }
        break;
      }
      case this.clearFormField.lastEmployerMilitary: {
        this.claimService.claimRoot.claim.employment.militaryStatus = null;
        this.claimService.claimRoot.claim.employment.separationDate = null;
        this.claimService.claimRoot.claim.employment.separationReason = null;
        this.claimService.claimRoot.claim.employment.characterOfService = null;
        break;
      }
      case this.clearFormField.receivingAidYes: {
        this.claimService.claimRoot.claim.employment.noAidDescription = null;
        this.claimService.claimRoot.claim.employment.lastDateEmploymentCompPaidThru = null;
        break;
      }
      case this.clearFormField.hasSameHoursYes: {
        this.claimService.claimRoot.claim.employment.originalJobDuties = null;
        this.claimService.claimRoot.claim.employment.currentJobDuties = null;
        this.claimService.claimRoot.claim.employment.restrictionsDescription = null;
        this.claimService.claimRoot.claim.employment.hasSameWages = null;
        this.claimService.claimRoot.claim.employment.estimatedRecoverDate = null;
        this.claimService.claimRoot.claim.employment.nextAppointmentDate = null;
        break;
      }
      case this.clearFormField.hasSameWagesYes: {
        this.claimService.claimRoot.claim.employment.wageChangeDescription = null
        break;
      }
    }
  }

  /** Clears supplemental form data. */
  clearSupplementalData() {
    this.claimService.claimRoot.claim.employment.hasReturnedToWork = null;
    this.claimService.claimRoot.claim.areLoansActive = null;
    this.claimService.documents = [];
    //The following clearField() lines reset the hide/show logic for supplemental.
    this.clearField(this.clearFormField.hasReturnedToWorkYes);
    this.clearField(this.clearFormField.hasReturnedToWork);
  }

  /** Calls claimService.checkPreExisting(). Returns the boolean response from the API. */
  checkPreExisting() {
    if(this.claimService.claimChannel == ClaimChannel.Intake){
      if (this.claimService.claimRoot.claim.incurredDate &&
        this.claimService.claimRoot.claim.employment.lastDateWorked &&
        this.claimService.claimRoot.claim.incident.drOrderedNoWorkDate) {
        this.isLoadingPreExisting = true;
        this.claimService.checkPreExisting().subscribe(
          (data: IsPreExisting) => {
            this.isLoadingPreExisting = false;
            this.preExistingMessage = [];
            if (!data.IsPreExisting) {
              this.preExistingMessage.push({ severity: 'info', summary: null, detail: this.lbl.noPreExisting });
            }
            this.showPreExistingQuestions = data.IsPreExisting;
          },
          (err) => {
            this.isLoadingPreExisting = false;
            this.preExistingMessage = [];
            this.preExistingMessage.push({ severity: 'warn', summary: null, detail: this.errMsg.internalServer(this.phoneService.phoneNumber) });
          },
          () => {

          }
        )
      } else {
        this.preExistingMessage = [];
        this.preExistingMessage.push({ severity: 'warn', summary: null, detail: this.errMsg.checkPreExisting });
      }
    } else {
      this.claimService.checkPreExisting().subscribe(
        (data: IsPreExisting) => {
          this.showPreExistingQuestions = data.IsPreExisting;
        },
        (err) => {

        },
        () => {

        }
      )
    }
  }

  /** Subscribe to ClaimService.submitClaim. */
  submitClaimSubscribe(){
    this.isSubmittingClaim = true;
    this.claimService.submitClaim().subscribe(
      (data) => {
        this.isSubmittingClaim = false;
      },
      (err) => {
        this.isSubmittingClaim = false;
        this.claimSubmitError = this.errMsg.claimSubmitError(this.phoneService.phoneNumber)
      },
      () => {
        this.claimService.claimHasBeenSubmitted = true;
        this.claimService.claimSubmitSuccess = true;
        this.claimRouterService.routeToClaimSubmitSuccess();
      }
    )
  }

  /** Shows or hides the additional benefits section based upon the claim channel and feature toggle */
  showAdditionalBenefits():boolean{
    if (this.claimService.claimChannel === ClaimChannel.MemberRegistration || this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.showLoansOtherCU) === true) {
      return true;
    } else {
      return false;
    }
  }

  /** Shows or hides the Last 4 SSN field based upon the claim channel */
  showLast4SSN():boolean{
    if (this.claimService.claimChannel === ClaimChannel.PostRegistration) {
      return false;
    } else {
      return true;
    }
  }

  /** Decides if the Last 4 SSN field required based upon the claim channel */
  requiredLast4SSN():boolean{
    if (this.claimService.claimChannel === ClaimChannel.MemberRegistration) {
      return true;
    } else {
      return false;
    }
  }

  /** Shows or hides the document upload section based upon the feature toggle */
  showDocumentUpload():boolean{
    // 07-19-2023: Removed toggle and hardcoded to false so we do not accidently turn it on via toggle. This feature is too massive to be dependent on a single toggle.
    return false;
  }

  constructor(
    private claimService: ClaimService,
    private phoneService: PhoneService,
    private claimRouterService: ClaimRouterService,
    private configService: ConfigurationService,
    private creditUnionService: CreditUnionService
    ) {
      if (this.claimService.claimChannel == ClaimChannel.Intake || this.claimService.claimChannel == ClaimChannel.PostRegistration || this.claimService.claimChannel == ClaimChannel.Supplemental){
        this.txtUpdate()
        this.emailUpdate()
        this.emailPreferred()
        this.showField(this.claimService.claimRoot.claim.loansOtherCU);
      }
   }
}
