import { IConfiguration } from '../interfaces/iconfiguration';
import { CreditUnion } from './credit-union';
import { FeatureToggle } from './feature-toggle';

export class Configuration implements IConfiguration {
    featureToggles: FeatureToggle[];
    creditUnions: CreditUnion[];

    constructor(){
        this.featureToggles = null;
        this.creditUnions = null;
    }
}
