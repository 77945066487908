<div class="form-group row">
  <label for="selfEmployed" class="col-sm-5 col-form-label">
    {{claimformService.lblIU.selfEmployed}} <span class="form-error-text">*</span>
  </label>
  <div class="col-sm-7">
    <div>
    <ng-select class="fw-normal fs-6" labelForId="selfEmployed" name="selfEmployed" #selfEmployed="ngModel" bindLabel="value" bindValue="value"
      [(ngModel)]="claimService.claimRoot.claim.employment.selfEmployed" [selectOnTab]="true" [searchable]="false" [clearable]="false" (change)="clearFieldsIU(claimformService.lblIU.selfEmployed)"
      [items]="claimformService.yesNoUnsure" placeholder="{{claimformService.lblIU.selfEmployed}}" required>
    </ng-select>
    </div>
    <div *ngIf="(selfEmployed.invalid && !selfEmployed.pristine) || (claimForm.submitted && selfEmployed.invalid)"
      class="alert alert-danger">
      {{claimformService.errMsg.genericSelect}}
    </div>
  </div>
</div>
<span *ngIf="claimService.claimRoot.claim.employment.selfEmployed === claimformService.staticInfo.no || claimService.claimRoot.claim.employment.selfEmployed === claimformService.staticInfo.unsure">
  <div class="form-group row">
    <label for="employerName" class="col-sm-5 col-form-label">
      {{claimformService.lblIU.employerName}}
    </label>
    <div class="col-sm-7">
      <input type="text" pInputText class="form-control input-styles" id="employerName"
        [(ngModel)]="claimService.claimRoot.claim.employment.employer.name" name="employerName" #employerName="ngModel"
        maxlength="50">
    </div>
  </div>
  <div class="form-group row">
    <label for="employerNumber" class="col-sm-5 col-form-label">
      {{claimformService.lblIU.employerNumber}}
    </label>
    <div class="col-sm-7">
      <p-inputMask styleClass="input-styles" type="tel" id="employerNumber"
        [(ngModel)]="claimService.claimRoot.claim.employment.employer.phoneNumber" name="employerNumber"
        #employerNumber="ngModel" mask="999-999-9999" styleClass="input-styles"></p-inputMask>
    </div>
  </div>
</span>
<div class="form-group row">
  <label for="hrsPerWeekPriorToUnemployment" class="col-sm-5 col-form-label">
    {{claimformService.lblIU.hrsPerWeekPriorToUnemployment}} <span class="form-error-text">*</span>
  </label>
  <div class="col-sm-7">
    <input type="tel" pInputText pKeyFilter="num" class="form-control input-styles" id="hrsPerWeekPriorToUnemployment" required
    [(ngModel)]="claimService.claimRoot.claim.employment.hoursPerWeek" name="hrsPerWeekPriorToUnemployment" #hrsPerWeekPriorToUnemployment="ngModel"
    maxlength="3">
    <div *ngIf="(hrsPerWeekPriorToUnemployment.invalid && !hrsPerWeekPriorToUnemployment.pristine) || (claimForm.submitted && hrsPerWeekPriorToUnemployment.invalid)"
      class="alert alert-danger">
      {{claimformService.errMsg.hrsPerWeekPriorToUnemployment}}
    </div>
  </div>
</div>
<div class="form-group row">
  <label for="employedOneYearOrMore" class="col-sm-5 col-form-label">
    {{claimformService.lblIU.employedOneYearOrMore}} <span class="form-error-text">*</span>
  </label>
  <div class="col-sm-7">
    <div>
    <ng-select class="fw-normal fs-6" labelForId="employedOneYearOrMore" name="employedOneYearOrMore" #employedOneYearOrMore="ngModel" bindLabel="value" bindValue="value"
      [(ngModel)]="claimService.acpValues.employedLongerThanOneYear" (change)="clearFieldsIU(claimformService.lblIU.employedOneYearOrMore)" [selectOnTab]="true" [searchable]="false" [clearable]="false"
      [items]="claimformService.yesNoUnsure" placeholder="{{claimformService.lblIU.employedOneYearOrMore}}" required>
    </ng-select>
    </div>
    <div *ngIf="(employedOneYearOrMore.invalid && !employedOneYearOrMore.pristine) || (claimForm.submitted && employedOneYearOrMore.invalid)"
      class="alert alert-danger">
      {{claimformService.errMsg.genericSelect}}
    </div>
  </div>
</div>
<span *ngIf="claimService.acpValues.employedLongerThanOneYear === claimformService.staticInfo.yes">
  <div class="form-group row">
    <label for="hasSeasonalEmployment" class="col-sm-5 col-form-label">
      {{claimformService.lblIU.hasSeasonalEmployment}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <div>
      <ng-select class="fw-normal fs-6" labelForId="hasSeasonalEmployment" name="hasSeasonalEmployment" #hasSeasonalEmployment="ngModel" bindLabel="value" bindValue="value"
        [(ngModel)]="claimService.acpValues.hasSeasonalEmployment" [selectOnTab]="true" [searchable]="false" [clearable]="false"
        [items]="claimformService.yesNoUnsure" placeholder="{{claimformService.lblIU.hasSeasonalEmployment}}" required>
      </ng-select>
      </div>
      <div *ngIf="(hasSeasonalEmployment.invalid && !hasSeasonalEmployment.pristine) || (claimForm.submitted && hasSeasonalEmployment.invalid)"
        class="alert alert-danger">
        {{claimformService.errMsg.genericSelect}}
      </div>
    </div>
  </div>
</span>
<span *ngIf="claimService.acpValues.employedLongerThanOneYear === claimformService.staticInfo.no || claimService.acpValues.employedLongerThanOneYear === claimformService.staticInfo.unsure">
  <div class="form-group row">
    <label for="isUnionMember" class="col-sm-5 col-form-label">
      {{claimformService.lblIU.isUnionMember}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <div>
      <ng-select class="fw-normal fs-6" labelForId="isUnionMember" name="isUnionMember" #isUnionMember="ngModel" bindLabel="value" bindValue="value"
        [(ngModel)]="claimService.acpValues.isUnionMember" (change)="clearFieldsIU(claimformService.lblIU.isUnionMember)" [selectOnTab]="true" [searchable]="false" [clearable]="false"
        [items]="claimformService.yesNoUnsure" placeholder="{{claimformService.lblIU.isUnionMember}}" required>
      </ng-select>
      </div>
      <div *ngIf="(isUnionMember.invalid && !isUnionMember.pristine) || (claimForm.submitted && isUnionMember.invalid)"
        class="alert alert-danger">
        {{claimformService.errMsg.genericSelect}}
      </div>
    </div>
  </div>
  <span *ngIf="claimService.acpValues.isUnionMember === claimformService.staticInfo.yes || claimService.acpValues.isUnionMember === claimformService.staticInfo.unsure">
    <div class="form-group row">
      <label for="unionName" class="col-sm-5 col-form-label">
        {{claimformService.lblIU.unionName}} <span class="form-error-text">*</span>
      </label>
      <div class="col-sm-7">
        <input type="text" pInputText class="form-control input-styles" id="unionName"
          [(ngModel)]="claimService.acpValues.unionName" name="unionName" #unionName="ngModel"
          maxlength="50" required>
        <div *ngIf="(unionName.invalid && !unionName.pristine) || (claimForm.submitted && unionName.invalid)"
        class="alert alert-danger">
          {{claimformService.errMsg.generic}}
        </div>
      </div>
    </div>
  </span>
  <span *ngIf="claimService.acpValues.isUnionMember === claimformService.staticInfo.no">
    <div class="form-group row">
      <label for="isTemporaryEmployee" class="col-sm-5 col-form-label">
        {{claimformService.lblIU.isTemporaryEmployee}} <span class="form-error-text">*</span>
      </label>
      <div class="col-sm-7">
        <div>
        <ng-select class="fw-normal fs-6" labelForId="isTemporaryEmployee" name="isTemporaryEmployee" #isTemporaryEmployee="ngModel" bindLabel="value" bindValue="value"
          [(ngModel)]="claimService.acpValues.isTemporaryEmployee" [selectOnTab]="true" [searchable]="false" [clearable]="false"
          [items]="claimformService.yesNoUnsure" placeholder="{{claimformService.lblIU.isTemporaryEmployee}}" required>
        </ng-select>
        </div>
        <div *ngIf="(isTemporaryEmployee.invalid && !isTemporaryEmployee.pristine) || (claimForm.submitted && isTemporaryEmployee.invalid)"
          class="alert alert-danger">
          {{claimformService.errMsg.genericSelect}}
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="isContractBasis" class="col-sm-5 col-form-label">
        {{claimformService.lblIU.isContractBasis}} <span class="form-error-text">*</span>
      </label>
      <div class="col-sm-7">
        <div>
        <ng-select class="fw-normal fs-6" labelForId="isContractBasis" name="isContractBasis" #isContractBasis="ngModel" bindLabel="value" bindValue="value"
          [(ngModel)]="claimService.acpValues.isContractBasis" [selectOnTab]="true" [searchable]="false" [clearable]="false"
          [items]="claimformService.yesNoUnsure" placeholder="{{claimformService.lblIU.isContractBasis}}" required>
        </ng-select>
        </div>
        <div *ngIf="(isContractBasis.invalid && !isContractBasis.pristine) || (claimForm.submitted && isContractBasis.invalid)"
          class="alert alert-danger">
          {{claimformService.errMsg.genericSelect}}
        </div>
      </div>
    </div>
  </span>
  <div class="form-group row">
    <label for="knewLayoffWasPossible" class="col-sm-5 col-form-label">
      {{claimformService.lblIU.knewLayoffWasPossible}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <div>
      <ng-select class="fw-normal fs-6" labelForId="knewLayoffWasPossible" name="knewLayoffWasPossible" #knewLayoffWasPossible="ngModel" bindLabel="value" bindValue="value"
        [(ngModel)]="claimService.acpValues.knewLayoffWasPossible" [selectOnTab]="true" [searchable]="false" [clearable]="false"
        [items]="claimformService.yesNoUnsure" placeholder="{{claimformService.lblIU.knewLayoffWasPossible}}" required>
      </ng-select>
      </div>
      <div *ngIf="(knewLayoffWasPossible.invalid && !knewLayoffWasPossible.pristine) || (claimForm.submitted && knewLayoffWasPossible.invalid)"
        class="alert alert-danger">
        {{claimformService.errMsg.genericSelect}}
      </div>
    </div>
  </div>
</span>
<span *ngIf="showCovid19Information === true">
  <div class="form-group row">
    <label for="isUnemploymentFromCOVID19" class="col-sm-5 col-form-label">
      {{claimformService.lblIU.isUnemploymentFromCOVID19}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <div>
      <ng-select class="fw-normal fs-6" labelForId="isUnemploymentFromCOVID19" name="isUnemploymentFromCOVID19" #isUnemploymentFromCOVID19="ngModel" bindLabel="value" bindValue="value"
        [(ngModel)]="claimService.acpValues.isUnemploymentFromCOVID19" [selectOnTab]="true" [searchable]="false" [clearable]="false"
        (change)="clearFieldsIU(claimformService.lblIU.isUnemploymentFromCOVID19)" 
        [items]="claimformService.yesNoUnsure" placeholder="{{claimformService.lblIU.isUnemploymentFromCOVID19}}" required>
      </ng-select>
      </div>
      <div *ngIf="(isUnemploymentFromCOVID19.invalid && !isUnemploymentFromCOVID19.pristine) || (claimForm.submitted && isUnemploymentFromCOVID19.invalid)"
        class="alert alert-danger">
        {{claimformService.errMsg.genericSelect}}
      </div>
    </div>
  </div>
  <span *ngIf="claimService.acpValues.isUnemploymentFromCOVID19 === claimformService.staticInfo.yes || claimService.acpValues.isUnemploymentFromCOVID19 === claimformService.staticInfo.unsure"> <!-- Yes, Unsure -->
    <div class="form-group row">
      <label for="isReceivingWagesDuringCOVID19Unemployment" class="col-sm-5 col-form-label">        
        <div [innerHTML]="claimformService.lblIU.isReceivingWagesDuringCOVID19Unemployment"></div><span class="form-error-text">*</span>
      </label>
      <div class="col-sm-7">
        <div>
        <ng-select class="fw-normal fs-6" labelForId="isReceivingWagesDuringCOVID19Unemployment" name="isReceivingWagesDuringCOVID19Unemployment" #isReceivingWagesDuringCOVID19Unemployment="ngModel" bindLabel="value" bindValue="value"
          [(ngModel)]="claimService.acpValues.isReceivingWagesDuringCOVID19Unemployment" [selectOnTab]="true" [searchable]="false" [clearable]="false"
          [items]="claimformService.yesNoUnsure" placeholder="{{claimformService.lblIU.isReceivingWagesDuringCOVID19Unemployment}}" required>
        </ng-select>
        </div>
        <div *ngIf="(isReceivingWagesDuringCOVID19Unemployment.invalid && !isReceivingWagesDuringCOVID19Unemployment.pristine) || (claimForm.submitted && isReceivingWagesDuringCOVID19Unemployment.invalid)"
          class="alert alert-danger">
          {{claimformService.errMsg.genericSelect}}
        </div>
      </div>
    </div>
  </span>
</span>
<div class="form-group row">
  <label for="reasonForUnemployment" class="col-sm-5 col-form-label">
    {{claimformService.lblIU.reasonForUnemployment}} <span class="form-error-text">*</span>
  </label>
  <div class="col-sm-7">
    <div>
    <ng-select class="fw-normal fs-6" labelForId="reasonForUnemployment" name="reasonForUnemployment" #reasonForUnemployment="ngModel" bindLabel="label" bindValue="value"
      [(ngModel)]="claimService.acpValues.reasonForUnemployment" [selectOnTab]="true" [searchable]="false" [clearable]="false" (change)="clearFieldsIU(claimformService.lblIU.reasonForUnemployment)" 
      [items]="claimformService.reasonJobEnded" placeholder="{{claimformService.lblIU.reasonForUnemployment}}" required>
    </ng-select>
    </div>
    <div *ngIf="(reasonForUnemployment.invalid && !reasonForUnemployment.pristine) || (claimForm.submitted && reasonForUnemployment.invalid)"
      class="alert alert-danger">
      {{claimformService.errMsg.genericSelect}}
    </div>
  </div>
</div>
<span *ngIf="claimService.acpValues.reasonForUnemployment === claimformService.enumUnemploymentReason.Other">
  <div class="form-group row">
    <label for="differentReasonForUnemployment" class="col-sm-5 col-form-label">
      {{claimformService.lblIU.differentReasonForUnemployment}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <input type="text" pInputText class="form-control input-styles" id="differentReasonForUnemployment"
        [(ngModel)]="claimService.acpValues.differentReasonForUnemployment" name="differentReasonForUnemployment" #differentReasonForUnemployment="ngModel"
        maxlength="200" required>
      <div *ngIf="(differentReasonForUnemployment.invalid && !differentReasonForUnemployment.pristine) || (claimForm.submitted && differentReasonForUnemployment.invalid)"
      class="alert alert-danger">
        {{claimformService.errMsg.generic}}
      </div>
    </div>
  </div>
</span>
<span *ngIf="claimService.acpValues.reasonForUnemployment === claimformService.enumUnemploymentReason.Other || claimService.acpValues.reasonForUnemployment === claimformService.enumUnemploymentReason.MissedWork
|| claimService.acpValues.reasonForUnemployment === claimformService.enumUnemploymentReason.ResignedOrRelocated || claimService.acpValues.reasonForUnemployment === claimformService.enumUnemploymentReason.RuleOrPolicyViolation
|| claimService.acpValues.reasonForUnemployment === claimformService.enumUnemploymentReason.Misconduct">
  <div class="form-group row">
    <label for="receivingAid" class="col-sm-5 col-form-label">
      {{claimformService.lblIU.receivingAid}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <div>
      <ng-select class="fw-normal fs-6" labelForId="receivingAid" name="receivingAid" #receivingAid="ngModel" bindLabel="value" bindValue="value"
        [(ngModel)]="claimService.claimRoot.claim.employment.receivingAid" [selectOnTab]="true" [searchable]="false" [clearable]="false"
        [items]="claimformService.yesNoUnsure" placeholder="{{claimformService.lblIU.receivingAid}}" required>
      </ng-select>
      </div>
      <div *ngIf="(receivingAid.invalid && !receivingAid.pristine) || (claimForm.submitted && receivingAid.invalid)"
        class="alert alert-danger">
        {{claimformService.errMsg.genericSelect}}
      </div>
    </div>
  </div>
</span>
<fieldset class="form-group">
  <div class="row">
    <div class="col-sm-5">
      <legend>
      {{claimformService.lblIU.hasReturnedToWork}} <span class="form-error-text">*</span>
      </legend>
    </div>
    <div class="col-sm-7">
      <div class="row" id="hasReturnedToWork">
        <div class="col-12 radio-button">
          <p-radioButton name="hasReturnedToWork" [value]="true" required
          label="{{claimformService.yesNo.yes}}"
          [(ngModel)]="claimService.claimRoot.claim.employment.hasReturnedToWork"
          inputId="hasReturnedToWork1" id="hasReturnedToWorkYes" #hasReturnedToWork="ngModel"
          (click)="clearFieldsIU(claimformService.lblIU.hasReturnedToWork)">
          </p-radioButton>
        </div>
        <div class="col-12 radio-button">
          <p-radioButton name="hasReturnedToWork" [value]="false" required
          label="{{claimformService.yesNo.no}}"
          [(ngModel)]="claimService.claimRoot.claim.employment.hasReturnedToWork"
          inputId="hasReturnedToWork2" id="hasReturnedToWorkNo" #hasReturnedToWork="ngModel"
          (click)="clearFieldsIU(claimformService.lblIU.hasReturnedToWork)">
          </p-radioButton>
        </div>
      </div>
      <div *ngIf="(hasReturnedToWork.invalid && !hasReturnedToWork.pristine) || (claimForm.submitted && hasReturnedToWork.invalid)"
      class="alert alert-danger">
      {{claimformService.errMsg.generic}}
      </div>
    </div>
  </div>
</fieldset>
<div *ngIf="claimService.claimRoot.claim.employment.hasReturnedToWork === true" class="form-group row">
  <label for="returnDate" class="col-sm-5 col-form-label">
    {{claimformService.lblIU.returnDate}} <span class="form-error-text">*</span>
  </label>
  <div class="col-sm-7">
    <p-calendar id="returnDate" required [monthNavigator]="true" [yearNavigator]="true" [showOnFocus]="false" [touchUI]="claimformService.touchUI"
      yearRange="{{claimformService.lbl.todayPlusMinus90Yrs}}" dataType="string" rPCalendarMask
      placeholder="MM/DD/YYYY" [(ngModel)]="claimService.claimRoot.claim.employment.returnDate" class="ui-fluid"
      name="returnDate" #returnDate="ngModel" [showIcon]="true" styleClass="input-styles" dateMask></p-calendar>
    <div *ngIf="(returnDate.invalid && !returnDate.pristine) || (claimForm.submitted && returnDate.invalid)"
      class="alert alert-danger">
      <span *ngIf="returnDate.errors.required">{{claimformService.errMsg.dateRequired}}</span>
    </div>
  </div>
</div>
<div *ngIf="claimService.claimRoot.claim.employment.hasReturnedToWork === true" class="form-group row">
  <label for="currentHoursPerWeek" class="col-sm-5 col-form-label">
    {{claimformService.lblIU.currentHoursPerWeek}} <span class="form-error-text">*</span>
  </label>
  <div class="col-sm-7">
    <div class="select-container">
    <select class="form-control" id="currentHoursPerWeek" name="currentHoursPerWeek" 
    [(ngModel)]="claimService.acpValues.currentHoursPerWeek" #currentHoursPerWeek="ngModel" required>
      <option disabled></option>
      <option *ngFor="let cWeekHrs of weeksCounterStrings(169); " [ngValue]="cWeekHrs">{{cWeekHrs}}</option>
    </select>
    </div>
    <div *ngIf="(currentHoursPerWeek.invalid && !currentHoursPerWeek.pristine) || (claimForm.submitted && currentHoursPerWeek.invalid)"
      class="alert alert-danger">
      {{claimformService.errMsg.currentHoursPerWeek}}
    </div>
  </div>
</div>
<div *ngIf="claimService.claimRoot.claim.employment.hasReturnedToWork === false" class="form-group row">
  <label for="plansToWorkWithSameEmployer" class="col-sm-5 col-form-label">
    {{claimformService.lblIU.plansToWorkWithSameEmployer}} <span class="form-error-text">*</span>
  </label>
  <div class="col-sm-7">
    <div>
    <ng-select class="fw-normal fs-6" labelForId="plansToWorkWithSameEmployer" name="plansToWorkWithSameEmployer" #plansToWorkWithSameEmployer="ngModel" bindLabel="value" bindValue="value"
      [(ngModel)]="claimService.acpValues.plansToWorkWithSameEmployer" [selectOnTab]="true" [searchable]="false" [clearable]="false"
      [items]="claimformService.yesNoUnsure" placeholder="{{claimformService.lblIU.plansToWorkWithSameEmployer}}" required>
    </ng-select>
    </div>
    <div *ngIf="(plansToWorkWithSameEmployer.invalid && !plansToWorkWithSameEmployer.pristine) || (claimForm.submitted && plansToWorkWithSameEmployer.invalid)"
      class="alert alert-danger">
      {{claimformService.errMsg.genericSelect}}
    </div>
  </div>
</div>
<div class="form-group row" *ngIf="showSeekingEmploymentQuestion()">
  <label for="isSeekingEmployment" class="col-sm-5 col-form-label">
    {{claimformService.lblIU.isSeekingEmployment}} <span class="req">*</span>
  </label>
  <div class="col-sm-7">
    <div>
    <ng-select class="fw-normal fs-6" labelForId="isSeekingEmployment" name="isSeekingEmployment" #isSeekingEmployment="ngModel" bindLabel="value" bindValue="value"
      [(ngModel)]="claimService.acpValues.isSeekingEmployment" [selectOnTab]="true" [searchable]="false" [clearable]="false"
      [items]="claimformService.yesNoUnsure" placeholder="{{claimformService.lblIU.isSeekingEmployment}}" required>
    </ng-select>
    </div>
    <div *ngIf="(isSeekingEmployment.invalid && !isSeekingEmployment.pristine) || (claimForm.submitted && isSeekingEmployment.invalid)"
      class="alert alert-danger">
      {{claimformService.errMsg.genericSelect}}
    </div>
  </div>
</div>
<div>
  <app-form-description></app-form-description> 
</div>
