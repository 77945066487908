import { Injectable } from '@angular/core';
import { StaticInfo } from '../app/_helpers/static-info';

@Injectable({
  providedIn: 'root'
})
export class InternationalAddressService {

  isForeignCountry: boolean = false;
  staticInfo_usa = new StaticInfo().countryCode.usa

  isClaimInternational(countryFromClaim:string){
    this.isForeignCountry = (countryFromClaim != this.staticInfo_usa)
  }

  constructor() { }
}
