<div class="container">
  <div class="row">
    <div class="col-sm-12 center">
      <h1>{{claimService.staticInfo.pageHeaders.GDPRConsent}}</h1>
      <span *ngIf="isChannelIntake()" class="intake-consent">
        <br>
        Since you are a member of Service CU that resides in the European Union/European Economic Area (EU/EEA), we are required to receive your explicit consent to use your personal data to process your claim per the General Data Privacy Regulation.
        <br><br>
        We will mail to you a consent form requesting your signature, though if we receive your verbal consent now, we can begin processing your claim immediately.
        <br><br>
        Do I have your verbal consent to begin processing of your claim?
        <br><br>
      </span>
      <span *ngIf="!isChannelIntake()" class="prwc">
        <br>
        <span class="prwc-consent-body">
          In order to process your claim under Credit Disability Insurance (CI), it will be necessary for CMFG Life Insurance Company, as processor for Service Credit Union’s AD&D and CI programs, to use the following information about you: 
          <br><br>
          •Data concerning your physical and/or mental health 
          <br><br>
          We also may need to disclose this personal data to a consulting medical professional, who will use your personal data for the purpose set out above. This disclosure will include a transfer of your personal data to the United States. 
          <br><br>
          The General Data Protection Regulation (Regulation (EU) 2016/679) requires that your explicit consent is required for this processing of your personal data. By signing below, you give your explicit consent to such processing for the purpose described above. 
          <br><br>
          <b><i>I hereby consent to the above-noted use of my personal data for the purpose of processing my claim.</i></b>
          <br><br>
        </span>     
      </span>
      <div class="row">
        <div class="col-sm-5">
          <button
          role="button"
          name="acceptGDPRConsentButton"
          id="acceptGDPRConsentButton"
          class="btn btn-primary button-margin"
          (click)="acceptGDPRConsent()">Yes, I consent</button>
          <button
          role="button"
          name="declineGDPRConsentButton"
          id="declineGDPRConsentButton"
          class="btn btn-secondary button-margin"
          (click)="declineGDPRConsent()">No, I do not consent</button>
        </div>
        <div class="col-sm-7">
        </div>
      </div>
      <span *ngIf="!isChannelIntake()" class="prwc-consent-body">
          <br>
          Please be aware that you have the right to refuse to give your consent. However, without your consent we will not be able to process your personal data, which may have practical implications as set out below. 
          <br><br>
          You have the right to withdraw your consent at any time. If you do withdraw your consent, this will not affect any processing of your personal data which has already happened, or any other processing of your data not in relation to this consent, but it will mean that we will stop processing your data for the purposes outlined above. 
          <br><br>
          If your consent is refused or revoked, this may mean that we will be unable to pay any benefits to which you would otherwise be entitled under your insurance coverage. 
          <br><br>
          To withdraw your consent, please contact us at <b>{{memberclaimemail}}</b> or 1.844.707.4964. Our hours of operation are 7 am to 6 pm (Central Time).
          <br><br><br>
      </span>
      <a class="btn btn-secondary button-margin" name="GDPRConsentPdf" id="GDPRConsentPdf" href={{gdprconsentform}} role="button" target="_blank">
        Download PDF Version
      </a>
    </div>
  </div>
</div>
