import { Component, OnInit, TemplateRef } from '@angular/core';
import { PhoneService } from '../phone.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ClaimService } from '../claim.service';
import { ConfigurationService } from 'src/app/configuration.service';
import { StaticInfo } from '../_helpers/static-info';
import { CommunityResourcesComponent } from '../community-resources/community-resources.component';
import { FaqComponent } from '../faq/faq.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.css'],
    standalone: true,
    imports: [NgIf, FaqComponent, CommunityResourcesComponent]
})

export class ContactUsComponent implements OnInit {
  staticInfo = new StaticInfo();
  display: boolean = false;
  modalRef: BsModalRef;
  showPortalUpdatesMessage: boolean;
  AllowTrustage: boolean;
  brandname: string;
  faxcover:string;

  /** Displays a modal on the page. */
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  constructor(
    public phoneService: PhoneService,
    private modalService: BsModalService,
    public claimService: ClaimService,
    private configService: ConfigurationService) { }

  ngOnInit() {    
    if (this.configService.configurationData.featureToggles) {
        this.showPortalUpdatesMessage = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.showPortalUpdatesMessage);
        this.AllowTrustage = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.allowTrustageBranding);
        (this.AllowTrustage && typeof this.AllowTrustage != 'undefined')?
          (this.brandname= this.staticInfo.brandname.trustage,
            this.faxcover=this.staticInfo.faxcoverpath.faxcoverTrustage):
          (this.brandname=this.staticInfo.brandname.cmg,
            this.faxcover=this.staticInfo.faxcoverpath.faxcovercmg)
      } else {
      this.configService.loadFeatureToggleData().toPromise()
      .then(p_configService => {
        this.showPortalUpdatesMessage = p_configService.getFeatureToggleValueFromKey(this.configService.featureToggles.showPortalUpdatesMessage),
        this.AllowTrustage = this.configService.getFeatureToggleValueFromKey(this.configService.featureToggles.allowTrustageBranding),
        (this.AllowTrustage && typeof this.AllowTrustage != 'undefined')?
        (this.brandname= this.staticInfo.brandname.trustage,
          this.faxcover=this.staticInfo.faxcoverpath.faxcoverTrustage):
        (this.brandname=this.staticInfo.brandname.cmg,
          this.faxcover=this.staticInfo.faxcoverpath.faxcovercmg)
      })
      .catch(err => console.error(err));
    }


  }
}
