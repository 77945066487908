import { Component, OnInit } from '@angular/core';
import { ClaimRouterService } from '../claim-router.service';
import { ClaimService } from '../claim.service';


@Component({
    selector: 'app-error-default',
    templateUrl: './error-default.component.html',
    styleUrls: ['./error-default.component.css'],
    standalone: true
})
export class ErrorDefaultComponent implements OnInit {

  constructor(
    private claimRouterService: ClaimRouterService,
    public claimService: ClaimService
  ) { }

  ngOnInit() {
  }

}
