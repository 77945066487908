import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { ConfigurationService } from 'src/app/configuration.service';
import { ClaimRouterService } from 'src/app/claim-router.service';
import { Observable } from 'rxjs';
import { RoutesStaticInfo } from '../routes-static-info';
import { CreditUnionService } from 'src/app/credit-union.service';
import { StaticInfo } from '../static-info';
import { ClaimService } from 'src/app/claim.service';
import { ClaimChannel } from '../../enum-claim-channel';
import { PhoneService } from 'src/app/phone.service';
import { AuthenticationService } from 'src/app/authentication.service';
import { SessionService } from 'src/app/session.service';

@Injectable({
  providedIn: 'root'
})
export class LandingGuard  {

  urlPath = RoutesStaticInfo;
  staticInfo = new StaticInfo();
  queryParam = this.staticInfo.queryParam;

  setClaimChannel(){
    switch (true) {
      case window.location.pathname.includes(this.urlPath.linkIntake): {
        this.claimService.setEnumClaimChannel(ClaimChannel.Intake);
        break;
      }
      case window.location.pathname.includes(this.urlPath.linkClaim)
        || window.location.pathname.includes(this.urlPath.linkLanding): {
          // Claim Channel is set if they click a link to provide further information
          this.claimService.setEnumClaimChannel(ClaimChannel.NotSet);
          break;
        }
      case window.location.pathname.includes(this.urlPath.linkSupp): {
        this.claimService.setEnumClaimChannel(ClaimChannel.Supplemental);
        break;
      }
      case window.location.pathname.includes(this.urlPath.linkSuppIntake): {        
        this.claimService.setEnumClaimChannel(ClaimChannel.SupplementalIntake);
        break;
      }
      case window.location.pathname.includes(this.urlPath.linkContract): {
        this.claimService.setEnumClaimChannel(ClaimChannel.MemberRegistration);
        break;
      }
      default: {
        this.claimService.setEnumClaimChannel(ClaimChannel.NotSet);
        break;
      }
    }
    this.phoneService.getPhoneNumber(this.claimService.claimChannel);
  }

  updateMemberRegistrationData() {
    // Member Registration has some special configuration handling
    if (window.location.pathname.includes(this.urlPath.linkContract)) {
      if (!this.creditUnionService.isMemberRegistrationCreditUnion(this.claimService.contractNumber)) {
        this.claimRouterService.routeToError();
      }
      this.memberRegistrationAuthentication();
    }
  }

  memberRegistrationAuthentication(){
    this.authService.createMemberToken(this.claimService.contractNumber).subscribe(
      (data:string) => {
        this.authService.token = data 
        this.claimService.claimRoot.cuContractNumber = this.claimService.contractNumber;
      },
      (err) => {
      },
      () => {
        this.sessionService.startCounter();
      }
    )
  }

  canActivate(route: ActivatedRouteSnapshot):Observable<boolean>{
    
    this.setClaimChannel();

    for (let key of route.queryParamMap.keys){
      switch(key.toUpperCase()) {
        case this.queryParam.contractNumber.toUpperCase(): {
          this.claimService.contractNumber = route.queryParamMap.get(key)
          break;
        }
      }
    }

    // Super awesome scoping issue resolution!
    let _this = this;
    return Observable.create((observer) => {
      if (_this.configService.configurationData.creditUnions == null) {
        _this.configService.loadCreditUnionData().subscribe(
          (data) => {
            if (data == null) {
              _this.claimRouterService.routeToError();
              observer.next(false);
              observer.complete();
            }
            _this.updateMemberRegistrationData();
            observer.next(true);
            observer.complete();
          },
          (error) => {
            _this.claimRouterService.routeToError();
            observer.next(false);
            observer.complete();
          }
        );
      } else {
        _this.updateMemberRegistrationData();
        observer.next(true);
        observer.complete();
      }

      if (_this.configService.configurationData.featureToggles == null) {
        _this.configService.loadFeatureToggleData().subscribe(
          (data) => {
            if (data == null) {
              _this.claimRouterService.routeToError();
              observer.next(false);
              observer.complete();
            }
            observer.next(true);
            observer.complete();
          },
          (error) => {
            _this.claimRouterService.routeToError();
            observer.next(false);
            observer.complete();
          }
        );
      }
    });

  }

  constructor(
    private claimService: ClaimService,
    private configService: ConfigurationService,
    private claimRouterService: ClaimRouterService,
    private creditUnionService: CreditUnionService,
    private phoneService: PhoneService,
    private authService: AuthenticationService,
    private sessionService: SessionService
  ) { }

}
