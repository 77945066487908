import { Component, OnInit, Input } from '@angular/core';
import { NgForm, ControlContainer, FormsModule } from '@angular/forms';
import cssVars from 'css-vars-ponyfill';
import { StaticInfo } from '../_helpers/static-info';
import { ClaimStepsService } from '../claim-steps.service';
import { ClaimRouterService } from '../claim-router.service';
import { ClaimService } from '../claim.service';
import { NgIf } from '@angular/common';
import { FormMedicalComponent } from '../form-medical/form-medical.component';
import { FormStepsComponent } from '../form-steps/form-steps.component';

@Component({
    selector: 'app-claimform-medical',
    templateUrl: './claimform-medical.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    styleUrls: ['./claimform-medical.component.css'],
    standalone: true,
    imports: [FormStepsComponent, FormsModule, FormMedicalComponent, NgIf]
})
export class ClaimformMedicalComponent implements OnInit {

  @Input() claimForm: NgForm

  medicalPage = 2;
  staticInfo = new StaticInfo();
  lbl = this.staticInfo.label;
  errMsg = this.staticInfo.errorMessage;

  constructor(
    public claimStepsService: ClaimStepsService,
    public claimRouterService: ClaimRouterService,
    public claimService: ClaimService
  ) { }

  ngOnInit() {
    cssVars();
    this.claimStepsService.index = this.medicalPage;
  }
}
