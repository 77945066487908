<p-fieldset *ngIf="getMostRecentPayment(benefitStatus.paymentsHistory)">
  <legend class="benefits">
    <p-header><b>{{header.mostRecentBenf}}</b></p-header>
  </legend>
  <div class="benf-padding">
    <br>
    <b>{{lbl.expOfMostRecentBenf}}:</b>
    <br>
    {{benefitStatus.paymentsExplanation}}
    <br><br>
    <b>{{lbl.benfIssued}}: {{getMostRecentPayment(benefitStatus.paymentsHistory).PaymentInitiationDate | date: 'MM/dd/yyyy'}}:</b><br>
    {{lbl.benfAmtIssued}}: {{getMostRecentPayment(benefitStatus.paymentsHistory).PaymentAmount | currency}}<br>
    {{lbl.benfStatus}}: {{getMostRecentPayment(benefitStatus.paymentsHistory).PaymentStatusDescription}}<br>
    {{lbl.benfPeriod}}: {{getMostRecentPayment(benefitStatus.paymentsHistory).TimePeriod}}
  </div>
  <br><br>
</p-fieldset>
<p-fieldset [toggleable]="true" [collapsed]="true"
  *ngIf="getFuture6MonthPayments(benefitStatus.paymentsHistory).length > 0">
  <p-header>
    <b>
      {{header.futureBenf}}
    </b>
  </p-header>
  <br>
  <div class="benf-padding">
    {{lbl.expOfFutureBenf}}
    <br><br>
    <i>{{lbl.futureBenfDesc}}</i>
    <p-table [value]="getFuture6MonthPayments(benefitStatus.paymentsHistory)" [responsive]="true" autoLayout="true">
      <ng-template pTemplate="body" let-paymentHistory>
        <tr>
          <td>
            <b>{{lbl.benfIssued}}: {{paymentHistory.PaymentInitiationDate | date: 'MM/dd/yyyy'}}:</b><br>
            {{lbl.benfAmtIssued}}: {{paymentHistory.PaymentAmount | currency}}<br>
            {{lbl.benfStatus}}: {{paymentHistory.PaymentStatusDescription}}<br>
            {{lbl.benfPeriod}}: {{paymentHistory.TimePeriod}}<br><br>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <br><br>
</p-fieldset>
<p-fieldset [toggleable]="true" [collapsed]="true" *ngIf="getPastPayments(benefitStatus.paymentsHistory).length > 0">
  <p-header>
    <b>
      {{header.pastBenf}}
    </b>
  </p-header>
  <br>
  <p-table [value]="getPastPayments(benefitStatus.paymentsHistory).slice(0, benefitStatus.rowCount)"
   [responsive]="true" autoLayout="true">
    <ng-template pTemplate="body" let-paymentHistory>
      <tr>
        <td>
          <b>{{lbl.benfIssued}}: {{paymentHistory.PaymentInitiationDate | date: 'MM/dd/yyyy'}}:</b><br>
          {{lbl.benfAmtIssued}}: {{paymentHistory.PaymentAmount | currency}}<br>
          {{lbl.benfStatus}}: {{paymentHistory.PaymentStatusDescription}}<br>
          {{lbl.benfPeriod}}: {{paymentHistory.TimePeriod}}<br><br>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <span class="btn-link load-more" (click)="getNext10PastPayments()"
    *ngIf="benefitStatus.rowCount < getPastPayments(benefitStatus.paymentsHistory).length; else allPastPaymentsDisplayed">
    <h4><i class="pi pi-plus"></i> Load more...</h4>
  </span>
  <ng-template #allPastPaymentsDisplayed>
    All past benefits are displayed.
  </ng-template>
  <br><br>
</p-fieldset>
