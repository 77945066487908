import { Component, OnInit, TemplateRef } from '@angular/core';
import { SessionService } from '../session.service';
import { ConfirmationService } from 'primeng/api';
import { ClaimService } from '../claim.service';
import { AuthenticationService } from '../authentication.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Message } from 'primeng/api';
import { StaticInfo } from '../_helpers/static-info';
import { RoutesStaticInfo } from '../_helpers/routes-static-info';
import { PhoneService } from '../phone.service';
import { ClaimRouterService } from '../claim-router.service';
import { ClaimChannel } from '../enum-claim-channel';
import { AuthResult } from '../api-data/auth-result';
import { MessagesModule } from 'primeng/messages';
import { NgIf } from '@angular/common';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
    selector: 'app-session',
    templateUrl: './session.component.html',
    styleUrls: ['./session.component.css'],
    providers: [ConfirmationService],
    standalone: true,
    imports: [
        ConfirmDialogModule,
        NgIf,
        MessagesModule,
    ],
})
export class SessionComponent implements OnInit {

  staticInfo = new StaticInfo();
  urlPath = RoutesStaticInfo;
  errMsg = this.staticInfo.errorMessage;
  modalRef: BsModalRef;
  renewSessionMessage: Message[] = [];
  isRenewingSession: boolean = false;

  /** Shows session warning dialog. User can choose to renew session. */
  sessionWarning(template: TemplateRef<any>) {
    this.confirmationService.confirm({
      message: 'Your session will expire in approximately 10 minutes. Would you like to renew this session?',
      header: 'Session Expiring',
      acceptVisible: true,
      rejectLabel: 'No',
      accept: () => {
        this.openModal(template);
        this.sessionService.restartCounter();
      },
      reject: () => {

      }
    });
  }

  /** Shows user that their session has expired and will direct them to error page. */
  sessionExpired() {
    this.confirmationService.confirm({
      message: 'Your session has expired. You will now be logged out of Member Claims Portal.',
      header: 'Session Expired',
      acceptVisible: false,
      rejectLabel: 'Close',
      reject: () => {
        this.authService.token = null;
        this.claimRouterService.routeToError();
      }
    });
  }

  /** Displays a modal on the page. */
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-md', backdrop: 'static', keyboard: false});
    this.renewSession();
  }

  /** Closes a modal on the page. */
  closeModal() {
    this.modalRef.hide();
  }

  /** Renews auth token. */
  renewSession(){
    this.isRenewingSession = true;
    this.renewSessionMessage = [];
    if(this.claimService.claimChannel === ClaimChannel.MemberRegistration){
      this.authService.createMemberToken(this.claimService.contractNumber)
      .subscribe(
        (data:string) => {
          this.renewSessionSubData(data);
        },
        (err) => {
          this.renewSessionSubError();
        },
        () => {
          this.renewSessionSubComplete();
        }
      )
    } else {
      this.authService.createToken(this.authService.auth)
      .subscribe(
        (data:AuthResult) => {
          this.renewSessionSubData(data.token);
        },
        (err) => {
          this.renewSessionSubError();
        },
        () => {
          this.renewSessionSubComplete();
        }
      );
    }
  }

  renewSessionSubData(data:string){
    this.authService.token = data;
    this.isRenewingSession = false;
  }

  renewSessionSubError(){
    this.isRenewingSession = false;
    //this is a generic message. It will need to be updated to be more fitting in this situation... but for time's sake of pushing RP out, this will do for now.
    this.renewSessionMessage.push({ severity: 'error', summary: null, detail: this.errMsg.internalServer(this.phoneService.phoneNumber) });
  }

  renewSessionSubComplete(){
    this.renewSessionMessage.push({ severity: 'success', summary: null, detail: 'This session has been successfully renewed!' });
    this.sessionService.startCounter();
  }

  constructor(
    public sessionService: SessionService,
    private confirmationService: ConfirmationService,
    private claimService: ClaimService,
    private authService: AuthenticationService,
    private modalService: BsModalService,
    private claimRouterService: ClaimRouterService,
    private phoneService: PhoneService
  ) { }

  ngOnInit() {
  };

}
