exports.headerjs=function(){
    window.brand.siteheader({
        // Mount point
        mount: ".js-siteheader-mount",
      });
}

exports.headerhide=function(){
    var app = document.getElementsByClassName('brand-site-header__primary-nav brand-site-header__primary-nav--desktop')[0];
    if(typeof app != "undefined"){
        app.setAttribute("style","visibility:hidden");
    }
    
    var app1 = document.getElementsByClassName('brand-site-header__right-column')[0];
    if(typeof app1 != "undefined"){
        app1.setAttribute("style","visibility:hidden");
    }
}
