import { Component, OnInit } from '@angular/core';
import { SharedModule } from 'primeng/api';
import { FieldsetModule } from 'primeng/fieldset';


@Component({
    selector: 'app-form-collapsible',
    templateUrl: './form-collapsible.component.html',
    styleUrls: ['./form-collapsible.component.css'],
    standalone: true,
    imports: [FieldsetModule, SharedModule]
})
export class FormCollapsibleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
