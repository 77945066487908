export class additionalClaimPropertyValues{
    employedLongerThanOneYear:string
    isUnionMember:string
    unionName:string
    isTemporaryEmployee: string
    isContractBasis: string
    knewLayoffWasPossible: string
    hasSeasonalEmployment: string
    isUnemploymentFromCOVID19: string
    isReceivingWagesDuringCOVID19Unemployment: string
    reasonForUnemployment: string
    differentReasonForUnemployment: string
    currentHoursPerWeek: string
    isSeekingEmployment: string
    plansToWorkWithSameEmployer: string
    hospitalName: string
    hospitalCity: string
    hospitalState: string

    constructor(){
        this.employedLongerThanOneYear = null
        this.isUnionMember = null
        this.unionName = null
        this.isTemporaryEmployee = null
        this.isContractBasis = null
        this.knewLayoffWasPossible = null
        this.hasSeasonalEmployment = null
        this.isUnemploymentFromCOVID19 = null
        this.isReceivingWagesDuringCOVID19Unemployment = null
        this.reasonForUnemployment = null
        this.differentReasonForUnemployment = null
        this.currentHoursPerWeek = null
        this.isSeekingEmployment = null
        this.plansToWorkWithSameEmployer = null
        this.hospitalName = null
        this.hospitalCity = null
        this.hospitalState = null
    }
}