import { Injectable } from '@angular/core';

import { RoutesStaticInfo } from '../routes-static-info';
import { ClaimService } from 'src/app/claim.service';
import { ClaimRouterService } from 'src/app/claim-router.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimSubmitGuardService  {

  constructor(
    private claimService: ClaimService,
    private claimRouterService: ClaimRouterService
  ) { }

  routesUrlPath = RoutesStaticInfo;

  canActivate():boolean {
    if (this.claimService.claimSubmitSuccess == false){
      this.claimRouterService.routeToError();
      return false;
    } else {
      return true;
    }
  }

}
