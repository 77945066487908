<div class="container">
    <app-form-steps></app-form-steps>
    <h1>{{claimService.staticInfo.pageHeaders.claimMedical}}</h1>
    <div class="row">
        <div class="col-sm-8">
        </div>
        <div class="col-sm-4">
            <p class="form-error-text">* denotes required fields.</p>
        </div>
    </div>
    <div class="container claim-form">
        <form (ngSubmit)="claimRouterService.medicalSubmit(medicalForm.valid)" #medicalForm="ngForm">
            <app-form-medical [claimForm]="medicalForm"></app-form-medical>
            <div class="divider"></div>
            <div *ngIf="medicalForm.submitted && !medicalForm.valid" class="form-error-text" class="alert alert-danger">
                {{errMsg.formRequired}}
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <button type="button" class="btn btn-secondary float-right" id="back" (click)="claimRouterService.routeToWork()">
                        {{lbl.back}}
                    </button>
                </div>
                <div class="col-sm-4">

                </div>
                <div class="col-sm-5">
                    <button type="submit" class="btn btn-primary float-right" id="enterMedInfo"
                        [disabled]="medicalForm.submitted && !medicalForm.valid">
                        {{lbl.reviewClaim}}
                    </button>
                </div>
            </div>

            <br><br>
        </form>
    </div>
</div>