<span class="claim-form">
  <div class="form-group row" *ngIf="claimService.showIncurredDate && claimService.lossType != claimService.enumLossType.Hospitalization">
    <label for="incurredDate" class="col-sm-5 col-form-label">
      {{claimService.incurredDateText}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <p-calendar id="incurredDate" required [monthNavigator]="true" [yearNavigator]="true" [showOnFocus]="false" [touchUI]="claimformService.touchUI"
        yearRange="{{claimformService.lbl.todayPlusMinus90Yrs}}" dataType="string" rPCalendarMask
        placeholder="MM/DD/YYYY" [(ngModel)]="claimService.claimRoot.claim.incurredDate" class="ui-fluid"
        name="incurredDate" #incurredDate="ngModel" [showIcon]="true" styleClass="input-styles" dateMask></p-calendar>
      <div *ngIf="(incurredDate.invalid && !incurredDate.pristine) || (claimForm.submitted && incurredDate.invalid)"
        class="alert alert-danger">
        <span *ngIf="incurredDate.errors.required">{{claimformService.errMsg.dateRequired}}</span>
      </div>
    </div>
  </div>
  <span *ngIf="claimService.lossType == claimService.enumLossType.Life">
    <h4 class="bold">{{claimformService.lbl.contactInformation}}</h4>
    <div class="form-group row">
      <label for="contactFirstName" class="col-sm-5 col-form-label">
        {{claimformService.lbl.contactFirstName}} <span class="form-error-text">*</span>
      </label>
      <div class="col-sm-7">
        <input type="text" pInputText class="form-control" id="contactFirstName" required class="input-styles"
          [(ngModel)]="claimService.claimRoot.contact.firstName" name="contactFirstName" #contactFirstName="ngModel"
          maxlength=30>
        <div
          *ngIf="(contactFirstName.invalid && !contactFirstName.pristine) || (claimForm.submitted && contactFirstName.invalid)"
          class="alert alert-danger">
          {{claimformService.errMsg.contactFirstNameRequired}}
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="contactLastName" class="col-sm-5 col-form-label">
        {{claimformService.lbl.contactLastName}} <span class="form-error-text">*</span>
      </label>
      <div class="col-sm-7">
        <input type="text" pInputText class="form-control" id="contactLastName" required class="input-styles"
          [(ngModel)]="claimService.claimRoot.contact.lastName" name="contactLastName" maxlength=30
          #contactLastName="ngModel">
        <div
          *ngIf="(contactLastName.invalid && !contactLastName.pristine) || (claimForm.submitted && contactLastName.invalid)"
          class="alert alert-danger">
          {{claimformService.errMsg.contactLastNameRequired}}
        </div>
      </div>
    </div>
    <h4 class="bold">{{claimformService.lbl.deceasedInformation}}</h4>
  </span>
  <div class="form-group row">
    <label for="firstName" class="col-sm-5 col-form-label">
      {{claimformService.lbl.firstName}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <input type="text" pInputText class="form-control" id="firstName" required class="input-styles"
        [(ngModel)]="claimService.claimRoot.claim.insured.firstName" name="firstName" #firstName="ngModel" maxlength=30 (blur)="setContactFirstName(firstName.model)">
      <div *ngIf="(firstName.invalid && !firstName.pristine) || (claimForm.submitted && firstName.invalid)"
        class="alert alert-danger">
        {{claimformService.errMsg.firstNameRequired}}
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="lastName" class="col-sm-5 col-form-label">
      {{claimformService.lbl.lastName}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <input type="text" pInputText class="form-control" id="lastName" required class="input-styles"
        [(ngModel)]="claimService.claimRoot.claim.insured.lastName" name="lastName" maxlength=30 #lastName="ngModel" (blur)="setContactLastName(lastName.model)">
      <div *ngIf="(lastName.invalid && !lastName.pristine) || (claimForm.submitted && lastName.invalid)"
        class="alert alert-danger">
        {{claimformService.errMsg.lastNameRequired}}
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="country" class="col-sm-5 col-form-label">
      {{claimformService.lbl.country}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <div>
        <ng-select class="fw-normal fs-6" labelForId="country" name="country" #country="ngModel" bindLabel="label" bindValue="value"
        [(ngModel)]="claimService.claimRoot.contact.address.countryCode" [selectOnTab]="true"
        [items]="claimformService.countries" placeholder="{{claimformService.lbl.country}}" required
        (ngModelChange)="isForeignCountry(claimService.claimRoot.contact.address.countryCode)">
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <span [ngOptionHighlight]="search">{{item.label}}</span>
          </ng-template>
        </ng-select>
      </div>
      <div *ngIf="(country.invalid && !country.pristine) || (claimForm.submitted && country.invalid)"
      class="alert alert-danger">
        {{claimformService.errMsg.countryRequired}}
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="address" class="col-sm-5 col-form-label">
      {{claimformService.lbl.address}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <input type="text" pInputText class="form-control" id="address" required class="input-styles"
        [(ngModel)]="claimService.claimRoot.contact.address.line1" name="address" maxlength="50" #address="ngModel">
      <div *ngIf="(address.invalid && !address.pristine) || (claimForm.submitted && address.invalid)"
        class="alert alert-danger">
        {{claimformService.errMsg.addressRequired}}
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="city" class="col-sm-5 col-form-label">
      {{claimformService.lbl.city}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <input type="text" pInputText class="form-control" id="city" required class="input-styles"
        [(ngModel)]="claimService.claimRoot.contact.address.city" name="city" maxlength="25" #city="ngModel">
      <div *ngIf="(city.invalid && !city.pristine) || (claimForm.submitted && city.invalid)" class="alert alert-danger">
        {{claimformService.errMsg.cityRequired}}
      </div>
    </div>
  </div>
  <div class="form-group row" *ngIf="!internationalAddressService.isForeignCountry">
    <label for="state" class="col-sm-5 col-form-label">
      {{claimformService.lbl.state}} <span class="form-error-text" *ngIf="!internationalAddressService.isForeignCountry">*</span>
    </label>
    <div class="col-sm-7">
      <div>
        <ng-select class="fw-normal fs-6" labelForId="state" name="state" #state="ngModel" bindLabel="label" bindValue="value"
        [(ngModel)]="claimService.claimRoot.contact.address.state" [selectOnTab]="true"
        [items]="claimformService.states" placeholder="{{claimformService.lbl.state}}" required="{{!internationalAddressService.isForeignCountry}}"
        (ngModelChange)="isForeignCountry(claimService.claimRoot.contact.address.countryCode)"
        [searchFn]="claimService.customSearchState" (search)="claimService.onStateSearch($event, state)">
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <span [ngOptionHighlight]="search">{{item.label}}</span>
          </ng-template>
        </ng-select>
      </div>
      <div *ngIf="(state.invalid && !state.pristine) || (claimForm.submitted && state.invalid)"
        class="alert alert-danger">
        {{claimformService.errMsg.stateRequired}}
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="zipCode" class="col-sm-5 col-form-label">
      {{claimformService.lbl.zipCode}} <span class="form-error-text" *ngIf="!internationalAddressService.isForeignCountry">*</span>
    </label>
    <div class="col-sm-7">
      <p-inputMask styleClass="input-styles" type="tel" id="zipCode" required="{{!internationalAddressService.isForeignCountry}}" [(ngModel)]="claimService.claimRoot.contact.address.zip"
        name="zipCode" #zipCode="ngModel" mask="99999" styleClass="input-styles"></p-inputMask>
      <div *ngIf="!internationalAddressService.isForeignCountry && ((zipCode.invalid && !zipCode.pristine) || (claimForm.submitted && zipCode.invalid))"
        class="alert alert-danger">
        {{claimformService.errMsg.zipCodeRequired}}
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="gender" class="col-sm-5 col-form-label">
      {{claimformService.lbl.gender}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <div>
        <ng-select class="fw-normal fs-6" labelForId="gender" name="gender" #gender="ngModel" bindLabel="label" bindValue="value"
        [(ngModel)]="claimService.claimRoot.claim.insured.gender" [selectOnTab]="true" [searchable]="false" [clearable]="false"
        [items]="claimformService.genderOption" placeholder="{{claimformService.lbl.gender}}" required>
        </ng-select>
      </div>
      <div *ngIf="(gender.invalid && !gender.pristine) || (claimForm.submitted && gender.invalid)"
        class="alert alert-danger">
        {{claimformService.errMsg.genderRequired}}
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label for="birthDate" class="col-sm-5 col-form-label">
      {{claimformService.lbl.birthDate}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <p-calendar id="birthDate" required [monthNavigator]="true" [yearNavigator]="true" [showOnFocus]="false" [touchUI]="claimformService.touchUI"
        yearRange="{{claimformService.lbl.todayPlusMinus90Yrs}}" dataType="string" rPCalendarMask
        [(ngModel)]="claimService.claimRoot.claim.insured.dateOfBirth" class="ui-fluid" name="birthDate"
        placeholder="MM/DD/YYYY" #birthDate="ngModel" [showIcon]="true" styleClass="input-styles" dateMask></p-calendar>
      <div *ngIf="(birthDate.invalid && !birthDate.pristine) || (claimForm.submitted && birthDate.invalid)"
        class="alert alert-danger">
        {{claimformService.errMsg.dateRequired}}
      </div>
    </div>
  </div>
  <div class="form-group row" *ngIf="claimformService.showLast4SSN()">
    <label for="ssn" class="col-sm-5 col-form-label">
      {{claimformService.lbl.last4SSN}} <span class="form-error-text" *ngIf="claimformService.requiredLast4SSN()">*</span>
    </label>
    <div class="col-sm-7">
      <p-inputMask styleClass="input-styles" type="tel" id="ssn" required="{{claimformService.requiredLast4SSN()}}" [(ngModel)]="claimService.claimRoot.claim.insured.last4SSN" name="ssn"
        #ssn="ngModel" mask="9999"styleClass="input-styles"></p-inputMask>
      <div *ngIf="claimformService.requiredLast4SSN() && ((ssn.invalid && !ssn.pristine) || (claimForm.submitted && ssn.invalid))" class="alert alert-danger">
        {{claimformService.errMsg.last4SSNRequired}}
      </div>
    </div>
  </div>
  <div class="form-group row" *ngIf="claimService.lossType == claimService.enumLossType.Life">
    <label for="incurredDate" class="col-sm-5 col-form-label">
      {{claimformService.lbl.deathDate}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <p-calendar id="incurredDate" required [monthNavigator]="true" [yearNavigator]="true" [showOnFocus]="false"
        [touchUI]="claimformService.touchUI" yearRange="{{claimformService.lbl.todayPlusMinus90Yrs}}" rPCalendarMask
        dataType="string" placeholder="MM/DD/YYYY" [(ngModel)]="claimService.claimRoot.claim.incurredDate"
        class="ui-fluid" name="incurredDate" #incurredDate="ngModel" [showIcon]="true" styleClass="input-styles" dateMask></p-calendar>
      <div *ngIf="(incurredDate.invalid && !incurredDate.pristine) || (claimForm.submitted && incurredDate.invalid)"
        class="alert alert-danger">
        <span *ngIf="incurredDate.errors.required">{{claimformService.errMsg.dateRequired}}</span>
      </div>
    </div>
  </div>
  <div *ngIf = "showDescriptionOnContactForm()">
    <app-form-description></app-form-description>
  </div>
</span>