<div class="container">
  <div class="row">
    <div class="col-md-8">
    </div>
    <div class="col-md-4">
      <p class="form-error-text">* denotes required fields.</p>
    </div>
  </div>

  <form (ngSubmit)="authorizeCreditUnion(creditUnionForm)" #creditUnionForm="ngForm" autocomplete="off" class="margin-bottom">

    <div class="form-group row">
      <label for="dropDown_creditUnion" class="col-sm-5 col-form-label claim-form">
        {{claimformService.lbl.creditUnion}} <span class="form-error-text">*</span>
      </label>
      <div class="col-sm-7">
        <div>
          <ng-select labelForId="creditUnion" name="creditUnion" #creditUnion="ngModel" bindLabel="name" bindValue="contractNumber"
            [(ngModel)]="claimService.contractNumber" [virtualScroll]="true" [selectOnTab]="true" 
            [items]="configurationService.configurationData.creditUnions" placeholder="{{claimformService.lbl.cuName}}" required>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <span [ngOptionHighlight]="search">{{item.name}}</span>
            </ng-template>
          </ng-select>
        </div>
        <div *ngIf="(creditUnion.invalid && !creditUnion.pristine) || (creditUnionForm.submitted && creditUnion.invalid)"
          class="alert alert-danger">
          {{errMsg.creditUnionRequired}}
        </div>
      </div>
    </div>

    <div *ngIf="creditUnionForm.submitted && !creditUnionForm.form.valid" class="form-error-text" class="alert alert-danger">
      {{errMsg.formRequired}}
    </div>

    <div class="row">
      <div class="col-md-12">
        <div *ngIf="claimService.apiError" class="form-error-text" class="alert alert-danger">
            {{errMsg.internalServer(phoneService.phoneNumber)}}
        </div>
        <span *ngIf="isLoadingAuth">
          Authenticating...<i class="pi pi-spin pi-spinner" style="font-size: 3em"></i>
        </span>
        <button type="submit" class="btn btn-primary float-right" id="claimSubmit"
        [disabled]="(creditUnionForm.submitted && !creditUnionForm.form.valid) || (isLoadingAuth || claimService.isLoadingClaimData)">Start Claim Process</button>
      </div>
    </div>

  </form>

  <p-accordion [multiple]="true" styleClass="question-header">
    <p-accordionTab>
      <p-header class="question-header">
        Don't see your credit union in the list?
      </p-header>
      If you do not see your credit union listed here, please confirm you did not receive an email or text that provides your claim number. If you are unable to locate your claim number, please contact us at {{phoneService.phoneNumber}}.
    </p-accordionTab>
  </p-accordion>

</div>