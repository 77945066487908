import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StaticInfo } from '../app/_helpers/static-info';
import { Observable } from 'rxjs';
import { ClaimMapperService } from './claim-mapper.service';
import { Configuration } from './configuration-data/configuration';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  staticInfo = new StaticInfo();
  api = this.staticInfo.api;
  configurationData: Configuration = new Configuration;
  featureToggles = this.staticInfo.featureToggles;
  isLoadingConfigurations:boolean = false;

  constructor(
    private http: HttpClient,
    private mapper: ClaimMapperService
  ) { }

  loadCreditUnionData(): Observable<this> {
    this.isLoadingConfigurations = true;
    // super awesome scoping issue resolution!
    let _this = this;
    // I need something I can subscribe to when I call this function for async awesomeness
    return Observable.create((observer) => {
      this.http.get(this.api.creditUnionsURL).subscribe(
        (data) => {

          this.isLoadingConfigurations = false;
          // TypeScript doesn't like me setting an IConfigurationData object to be a dynamic object
          // returned by an API, so this is a way to explicitly map it all out so that its happy
          _this.configurationData.creditUnions = this.mapper.mapCreditUnionData(data).sort((a,b) => a.name.localeCompare(b.name));

          // Boom, pass the data into my returned Observable
          observer.next(_this);

          // Close the sub dude
          observer.complete();
        },
        (error) => {
          this.isLoadingConfigurations = false;
          observer.error(error);
        }
      );
    });
    
  }

  loadFeatureToggleData(): Observable<this> {
    let _this = this;
    return Observable.create((observer) => {
      this.http.get(this.api.featureTogglesURL).subscribe(
        (data) => {
          _this.configurationData.featureToggles = this.mapper.mapfeatureTogglesData(data);
          observer.next(_this);
          observer.complete();
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  getFeatureToggleValueFromKey(paramKey){
    return this.mapper.mapStringToBooleanIfValueIsTrueOrFalse(this.configurationData.featureToggles.find(element => element.key === paramKey).value);
  }

}
