<span class="claim-form">
  <fieldset class="form-group">
    <div class="row">
      <div class="col-sm-5">
        <legend>
          {{claimformService.lblIUSupp.workedHoursSinceUnemployed}} <span class="form-error-text">*</span>
        </legend>
      </div>
      <div class="col-sm-7">
        <div class="row" id="returnedToWork">
          <div class="col-12 radio-button">
            <p-radioButton name="returnedToWork" [value]="true" required label="{{claimformService.yesNo.yes}}"
              [(ngModel)]="claimService.claimRoot.claim.employment.hasReturnedToWork" inputId="returnedToWork1"
              id="returnedToWorkYes" #returnedToWork="ngModel"
              (click)="claimformService.clearField(claimformService.clearFormField.hasReturnedToWorkYes)">
            </p-radioButton>
          </div>
          <div class="col-12 radio-button">
            <p-radioButton name="returnedToWork" [value]="false" required label="{{claimformService.yesNo.no}}"
              [(ngModel)]="claimService.claimRoot.claim.employment.hasReturnedToWork" inputId="returnedToWork2"
              id="returnedToWorkNo" #returnedToWork="ngModel"
              (click)="claimformService.clearField(claimformService.clearFormField.hasReturnedToWork)">
            </p-radioButton>
          </div>
        </div>
        <div
          *ngIf="(returnedToWork.invalid && !returnedToWork.pristine) || (claimForm.submitted && returnedToWork.invalid)"
          class="alert alert-danger">
          {{claimformService.errMsg.generic}}
        </div>
      </div>
    </div>
  </fieldset>
  <div *ngIf="claimService.claimRoot.claim.employment.hasReturnedToWork === true" class="form-group row">
    <label for="returnedToWorkDate" class="col-sm-5 col-form-label">
      {{claimformService.lblIUSupp.firstDateWorkedAfterUnemployed}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <p-calendar id="returnedToWorkDate" required [monthNavigator]="true" [yearNavigator]="true" [showOnFocus]="false"
        [touchUI]="claimformService.touchUI" yearRange="{{claimformService.lbl.todayPlusMinus90Yrs}}" dataType="string"
        [(ngModel)]="claimService.claimRoot.claim.employment.returnDate" class="ui-fluid" name="returnedToWorkDate"
        placeholder="MM/DD/YYYY" #returnedToWorkDate="ngModel" [showIcon]="true" rPCalendarMask styleClass="input-styles" dateMask></p-calendar>
      <div
        *ngIf="(returnedToWorkDate.invalid && !returnedToWorkDate.pristine) || (claimForm.submitted && returnedToWorkDate.invalid)"
        class="alert alert-danger">
        {{claimformService.errMsg.dateRequired}}
      </div>
    </div>
  </div>
  <div *ngIf="claimService.claimRoot.claim.employment.hasReturnedToWork === true" class="form-group row">
    <label for="hoursPerWeek" class="col-sm-5 col-form-label">
      {{claimformService.lblIUSupp.hoursWorkingPerWeek}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <div class="select-container">
      <select class="form-control" id="hoursPerWeek" name="hoursPerWeek" 
      [(ngModel)]="claimService.claimRoot.claim.employment.hoursPerWeek" #hoursPerWeek="ngModel" required>
        <option disabled></option>
        <option *ngFor="let in of counter(169) ;let i = index" [ngValue]="i">{{i}}</option>
      </select>
      </div>
      <div *ngIf="(hoursPerWeek.invalid && !hoursPerWeek.pristine) || (claimForm.submitted && hoursPerWeek.invalid)"
        class="alert alert-danger">
        {{claimformService.errMsg.hoursPerWeek}}
      </div>
    </div>
  </div>
  <div *ngIf="claimService.claimRoot.claim.employment.hasReturnedToWork === false" class="form-group row">
    <label for="plansToWorkWithSameEmployer" class="col-sm-5 col-form-label">
      {{claimformService.lblIUSupp.plansToWorkWithSameEmployer}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <div>
      <ng-select class="fw-normal fs-6" labelForId="plansToWorkWithSameEmployer" name="plansToWorkWithSameEmployer" #plansToWorkWithSameEmployer="ngModel" bindLabel="value" bindValue="value"
        [(ngModel)]="claimService.acpValues.plansToWorkWithSameEmployer" [selectOnTab]="true" [searchable]="false" [clearable]="false"
        [items]="claimformService.yesNoUnsure" placeholder="{{claimformService.lbl.plansToWorkWithSameEmployer}}" required>
      </ng-select>
    </div>
      <div *ngIf="(plansToWorkWithSameEmployer.invalid && !plansToWorkWithSameEmployer.pristine) || (claimForm.submitted && plansToWorkWithSameEmployer.invalid)"
        class="alert alert-danger">
        {{claimformService.errMsg.genericSelect}}
      </div>
    </div>
  </div>
  <div class="form-group row" *ngIf="showSeekingEmploymentQuestion()">
    <label for="isSeekingEmployment" class="col-sm-5 col-form-label">
      {{claimformService.lblIUSupp.isSeekingEmployment}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <div>
      <ng-select class="fw-normal fs-6" labelForId="isSeekingEmployment" name="isSeekingEmployment" #isSeekingEmployment="ngModel" bindLabel="value" bindValue="value"
        [(ngModel)]="claimService.acpValues.isSeekingEmployment" [selectOnTab]="true" [searchable]="false" [clearable]="false"
        [items]="claimformService.yesNoUnsure" placeholder="{{claimformService.lbl.isSeekingEmployment}}" required>
      </ng-select>
      </div>
      <div *ngIf="(isSeekingEmployment.invalid && !isSeekingEmployment.pristine) || (claimForm.submitted && isSeekingEmployment.invalid)"
        class="alert alert-danger">
        {{claimformService.errMsg.genericSelect}}
      </div>
    </div>
  </div>
</span>