import { Component, OnInit } from '@angular/core';
import { ClaimformService } from '../claimform.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-modal-edc',
    templateUrl: './modal-edc.component.html',
    styleUrls: ['./modal-edc.component.css'],
    standalone: true,
    imports: [NgIf]
})
export class ModalEdcComponent implements OnInit {
  constructor(
    public modalRef: BsModalRef,
    public claimformService: ClaimformService
  ) { }

  closeModal(){
    this.modalRef.hide();
  }

  acceptConsentAndCloseModal(){
    this.claimformService.showEmailConsentButtons = false;
    this.closeModal();
  }

  declineConsentAndCloseModal(){
    this.claimformService.declineEmailConsent();
    this.closeModal();
  }

  ngOnInit() {
  }

}
