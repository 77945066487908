<span class="claim-form">
  <div class="form-group row" *ngIf="showLoansOtherCU === true">
    <label for="loansOtherCU" class="col-sm-5 col-form-label">
      {{claimformService.lbl.loansOtherCU}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <div>
      <ng-select class="fw-normal fs-6" labelForId="loansOtherCU" name="loansOtherCU" #loansOtherCU="ngModel" bindLabel="value" bindValue="value"
          [(ngModel)]="claimService.claimRoot.claim.loansOtherCU" [selectOnTab]="true" [searchable]="false" [clearable]="false"
          [items]="claimformService.yesNoUnsure" placeholder="{{claimformService.lbl.loansOtherCU}}" required>
      </ng-select>
    </div>
      <div *ngIf="(loansOtherCU.invalid && !loansOtherCU.pristine) || (claimForm.submitted && loansOtherCU.invalid)"
        class="alert alert-danger">
        {{claimformService.errMsg.genericSelect}}
      </div>
    </div>
  </div>
  <div class="form-group row" *ngIf="claimformService.blnloansOtherCU">
    <label for="otherCUNames" class="col-sm-5 col-form-label">
      {{claimformService.lbl.otherCUNames}}
    </label>
    <div class="col-sm-7">
      <input type="text" pInputText class="form-control input-styles" id="otherCUNames"
        [(ngModel)]="claimService.claimRoot.claim.otherCU" name="otherCUNames" #otherCUNames="ngModel" maxlength="200">
    </div>
  </div>
  <div class="form-group row" *ngIf="claimformService.showCCField(claimService.claimChannel)">
    <label for="creditCard" class="col-sm-5 col-form-label">
      {{claimformService.lbl.creditCard}} <span class="form-error-text">*</span>
    </label>
    <div class="col-sm-7">
      <div>
      <ng-select class="fw-normal fs-6" labelForId="creditCard" name="creditCard" #creditCard="ngModel" bindLabel="value" bindValue="value"
          [(ngModel)]="claimService.claimRoot.claim.hasCreditCard" [selectOnTab]="true" [searchable]="false" [clearable]="false"
          [items]="claimformService.yesNoUnsure" placeholder="{{claimformService.lbl.creditCard}}" required>
      </ng-select>
      </div>
      <div *ngIf="(creditCard.invalid && !creditCard.pristine) || (claimForm.submitted && creditCard.invalid)"
        class="alert alert-danger">
        {{claimformService.errMsg.genericSelect}}
      </div>
    </div>
  </div>
</span>
