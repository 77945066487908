export const RoutesStaticInfo = {
    linkContract: 'link/contract',
    linkLanding: 'link/landing',
    linkIntake: 'link/intake',
    linkClaim: 'link/claim',
    linkSupp: 'link/supplemental',
    linkSuppIntake: 'link/supp-intake',
    summary: 'summary',
    claimSubmitSuccess: 'claim-submit-success',
    claim: 'claim',
    claimContact: 'claimform-contact',
    claimWork: 'claimform-work',
    claimMedical: 'claimform-medical',
    claimLossType: 'claimform-claim-type',
    claimSupp: 'claimform-supplemental',
    GDPRConsent: 'gdpr-consent',
    home: '',
    error: '**'
}

export const Title = {
    error: 'Error',
    GDPRConsent: 'Consent',
    claimMedical: 'Medical Information',
    claimWork: 'Work Information',
    claimContact: 'Contact Information',
    claim: 'Claim Information',
    claimSubmitSuccess: 'Claim Submitted',
    summary: 'Claim Review',
    findYourClaim: 'Your Claim'
}
